import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Video } from 'typings/index';

interface GetUserVideoState {
  userVideos: Video[];
  isLoading: boolean;
  error: string | null;
}

const initialState: GetUserVideoState = {
  userVideos: [],
  isLoading: false,
  error: null,
};

export const getUserVideoSlice = createSlice({
  name: 'get/user/video',
  initialState,
  reducers: {
    getUserVideoStart: (
      state,
      action: PayloadAction<{ userId: string; toUserId: string }>
    ) => {
      state.isLoading = true;
      state.error = null;
      state.userVideos = [];
    },
    getUserVideoSuccess: (state, { payload }) => {
      state.userVideos = payload.result;
      state.error = null;
      state.isLoading = false;
    },
    getUserVideoFailure: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(uploadVideoSuccess, (state, { payload }) => {
  //     console.log('payload', payload);
  //     console.log('state.userVideos', state.userVideos);

  //     state.userVideos = [payload, ...state.userVideos];
  //   });
  // },
});

export const { getUserVideoStart, getUserVideoSuccess, getUserVideoFailure } =
  getUserVideoSlice.actions;

export default getUserVideoSlice.reducer;
