import React from 'react';
import { BottomNavigation, BottomNavigationAction, Box, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getUserSession } from 'utils/utils';
import { useAppSelector } from 'hooks/useRedux';
import { Link } from 'react-router-dom';
import HomeIcon from 'assets/IconsComponents/Home';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const MobileFooter: React.FC = () => {
    const theme = useTheme();
    const location = useLocation();
    const user = getUserSession();
    const { videos } = useAppSelector((state) => state.video.getAllVideo);
    const [value, setValue] = React.useState<string>(
        location.pathname.includes('recommended-videos') ? 'recommended-videos' :
            location.pathname.includes('shorts') ? 'shorts' :
                location.pathname);

    const videoId = !!videos.length && videos[0]._id;
    React.useEffect(() => {
        setValue(location.pathname.includes('recommended-videos') ? 'recommended-videos' :
            location.pathname.includes('shorts') ? 'shorts' :
                location.pathname);
    }, [location.pathname]);

    return (
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 1100, display: { xs: location.pathname.includes('room/host') || location.pathname.includes('privacy-policy') ? 'none' : "block", sm: 'none' } }}>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                sx={{ backgroundColor: 'rgba(33, 33, 33, 0.13)', p: "12px 16px", justifyContent: 'space-between' }}>
                <BottomNavigationAction
                    component={Link}
                    to={user ? `/${user._id}/recommended-videos/${videoId}` : `/recommended-videos/${videoId}`}
                    value={`recommended-videos`}
                    icon={<HomeIcon
                        sx={{
                            fontSize: '18px', color: value !== 'recommended-videos' ? 'background.paper' : 'rgba(121, 255, 228, 1)'
                        }}
                    />}
                    sx={{
                        color: 'background.paper',
                        '&.Mui-selected': {
                            color: 'rgba(121, 255, 228, 1)'
                        },
                        borderRadius: "20px",
                        minWidth: "60px",
                        maxWidth: "60px",
                    }}
                />
                <BottomNavigationAction
                    component={Link}
                    to={user ? `/${user._id}/shorts/${videoId}` : `/shorts/${videoId}`}
                    value="shorts"
                    icon={<PlayArrowOutlinedIcon />}
                    sx={{
                        color: 'background.paper',
                        '&.Mui-selected': {
                            color: 'rgba(121, 255, 228, 1)'
                        },
                        borderRadius: "20px",
                        minWidth: "60px",
                        maxWidth: "60px",
                    }}
                />
                {!location.pathname.includes('upload') &&
                    <BottomNavigationAction
                        component={Link}
                        to={user ? `/upload` : '/login'}
                        value={`/upload`}
                        icon={<AddOutlinedIcon sx={{color: '#000'}} />}
                        sx={{
                            color: 'background.paper',
                            background: 'rgba(121, 255, 228, 1)',
                            borderRadius: "20px",
                            minWidth: "60px",
                            maxWidth: "60px",
                            '&.Mui-selected': {
                                color: 'rgba(121, 255, 228, 1)',
                            },
                        }}
                    />}
                <BottomNavigationAction
                    component={Link}
                    to={user ? `/${user._id}/followerList` : '/login'}
                    value={`/${user?._id}/followerList`}
                    icon={<SubscriptionsOutlinedIcon />}
                    sx={{
                        color: 'background.paper',
                        '&.Mui-selected': {
                            color: 'rgba(121, 255, 228, 1)'
                        },
                        borderRadius: "20px",
                        minWidth: "60px",
                        maxWidth: "60px",
                    }}
                />
                <BottomNavigationAction
                    component={Link}
                    to={user ? `/${user._id}/profile` : '/login'}
                    value={`/${user?._id}/profile`}
                    icon={<PersonOutlineOutlinedIcon />}
                    sx={{
                        color: 'background.paper',
                        '&.Mui-selected': {
                            color: 'rgba(121, 255, 228, 1)'
                        },
                        borderRadius: "20px",
                        minWidth: "60px",
                        maxWidth: "60px",
                    }}
                />
            </BottomNavigation>
        </Box>
    );
};

export default MobileFooter;
