import Sidebar from 'components/common/Sidebar';
import SidebarContentProvider from 'components/common/SidebarContentProvider';
import { FC, PropsWithChildren, useState } from 'react';
import Header from '../Header';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Path } from 'constants/index';
import FooterPage from '../Footer';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const { pathname } = useLocation()
  const [isShowSidebar, setIsShowSidebar] = useState<boolean>(true);

  return (
    <Box sx={{
      backgroundColor: '#0B0B0B',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {pathname !== Path.PRIVACY_POLICY &&
        <Box sx={{
          display: {
            xs: pathname.includes('recommended-videos') || pathname.includes('shorts') || pathname.includes('my-videos') ? 'none' : 'block',
            sm: 'block'
          }
        }}>
          <Header isShowSidebar={isShowSidebar} handleOpenMenu={() => setIsShowSidebar(!isShowSidebar)} />
        </Box>}
      <Box sx={{ display: 'flex', height: pathname === Path.PRIVACY_POLICY ? "100%" : "calc(100vh - 80px)" }}>
        {!pathname.includes('room/host') && <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Sidebar isShowSidebar={isShowSidebar} sidebarContent={<SidebarContentProvider isShowSidebar={isShowSidebar} />} />
        </Box>}
        <Box sx={{
          background: '#0B0B0B',
          borderLeft: { xs: 'none', sm: pathname.includes('room/host') ? 'none' : `1px solid ${theme.palette.custom.primary.graphite}20` },
          // borderTop: `1px solid ${theme.palette.custom.primary.graphite}20`,
          width: "100%",
          height: {
            xs: pathname === Path.PRIVACY_POLICY ? "100%" : pathname.includes('recommended-videos') || pathname.includes('shorts') || pathname.includes('my-videos') ? "calc(100vh - 55px)" : pathname.includes('room/host')? "calc(100vh - 62px)": "calc(100vh - 105px)",
            sm: pathname === Path.PRIVACY_POLICY ? "100%" : "calc(100vh - 81px)"
          },
          overflow: 'auto'
        }}>{children}</Box>
      </Box>
      <FooterPage />
    </Box>
  );
};

export default Layout;
