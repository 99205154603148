import { Avatar, ListItemIcon, ListItemText, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { Path } from 'constants/index';
import { signOut } from 'firebase/auth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/auth';
import { User } from 'typings/index';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LogoutIcon from '@mui/icons-material/Logout';

import { auth } from '../../../../firebase';
import UploadIcon from 'assets/IconsComponents/Upload';

interface UserCustomMenuProps {
  user: User;
  handleMenuClose: () => void;
}

const UserCustomMenu: React.FC<UserCustomMenuProps> = ({
  user,
  handleMenuClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      dispatch(logout());
      handleMenuClose();
      navigate(Path.ROOT);
      window.location.reload();
    } catch (error) {
      console.error('Ошибка при выходе:', error);
    }
  };

  const handleToMyProfile = () => {
    navigate(`/${user._id}/profile`);
    handleMenuClose();
  };

  const handleToUpload = () => {
    navigate(`/upload`);
    handleMenuClose();
  };

  return (
    <>
      <Stack direction='row' sx={{ p: '24px 24px 10px' }}>
        <Avatar
          src={user.image}
          alt='User Image'
          sx={{ width: 40, height: 40, marginRight: '12px' }}
        />
        <Stack>
          <Typography variant='h5' sx={{ fontWeight: 500, mb: 0.5, color: '#fff', fontFamily: 'DM Sans' }}>
            {user.name}
          </Typography>
          <Typography
            variant='h6'
            sx={{ color: '#6E7184', fontFamily: 'DM Sans' }}
          >
            {user.userName}
          </Typography>
        </Stack>
      </Stack>
      <Stack pb={2}>
        <MenuItem
          sx={{
            p: '8px 16px 8px 24px',
            '&:hover': {
              background: '#79FFE4',
              color: '#000',
            },
            '& .MuiListItemIcon-root': {
              color: '#fff', // default icon color
              '&:hover': {
                color: '#fff', // icon color on hover
              },
            },
            color: '#fff'
          }}
          onClick={handleToMyProfile}
        >
          <ListItemIcon>
            <PermIdentityIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'DM Sans' }}>
              {t('header.profile')}
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          sx={{
            p: '8px 16px 8px 24px',
            '&:hover': {
              background: '#79FFE4',
              color: '#000',
            },
            '& .MuiListItemIcon-root': {
              color: '#fff', // default icon color
              '&:hover': {
                color: '#000', // icon color on hover
              },
            },
            color: '#fff', fontFamily: 'DM Sans'
          }}
          onClick={handleToUpload}>
          <ListItemIcon>
            <UploadIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'DM Sans' }}>
              {t('header.uploadMenu')}
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          sx={{
            p: '8px 16px 8px 24px',
            '&:hover': {
              background: '#79FFE4',
              color: '#000',
            },
            '& .MuiListItemIcon-root': {
              color: '#fff', // default icon color
              '&:hover': {
                color: '#000', // icon color on hover
              },
            },
            color: '#fff', fontFamily: 'DM Sans'
          }}
          onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'DM Sans' }}>
              {t('header.logout')}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Stack>
    </>
  );
};

export default UserCustomMenu;
