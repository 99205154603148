import { Box } from '@mui/material';
import {
  commonMenu,
  protectedMenu,
} from 'components/common/SidebarContentProvider/constants';
import CustomDivider from 'components/ui/CustomDivider';
import DownloadAppIcons from 'components/ui/DownloadAppIcons';
import SidebarList from 'components/ui/SidebarList';
import Subscription from 'components/ui/Subscription';
import { ContentType, Path } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFollowingListStart } from 'store/user';
import { getUserSession } from 'utils/utils';

interface ProtectedSidebarContentProps {
  closeMenu?: () => void;
  isShowSidebar?: boolean;
}

const ProtectedSidebarContent: FC<ProtectedSidebarContentProps> = ({ closeMenu, isShowSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { followingList } = useAppSelector(
    (state) => state.user.getFollowerList
  );

  const { roomId } = useAppSelector((state) => state.stream);

  const { videos } = useAppSelector((state) => state.video.getAllVideo);

  const videoId = !!videos.length && videos[0]._id;

  const user = getUserSession();

  const [activeMenuItem, setActiveMenuItem] = useState<Path | null>(
    Path.RECOMMENDEDVIDEOS
  );
  const [activeSubscriptionId, setActiveSubscriptionId] = useState<
    string | null
  >(null);

  const handleMenuClick = (path: Path) => {
    closeMenu && closeMenu()
    let targetPath: Path | string = path;
    if (user) {
      if (path === Path.RECOMMENDEDVIDEOS) {
        targetPath = `/${user._id}/recommended-videos/${videoId}`;
      }
      if (path === Path.PROFILE) {
        targetPath = `/${user._id}/profile`;
      }
      if (path === Path.SHORTS) {
        targetPath = `/${user._id}/shorts/${videoId}`;
      }
      if (path === Path.ROOM) {
        targetPath = `/room/${roomId}`;
      }
    }

    setActiveSubscriptionId(null);
    setActiveMenuItem(path);
    // navigate(targetPath);
    window.location.href=`${process.env.REACT_APP_BASE_URL}${targetPath}`
  };

  const handleSubscriptionClick = (subscriptionId: string) => {
    setActiveSubscriptionId(subscriptionId);
    setActiveMenuItem(null);
  };

  useEffect(() => {
    const profilePath = location.pathname.includes('profile');
    const shortsPath = location.pathname.includes('shorts');
    const roomPath = location.pathname.includes('room');
    const recommendedVideosPath = location.pathname.includes('recommended-videos');

    profilePath && setActiveMenuItem(Path.PROFILE);
    shortsPath && setActiveMenuItem(Path.SHORTS);
    roomPath && setActiveMenuItem(Path.ROOM);
    recommendedVideosPath && setActiveMenuItem(Path.RECOMMENDEDVIDEOS);
  }, [location]);

  useEffect(() => {
    if (!followingList?.length) {
      dispatch(
        getFollowingListStart({
          userId: user._id,
          type: ContentType.FOLLOWING_LIST,
          isOtherUser: false,
        })
      );
    }
  }, []);

  return (
    <Box>
      <SidebarList
        menu={commonMenu}
        activeMenuItem={activeMenuItem}
        handleMenuClick={handleMenuClick}
        activeSubscriptionId={activeSubscriptionId}
        isShowSidebar={isShowSidebar}
      />

      {isShowSidebar && <CustomDivider />}

      <SidebarList
        menu={protectedMenu}
        activeMenuItem={activeMenuItem}
        handleMenuClick={handleMenuClick}
        activeSubscriptionId={activeSubscriptionId}
        isShowSidebar={isShowSidebar}
      />

      {isShowSidebar && <CustomDivider />
      }
      {isShowSidebar && <Subscription
        followingList={followingList}
        activeSubscriptionId={activeSubscriptionId}
        onSubscriptionClick={handleSubscriptionClick}
      />}

      {isShowSidebar && <CustomDivider />}

      {/* <Box display='flex' alignItems='center' py={1} px={2} my={1}>
        <IconHelp />
        <Typography variant='h6' ml={1.5}>
          {t('sidebar.help')}
        </Typography>
      </Box> */}

      {/* <CustomDivider /> */}

      {isShowSidebar && <DownloadAppIcons />}
    </Box>
  );
};

export default ProtectedSidebarContent;
