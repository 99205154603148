import { Typography } from '@mui/material';
import CustomButton from 'components/ui/Auth/CustomButton';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import { Path } from 'constants/index';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IErrorFormProps {
  handleClose: () => void;
}

const ErrorForm: FC<IErrorFormProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    handleClose();
    navigate(Path.LOGIN);
  };

  return (
    <>
      <ModalTitle title={t('auth.title.error')} />
      <Typography variant='h5' mb={3}>
        {t('auth.form.modalError')}
      </Typography>
      <CustomButton onClick={handleGoToLogin} i18nKey='form.button.login' />
    </>
  );
};

export default ErrorForm;
