import { List, ListItem, ListItemIcon, Typography, useTheme } from '@mui/material';
import { Path } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { FC } from 'react';
import { ISidebarList } from 'typings/index';

interface ISidebarListProps {
  activeMenuItem: Path | null;
  activeSubscriptionId?: string | null;
  handleMenuClick: (path: Path) => void;
  menu: ISidebarList[];
  isShowSidebar?: boolean;
}

const SidebarList: FC<ISidebarListProps> = ({
  activeMenuItem,
  activeSubscriptionId,
  handleMenuClick,
  menu,
  isShowSidebar
}) => {
  const theme = useTheme();
  const { roomId } = useAppSelector((state) => state.stream);
  const displayedMenu = roomId
    ? menu
    : menu.filter((item) => item.title !== 'Stream');

  return (
    <List sx={{ p: isShowSidebar ? '8px 0' : 0 }}>
      {displayedMenu.map(({ id, title, icon, path }) => (
        <ListItem
          key={id}
          sx={{
            display: title === 'My Shorts' ? 'none' : 'flex',
            alignItems: 'center',
            borderRadius: '24px',
            cursor: 'pointer',
            bgcolor:
              activeMenuItem === path && !activeSubscriptionId
                ? `rgba(121, 255, 228, 0.4)`
                : 'transparent',
            '&:hover': {
              bgcolor: `rgba(121, 255, 228, 0.1)`,
            },
          }}
          onClick={() => handleMenuClick(path)}
        >
          {icon}
          {isShowSidebar && <Typography
            variant='h6'
            ml={1.5}
            sx={{ color: '#fff',  }}
          >
            {title}
          </Typography>}
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarList;
