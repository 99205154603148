import { getFromAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getSharingLinkVideoFailure,
  getSharingLinkVideoStart,
  getSharingLinkVideoSuccess,
} from 'store/video/slices/getSharingLinkVideoSlice';

function* getSharingLinkVideoSaga(
  action: ReturnType<typeof getSharingLinkVideoStart>
) {
  const options = { ...action.payload };
  const endpoint = '/client/video/link';

  try {
    const response: AxiosResponse = yield call(getFromAPI, options, endpoint);

    const result: any = response.data?.url ?? null;

    if (result) {
      yield put(getSharingLinkVideoSuccess({ result }));
    } else {
      yield put(getSharingLinkVideoFailure('Ошибка: некорректные данные'));
    }
  } catch (e) {
    yield put(getSharingLinkVideoFailure('Ошибка при выполнении запроса'));
  }
}

export function* watchGetSharingLinkVideoSaga(): SagaIterator {
  yield takeEvery(getSharingLinkVideoStart.type, getSharingLinkVideoSaga);
}
