import { IconButton, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface IActionIconButtonProps {
  icon: React.ReactNode;
  isClicked: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ActionIconButton: FC<IActionIconButtonProps> = ({
  icon,
  isClicked,
  onClick,
}) => {
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        bgcolor: isClicked
          ? theme.palette.custom.primary.white
          : `${theme.palette.custom.primary.graphite}20`,
        transition: 'background-color 0.3s',
        p: 1.5,
        '&:hover': {
          bgcolor: isClicked
            ? theme.palette.custom.primary.white
            : `${theme.palette.custom.primary.graphite}30`,
          opacity: 0.9,
        },
      }}
    >
      {React.cloneElement(icon as React.ReactElement, {
        sx: {
          color: isClicked
            ? '#fff'
            : theme.palette.custom.primary.white,
        },
      })}
    </IconButton>
  );
};

export default ActionIconButton;
