import { createSlice } from '@reduxjs/toolkit';
import { AuthModalType } from 'constants/index';
import { User } from 'typings/index';
import { clearSessionStorage } from 'utils/utils';

interface AuthState {
  user: User | null;
  isLoading: boolean;
  error: string | null;
  formType: AuthModalType | null;
  accessToken: string | null;
}

const initialState: AuthState = {
  user: null,
  isLoading: false,
  error: null,
  formType: null,
  accessToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // авторизация через username/password
    loginStart: (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, { payload }) => {
      state.user = payload;
      state.accessToken = payload.accessToken;
      state.error = null;
      state.isLoading = false;
    },
    loginFailure: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },

    // авторизация через google авторизацию
    googleLoginStart: (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    },
    googleLoginSuccess: (state, { payload }) => {
      state.user = payload.user;
      state.accessToken = payload.accessToken;
      state.error = null;
      state.isLoading = false;
      state.formType = null;
    },
    googleLoginFailure: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.formType = AuthModalType.ERROR;
    },

    // регистрация
    registrationStart: (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    },
    registrationSuccess: (state, { payload }) => {
      state.user = payload.user;
      state.accessToken = payload.accessToken;
      state.error = null;
      state.isLoading = false;
    },
    registrationFailure: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },

    // обновление токена
    refreshTokenStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    refreshTokenSuccess: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.isLoading = false;
      state.error = null;
    },
    refreshTokenFailure: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },

    // логаут
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.isLoading = false;
      state.error = null;
      state.formType = null;
      clearSessionStorage();
      window.dispatchEvent(new Event('loginStatusChange'));
    },

    // ошибки
    clearError: (state) => {
      state.error = null;
    },

    // тип формы
    setFormType: (state, { payload }) => {
      state.formType = payload;
    },
  },
});

export const {
  clearError,
  googleLoginStart,
  googleLoginSuccess,
  googleLoginFailure,
  loginStart,
  loginSuccess,
  loginFailure,
  registrationStart,
  registrationFailure,
  registrationSuccess,
  setFormType,
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenFailure,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
