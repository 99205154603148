import { Box, Button, Checkbox, FormControlLabel, IconButton, Link, Modal, Typography } from '@mui/material';
import { signInWithPopup } from 'firebase/auth';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { googleLoginStart } from 'store/auth';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import { auth, googleProvider } from '../../../../../firebase';
import GoogleIcon from 'assets/IconsComponents/Google';
import ButttonCustom from 'components/ui/Button/Index';
import QRCode from 'qrcode';
import { getFromAPI } from 'api/index';
import cfg from 'config/index';
import { useNavigate } from 'react-router-dom';
import { Path } from 'constants/index';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface ISocialAuthButtonsProps {
  handleClose: () => void;
}

const SocialAuthButtons: FC<ISocialAuthButtonsProps> = ({ handleClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const [handleModalClose, setHandleModalClose] = useState<(() => void) | null>(null);
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      googleProvider.setCustomParameters({
        prompt: 'select_account',
      });
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const idToken = await user.getIdToken();
      const ref = localStorage.getItem('refId')
      dispatch(googleLoginStart({idToken, ref}));
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleQrLogin = async () => {
    try {
      const { data }: any = await getFromAPI(null, `${cfg.API_URL}/client/user/auth/link`);

      const qrData = `qrLogin,${data._id}`;

      const qrCode = await QRCode.toDataURL(qrData);

      setQrCodeUrl(qrCode);
      setIsQrModalOpen(true);

      const intervalId = setInterval(async () => {
        try {
          const response: any = await getFromAPI(null, `${cfg.API_URL}/client/user/auth/link?linkId=${data._id}`);
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data.user))
            localStorage.setItem('token', response.data.accessToken)
            localStorage.setItem('refreshToken', response.data.refreshToken)
            setIsQrModalOpen(false);
            setQrCodeUrl(null);
            clearInterval(intervalId);
            navigate(Path.ROOT)
          }
        } catch (error) {
          clearInterval(intervalId);
        }
      }, 1000);

      setHandleModalClose(() => () => {
        setIsQrModalOpen(false);
        setQrCodeUrl(null);
        clearInterval(intervalId);
      });
    } catch (error) {
      console.error('Failed to generate QR code:', error);
    }
  };

  return (
    <Box>
      <Button
        onClick={handleGoogleLogin}
        startIcon={<GoogleIcon />}
        fullWidth
        sx={{
          textTransform: 'none',
          color: '#fff',
          borderRadius: '36px',
          p: '16px 40px',
          border: '1px solid rgba(110, 113, 132, 0.45)',
          mb: 2
        }}
      >
        Login with &nbsp; <span style={{ color: '#79FFE4' }}>Google</span>
      </Button>
      <Button
        onClick={handleQrLogin}
        startIcon={<QrCodeScannerRoundedIcon />}
        fullWidth
        sx={{
          textTransform: 'none',
          borderRadius: '36px',
          p: '16px 40px',
          border: '1px solid rgba(110, 113, 132, 0.45)',
          mb: 1,
          color: '#fff'
        }}
      >
        Login by  &nbsp; <span style={{ color: '#79FFE4' }}>QR code</span>
      </Button>
      <Box>
        <FormControlLabel sx={{color: '#fff'}} control={<Checkbox />} label="Remember this device" />
      </Box>
      <ButttonCustom title="Login in" fullWidth sx={{ height: '56px', borderRadius: '36px', mt: 3 }} />
      <Typography sx={{ fontSize: '12px', textAlign: 'center', mt: 3, color: '#fff', fontFamily: 'Dm sans' }}>
        Continuing, you accept I Earn Terms of Use, and also confirm that you have read the document &nbsp;
        <Link sx={{ textDecoration: 'none', color: '#79FFE4', fontFamily: 'Dm sans' }} href="/privacy-policy">Privacy Policy</Link>
      </Typography>
      <Modal open={isQrModalOpen} onClose={handleModalClose || (() => setIsQrModalOpen(false))}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#090909',
            boxShadow: 24,
            p: 4,
            borderRadius: '24px',
            width: { xs: '90%', sm: '420px' }
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: '#79FFE4', fontSize: '28px',  }}>Login by QR code</Typography>
            <IconButton onClick={handleModalClose || (() => setIsQrModalOpen(false))}>
              <CloseOutlinedIcon sx={{ color: '#1C1B20' }} />
            </IconButton>
          </Box>
          {qrCodeUrl ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box component='img' sx={{
                boxShadow: '0px 2px 10px 0px rgba(28, 27, 32, 0.12)',
                width: '220px',
                height: '220px',
                m: '30px auto',
                borderRadius: '16px'
              }}
                src={qrCodeUrl}
                alt="QR Code"
              />
              <Typography sx={{ textAlign: 'center', fontSize: '16px', color: '#fff' }}>Logging in by QR code is easy and safe!</Typography>
              <Typography sx={{ textAlign: 'center', fontSize: '14px', mt: 1, color: '#fff' }}>Scan the QR code with your cell phone< br /> camera and log in to your account</Typography>
            </Box>
          ) : (
            <Typography sx={{ color: '#fff'}}>Generating QR Code...</Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default SocialAuthButtons;
