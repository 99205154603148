import { createSvgIcon } from "@mui/material";

// FIXME: Need extract only document icon, without background
const HdIcon = createSvgIcon(
    <svg
        width={20}
        height={16}
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4 11H5.5V9H7.5V11H9V5H7.5V7.5H5.5V5H4V11ZM11 11H15C15.2833 11 15.5208 10.9042 15.7125 10.7125C15.9042 10.5208 16 10.2833 16 10V6C16 5.71667 15.9042 5.47917 15.7125 5.2875C15.5208 5.09583 15.2833 5 15 5H11V11ZM12.5 9.5V6.5H14.5V9.5H12.5ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM2 14H18V2H2V14Z"
            fill="currentColor"
            fillOpacity={0.45}
        />
    </svg>,
    "Hd",
);

export default HdIcon;
