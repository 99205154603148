import { Typography } from '@mui/material';
import CustomButton from 'components/ui/Auth/CustomButton';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import { Path } from 'constants/index';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PasswordRecoveryStep4: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(Path.LOGIN);
  };

  return (
    <>
      <ModalTitle title={t('auth.title.successfullyChangingPassword')} />

      <Typography variant='h5' mb={3}>
        {t('auth.form.changingPasswordInfo')}
      </Typography>

      <CustomButton onClick={handleClick} i18nKey='form.button.login' />
    </>
  );
};

export default PasswordRecoveryStep4;
