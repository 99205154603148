import ModalForm from 'components/common/ModalForm';
import LoginForm from 'components/ui/Auth/LoginForm';
import PasswordRecoveryForm from 'components/ui/Auth/PasswordRecoveryForm';
import RegistrationForm from 'components/ui/Auth/RegistrationForm';
import ErrorForm from 'components/ui/ErrorForm';
import { AuthModalType } from 'constants/index';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearError, setFormType } from 'store/auth';
import { IModalFormProps } from 'typings/index';

const AuthModalForm: FC<IModalFormProps> = ({ open, onClose, type }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (type !== AuthModalType.ERROR) {
      dispatch(setFormType(type));
    }
  }, []);

  const handleClose = () => {
    type === AuthModalType.ERROR && dispatch(clearError());
    onClose();
  };

  const renderForm = () => {
    switch (type) {
      case AuthModalType.LOGIN:
        return <LoginForm handleClose={handleClose} />;
      case AuthModalType.REGISTRATION:
        return <RegistrationForm handleClose={handleClose} />;
      case AuthModalType.FORGOT_PASSWORD:
        return <PasswordRecoveryForm />;
      case AuthModalType.ERROR:
        return <ErrorForm handleClose={handleClose} />;
      default:
        return;
    }
  };

  return (
    <ModalForm open={open} onClose={handleClose}>
      {renderForm()}
    </ModalForm>
  );
};

export default AuthModalForm;
