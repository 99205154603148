import { Box } from '@mui/material';
import { IconIEarnLogoFullText, IconIEarnLogoImageWhite } from 'assets';
import { Path } from 'constants/index';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar: FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate(Path.ROOT);
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      onClick={handleGoHome}
      sx={{ cursor: 'pointer' }}
    >
      {/* <Box mr={2}>
        <IconMenu />
      </Box> */}
      <Box mr={1}>
        <IconIEarnLogoImageWhite />
      </Box>
      {/* <IconIEarnLogoFullText /> */}
    </Box>
  );
};

export default Sidebar;
