import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth/slices/authSlice';
import notificationReducer from './notification/slices/notificationSlice';
import getReportReasonReducer from './report/slices/getReportReasonSlice';
import reportVideoReducer from './report/slices/reportSlice';
import setRoomIdReducer from './stream/slices/streamRoomSlice';
import followReducer from './user/slices/followSlice';
import getFollowerListReducer from './user/slices/getFollowerListSlice';
import getProfileReducer from './user/slices/getProfileSlice';
import actionVideoReducer from './video/slices/actionVideoSlice';
import addCommentReducer from './video/slices/addCommentSlice';
import getAllVideoReducer from './video/slices/getAllVideoSlice';
import getCommentsReducer from './video/slices/getCommentsSlice';
import getSharingLinkVideoReducer from './video/slices/getSharingLinkVideoSlice';
import getUserVideoReducer from './video/slices/getUserVideoSlice';
import likeCommentReducer from './video/slices/likeCommentSlice';
import likeVideoReducer from './video/slices/likeVideoSlice';
import uploadVideoReducer from './video/slices/uploadVideoSlice';

export const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  report: combineReducers({
    getReportReason: getReportReasonReducer,
    reportVideo: reportVideoReducer,
  }),
  video: combineReducers({
    addComment: addCommentReducer,
    getAllVideo: getAllVideoReducer,
    getComments: getCommentsReducer,
    getSharingLinkVideo: getSharingLinkVideoReducer,
    getUserVideo: getUserVideoReducer,
    likeVideo: likeVideoReducer,
    likeComment: likeCommentReducer,
    actionVideo: actionVideoReducer,
    uploadVideo: uploadVideoReducer,
  }),
  user: combineReducers({
    follow: followReducer,
    getFollowerList: getFollowerListReducer,
    getProfile: getProfileReducer,
  }),
  stream: setRoomIdReducer,
});
