import { Box, type BoxProps } from "@mui/material";
import useDebounce from "hooks/useDebounce";
import { useRef, useState, type PointerEvent } from "react";

export interface IScrollContainerProps extends BoxProps
{
	/**
	 * @default "row"
	 */
	direction?: "row" | "column";
	/**
	 * Snaps to the beginning of the element after scrolling
	 * @default false
	 */
	snapping?: boolean;
}

function ScrollContainer({ direction = "row", snapping = false, sx = [], ...props }: IScrollContainerProps)
{
	const containerRef = useRef<HTMLDivElement>(null);

	const [ isDragging, setIsDragging ] = useState<boolean>(false);
	const [ startPos, setStartPos ] = useState<number>(0);
	const [ scrollStart, setScrollStart ] = useState<number>(0);

	const draggingDebounce = useDebounce(isDragging, 175);

	function handlePointerDown(event: PointerEvent<HTMLDivElement>)
	{
		if (containerRef.current === null)
		{
			return;
		}

		if (event.pointerType === "mouse" || event.pointerType === "pen")
		{
			event.preventDefault();

			setIsDragging(true);

			setStartPos(event[ direction === "row" ? "clientX" : "clientY" ]);
			setScrollStart(containerRef.current[ direction === "row" ? "scrollLeft" : "scrollTop" ]);
		}
	}

	function handlePointerMove(event: PointerEvent<HTMLDivElement>)
	{
		if (!isDragging || containerRef.current === null)
		{
			return;
		}

		const currentPos = direction === "row" ? event.clientX : event.clientY;
		const scrollDelta = currentPos - startPos;

		containerRef.current[ direction === "row" ? "scrollLeft" : "scrollTop" ] = scrollStart - scrollDelta;
	}

	function handlePointerEnd()
	{
		setIsDragging(false);
	}

	return (
		<Box
			ref={containerRef}
			onPointerDown={handlePointerDown}
			onPointerMove={handlePointerMove}
			onPointerUp={handlePointerEnd}
			onPointerCancel={handlePointerEnd}
			onPointerLeave={handlePointerEnd}
			sx={[
				{
					position: "relative",
					userSelect: "none",
					cursor: "grab",

					"& > *":
					{
						scrollSnapAlign: "start"
					}
				},
				direction === "row" &&
				{
					overflowX: "auto",

					"&::-webkit-scrollbar":
					{
						height: "0px !important"
					}
				},
				direction === "column" &&
				{
					overflowY: "auto",

					"&::-webkit-scrollbar":
					{
						width: "0px !important"
					}
				},
				snapping === true && isDragging === false &&
				{
					scrollSnapType: (direction === "row" ? "x" : "y") + " mandatory"
				},
				isDragging === true &&
				{
					cursor: "grabbing",

					"& > *":
					{
						cursor: "grabbing"
					}
				},
				draggingDebounce === true &&
				{
					"& > *":
					{
						pointerEvents: "none"
					}
				},
				...Array.isArray(sx) ? sx : [ sx ]
			]}
			{...props}
		/>
	);
}

export default ScrollContainer;
