import { Box, Button, IconButton, Link, useTheme } from '@mui/material';
import { getFromAPI } from 'api/index';
import AboutVideo from 'components/ui/AboutVideo';
import VideoActionIcons from 'components/ui/VideoActionIcons';
import VideoControlButtons from 'components/ui/VideoControlButtons';
import cfg from 'config/index';
import { Path } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getAllVideoStart,
  setCurrentPlayingVideoId,
  setMute,
  setVolume,
} from 'store/video';
import { Video } from 'typings/index';
import { getUserSession } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import DialogDownloadApp from './DialogDownloadApp';

interface IVideoProps {
  video: Video;
  isLoggedIn: boolean;
  lastVideoId: string;
  autoplay?: boolean;
  loop?: boolean;
  videos: Video[];
  index: number;
  userId?: string | null;
  videoId?: string | null;
  setMaxCountVideo?: (val: number) => void;
  maxCountVideo?: number;
  shouldLoad?: boolean;
  pageUrl?: string;
  preload?: boolean;
  id?: string;
  handlePlayToggle: () => void;
}

const VideoPlayer: FC<IVideoProps> = ({
  video,
  isLoggedIn,
  lastVideoId,
  autoplay = true,
  loop = true,
  videos,
  index,
  userId,
  videoId,
  setMaxCountVideo,
  maxCountVideo,
  pageUrl,
  preload,
  handlePlayToggle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const user = getUserSession();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const lastVideoRef = useRef<HTMLDivElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const { currentPlayingVideoId, volume, isMuted, previousVolume } =
    useAppSelector((state) => state.video.actionVideo);

  const isCurrentPlaying = currentPlayingVideoId === video._id;

  useEffect(() => {
    setIsPlaying(isCurrentPlaying);
  }, [currentPlayingVideoId]);

  const observeVideo = useCallback(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShouldLoad(true);
            dispatch(setCurrentPlayingVideoId(video._id));
            if (userId) {
              if (
                window.location.pathname !==
                `/${userId}/${pageUrl}/${video._id}`
              ) {
                getFromAPI(
                  null,
                  `${cfg.API_URL}/client/video/watched?videoId=${video._id}`
                );
                window.history.replaceState(
                  {},
                  '',
                  `/${userId}/${pageUrl}/${video._id}`
                );
              }
            } else if (
              window.location.pathname !== `/${pageUrl}/${video._id}`
            ) {
              window.history.replaceState({}, '', `/${pageUrl}/${video._id}`);
            }
          } else {
            setShouldLoad(false);
          }
        });
      },
      { threshold: 0.8 }
    );
    return observer;
  }, [dispatch, video._id, userId]);

  useEffect(() => {
    if (containerRef.current) {
      const observer = observeVideo();
      observer.observe(containerRef.current);
      if (
        containerRef.current.getBoundingClientRect().top <= window.innerHeight
      ) {
        setShouldLoad(true);
      }
      return () => observer.disconnect();
    }
  }, [observeVideo]);

  const loadMoreVideos = useCallback(() => {
    if (pageUrl !== 'my-videos') {
      dispatch(getAllVideoStart({ userId: user?._id }));
      if (setMaxCountVideo && maxCountVideo) {
        setMaxCountVideo(maxCountVideo + 20);
      }
    }
  }, [dispatch, user, maxCountVideo, setMaxCountVideo, pageUrl]);

  useEffect(() => {
    if (!lastVideoRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        const lastEntry = entries[0];
        if (lastEntry.isIntersecting) {
          loadMoreVideos();
          observer.disconnect(); // Отключение, чтобы предотвратить повторные вызовы
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(lastVideoRef.current);

    return () => observer.disconnect();
  }, [loadMoreVideos]);
  const handleOpenMusic = () => {
    if (!isLoggedIn) {
      navigate(Path.LOGIN);
      return;
    }
    navigate(Path.MUSIC);
  };

  const videoUrl = useMemo(() => video.videoUrl, [video.videoUrl]);

  // const handlePlayToggle = () => {
  //   setIsPlaying(!isPlaying);
  //   dispatch(setCurrentPlayingVideoId(isPlaying ? null : video._id));
  // };

  return (
    <Box>
      <Box
        ref={index === videos.length - 2 ? lastVideoRef : containerRef}
        id={video._id}
        sx={{
          height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 130px)' },
          position: 'relative',
          marginBottom: { xs: 0, sm: '16px' },
          borderRadius: { xs: 0, sm: '24px' },
          overflow: 'hidden',
          // background: '#fff',
        }}
      >
        {/* <VideoControlButtons
          isMuted={isMuted}
          isPlaying={isPlaying}
          toggleMute={() => dispatch(setMute(!isMuted))}
          togglePlay={handlePlayToggle}
          volume={volume}
          previousVolume={previousVolume}
          onVolumeChange={(event, newValue) =>
            dispatch(setVolume(newValue as number))
          }
          containerRef={containerRef}
        /> */}
        {!isVideoReady && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${video.videoImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: 1,
            }}
          />
        )}
        <Box
          onClick={handlePlayToggle}
          sx={{ cursor: 'pointer', width: '100%', height: '100%' }}
        >
          {(shouldLoad || preload) && (
            <ReactPlayer
              // id={id}
              style={{ aspectRatio: '9 / 16', borderRadius: '24px' }}
              className='videoContainer'
              url={videoUrl}
              playing={isPlaying}
              loop={loop}
              width='100%'
              height='100%'
              muted={isMuted}
              playsinline={true}
              volume={volume}
              preload='auto'
              onReady={() => {
                setIsVideoReady(true);
                if (autoplay && isPlaying) {
                  dispatch(setCurrentPlayingVideoId(video._id));
                }
              }}
              config={{
                file: {
                  attributes: { preload: 'auto', poster: video.videoImage },
                },
              }}
            />
          )}
        </Box>
        <VideoActionIcons
          handleOpenMusic={handleOpenMusic}
          isLoggedIn={isLoggedIn}
          video={video}
        />
        <AboutVideo isLoggedIn={isLoggedIn} video={video} />
      </Box>
    </Box>
  );
};

export default VideoPlayer;
