import { CssBaseline, ThemeProvider } from '@mui/material';
import Layout from 'components/common/Layout';
import AuthPage from 'components/pages/AuthPage';
import MainContentPage from 'components/pages/MainContentPage';
import MusicPage from 'components/pages/MusicPage';
import ProfilePage from 'components/pages/ProfilePage';
import RoomPage from 'components/pages/RoomPage';
import ShortsPage from 'components/pages/ShortsPage';
import SubscribeListPage from 'components/pages/SubscribeListPage';
import ChangeProfilePage from 'components/ui/ProfileForm';
import { Path } from 'constants/index';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import theme from 'styles/theme';
import UploadPage from './pages/UploadPage/index.tsx';
import RecomendedVidosPage from './pages/RecommendedVideosPage/RecommendedVideos';
import Broadcasts from './pages/Broadcasts/Broadcasts';
import MyVideos from './pages/MyVideos/MyVideos';
import '../styles/main.scss';
import RoomPageHost from './pages/RoomPage/RoomPageHost';
import PopularChannelsPage from './pages/PopularChannelsPage/PopularChannelsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            <Route path={Path.LOGIN} element={<AuthPage />} />
            {/* <Route path={Path.REGISTRATION} element={<AuthPage />} /> */}
            {/* <Route path={Path.FORGOT_PASSWORD} element={<AuthPage />} /> */}
            <Route path={Path.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
            <Route path={Path.MUSIC} element={<MusicPage />} />
            <Route path={Path.MYVIDEOS} element={<MyVideos />} />
            <Route path={Path.BROADCASTS} element={<Broadcasts />} />
            <Route path={Path.RECOMMENDEDVIDEOS} element={<RecomendedVidosPage />} />
            <Route path={Path.RECOMMENDEDVIDEOSUSERS} element={<RecomendedVidosPage />} />
            <Route path={Path.PROFILE} element={<ProfilePage />} />
            <Route path={Path.CHANGE_PROFILE} element={<ChangeProfilePage />} />
            <Route path={Path.ROOT} element={<MainContentPage />} />
            <Route path={Path.SHORTS} element={<ShortsPage />} />
            <Route path={Path.FOLLOWER_LIST} element={<SubscribeListPage />} />
            <Route path={Path.FOLLOWING_LIST} element={<SubscribeListPage />} />
            <Route path={Path.USER_ID_SHORTS} element={<ShortsPage />} />
            <Route path={Path.ROOM} element={<RoomPage />} />
            <Route path={Path.ROOMHOST} element={<RoomPageHost />} />
            <Route path={Path.UPLOAD} element={<UploadPage />} />
            <Route path={Path.POPULARCHANNELS} element={<PopularChannelsPage />} />
            {/* <Route path='*' element={<Navigate to={Path.ROOT} />} /> */}
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
