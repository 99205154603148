import { createSvgIcon } from "@mui/material";

// FIXME: Need extract only document icon, without background
const PopularChannelsIcon = createSvgIcon(
    <svg
        width={44}
        height={44}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_15937_4161)">
            <rect width={44} height={44} rx={22} fill="#F04444" />
            <path
                d="M11.812 36.6333C9.63171 33.4405 5.1562 26.4899 8.74748 20.4409C12.0002 14.9621 14.6091 14.2594 16.345 11.5407C17.545 9.66149 18.2297 7.36344 18.2984 7.14734C18.3566 8.17898 18.5514 9.39191 18.355 11.5177C18.2438 12.7223 17.8693 13.986 17.7589 14.5772C19.0389 13.7013 22.0128 11.3165 23.6688 8.7846C25.3247 6.2527 26.7172 3.77987 27.2065 2.85993C28.0768 3.55192 29.6114 5.58424 30.4089 8.72504C31.3283 12.3453 30.8355 16.5686 30.8355 16.5686C30.8355 16.5686 31.8256 15.4446 32.2111 13.2641C32.4423 11.9566 32.3989 8.9574 32.4494 8.5616C34.7149 12.5206 35.3911 15.7337 35.0168 20.8771C34.5489 27.3063 32.9215 29.5428 31.0986 32.2978C29.2757 35.0529 26.3172 36.5384 23.7118 37.857C22.4652 38.4879 17.681 39.4418 17.681 39.4418C17.681 39.4418 21.612 35.6008 22.6784 33.1111C23.4402 31.3326 23.8351 29.0942 23.6332 27.7103C23.4313 26.3264 23.0379 24.1554 22.837 23.2345C22.0339 24.6093 20.773 26.4145 19.5875 27.6386C18.7256 28.5286 14.4557 31.6574 14.4557 31.6574C14.4557 31.6574 15.9643 29.3513 16.5903 28.1462C17.0599 27.2424 17.2314 26.26 17.3085 25.802C16.3719 26.4321 14.2998 27.9902 13.5038 29.1814C12.5088 30.6705 11.7498 32.7762 11.8061 33.9789C11.8511 34.9411 11.8288 36.1494 11.812 36.6333Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_15937_4161">
                <rect width={44} height={44} rx={22} fill="white" />
            </clipPath>
        </defs>
    </svg>,
    "PopularChannels",
);

export default PopularChannelsIcon;
