import OverlayLoader from 'components/ui/OverlayLoader';
import SubscribeListContent from 'components/ui/SubscribeListContent';
import { ContentType } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getFollowingListStart } from 'store/user';
import { getUserSession } from 'utils/utils';

const SubscribeListPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userId: toUserId } = useParams<{ userId: string }>();
  const user = getUserSession();

  const {
    followingList,
    followerList,
    otherUserFollowerList,
    otherUserFollowingList,
  } = useAppSelector((state) => state.user.getFollowerList);

  const userId = user._id;
  const isFollowerType = useMemo(
    () => location.pathname.includes(ContentType.FOLLOWER_LIST),
    [location.pathname]
  );

  // Проверяем, зашел ли пользователь в свой профиль
  const isOwner = useMemo(() => user && toUserId === userId, [user, toUserId]);

  useEffect(() => {
    if (!followingList.length && userId === toUserId) {
      dispatch(
        getFollowingListStart({
          userId,
          type: ContentType.FOLLOWING_LIST,
          isOtherUser: false,
        })
      );
    }
  }, [followingList.length, toUserId, userId]);

  useEffect(() => {
    if (!followerList.length && userId === toUserId) {
      dispatch(
        getFollowingListStart({
          userId,
          type: ContentType.FOLLOWER_LIST,
          isOtherUser: false,
        })
      );
    }
  }, [followerList.length, toUserId, userId]);

  useEffect(() => {
    if (!otherUserFollowingList.length && toUserId && userId !== toUserId) {
      dispatch(
        getFollowingListStart({
          userId: toUserId,
          type: ContentType.FOLLOWING_LIST,
          isOtherUser: true,
        })
      );
    }
  }, [otherUserFollowingList.length, toUserId, userId]);

  useEffect(() => {
    if (!otherUserFollowerList.length && toUserId && userId !== toUserId) {
      dispatch(
        getFollowingListStart({
          userId: toUserId,
          type: ContentType.FOLLOWER_LIST,
          isOtherUser: true,
        })
      );
    }
  }, [otherUserFollowerList.length, toUserId, userId]);

  if (!user) {
    return <OverlayLoader />;
  }

  return (
    <SubscribeListContent isOwner={isOwner} isFollowerType={isFollowerType} />
  );
};

export default SubscribeListPage;
