import { Box, Stack } from '@mui/material';
import CustomDivider from 'components/ui/CustomDivider';
import { FC } from 'react';

import OverlayLoader from 'components/ui/OverlayLoader';
import { useAppSelector } from 'hooks/useRedux';
import Content from './Content';
import Information from './Information';
import Menu from './Menu';

interface IProfileContentProps {
  isOwner: boolean;
}

const ProfileContent: FC<IProfileContentProps> = ({ isOwner }) => {
  const { isLoading: isGetProfileLoading } = useAppSelector(
    (state) => state.user.getProfile
  );
  const { isLoading: isGetUserVideoLoading } = useAppSelector(
    (state) => state.video.getUserVideo
  );

  if (isGetProfileLoading || isGetUserVideoLoading) {
    return <OverlayLoader />;
  }

  return (
    <Stack
      sx={{
        flexGrow: 1,
        height: '100%',
        p: { xs: 0, sm: 4 }
      }}
      width='100%'
    >
      <Box sx={{ width: { xs: '100%', sm: '90%', md: '85%', lg: '80%' }, m: '0 auto' }}>
        <Box mb={1} sx={{ alignSelf: 'center' }} width='100%'>
          {/* <IconBackgroundProfile /> */}
          <Information />
        </Box>

        <Menu isOwner={isOwner} />
      </Box>
    </Stack>
  );
};

export default ProfileContent;
