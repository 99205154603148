import OverlayLoader from 'components/ui/OverlayLoader';
import { Path } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllVideoStart } from 'store/video';
import { getUserSession } from 'utils/utils';

const MainContentPage: FC = () => {
  const user = getUserSession();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const refId = searchParams.get('referrerUser');
  const videoIdRef = searchParams.get('sharedVideoId');
  refId && localStorage.setItem('refId', refId);
  const userId = user?._id || null;
  const { videos } = useAppSelector((state) => state.video.getAllVideo);

  const videoId = !!videos.length && videos[0]._id;

  useEffect(() => {
    if (!videos.length || !userId) {
      dispatch(getAllVideoStart({ userId, videoId: videoIdRef }));
    }
  }, [userId, videos.length]);

  useEffect(() => {
    if (!userId && !videoId && !videoIdRef) {
      navigate(Path.RECOMMENDEDVIDEOS);
    }

    if (userId) {
      if (videoIdRef) {
        navigate(`/${userId}/recommended-videos/${videoIdRef}`);
      } else {
        navigate(`/${userId}/recommended-videos/${videoId}`);
      }
    } else {
      if (videoIdRef) {
        navigate(`/recommended-videos/${videoIdRef}`);
      } else {
        navigate(`/recommended-videos/${videoId}`);
      }
    }
  }, [userId, navigate, videoId]);

  return <OverlayLoader />;
};

export default MainContentPage;
