import { uploadFileToAPI } from 'api/index';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  uploadVideoFailure,
  uploadVideoStart,
  uploadVideoSuccess,
} from 'store/video/slices/uploadVideoSlice';

function* uploadVideoSaga(action: ReturnType<typeof uploadVideoStart>) {
  const endpoint = '/client/video/uploadvideo';
  const { videoData, userId } = action.payload;

  const formData = new FormData();
  formData.append(
    'videoTime',
    String(Math.floor(videoData.file.size / 1000000))
  );
  formData.append('caption', videoData.file.name);
  formData.append('videoUrl', videoData.file);

  try {
    const response: AxiosResponse = yield call(
      uploadFileToAPI,
      { userId },
      formData,
      endpoint
    );

    const result: any = response.data ?? null;

    if (result.data) {
      yield put(uploadVideoSuccess(result.data));
    } else {
      yield put(uploadVideoFailure('Ошибка: некорректные данные'));
    }
  } catch (e) {
    yield put(uploadVideoFailure('Ошибка при выполнении запроса'));
  }
}

export function* watchUploadVideoSaga(): SagaIterator {
  yield takeEvery(uploadVideoStart.type, uploadVideoSaga);
}
