import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, IconButton, Slider, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVolume } from 'store/video';

interface IVideoControlButtonsProps {
  isMuted: boolean;
  isPlaying: boolean;
  volume: number;
  previousVolume: number;
  toggleMute: () => void;
  togglePlay: () => void;
  onVolumeChange: (event: Event, newValue: number | number[]) => void;
}

const VideoControlButtons: FC<IVideoControlButtonsProps> = ({
  isMuted,
  isPlaying,
  volume,
  previousVolume,
  toggleMute,
  togglePlay,
  onVolumeChange,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isVolumeHovered, setIsVolumeHovered] = useState(false);

  const handleToggleMute = () => {
    if (isMuted || volume === 0) {
      // Если звук выключен, возвращаем прежний уровень громкости
      dispatch(setVolume(previousVolume));
    } else {
      // Запоминаем текущую громкость и отключаем звук
      dispatch(setVolume(0));
    }
    toggleMute();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '24px',
        left: '24px',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      {/* Кнопка Play/Pause */}
      <IconButton onClick={togglePlay} sx={{ bgcolor: 'rgba(0, 0, 0, 0.5)' }}>
        {isPlaying ? (
          <PauseIcon sx={{ color: theme.palette.common.white }} />
        ) : (
          <PlayArrowIcon sx={{ color: theme.palette.common.white }} />
        )}
      </IconButton>

      {/* Контейнер для иконки громкости и слайдера */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '24px',
          padding: '4px 8px',
          transition: 'width 0.3s ease',
          width: isVolumeHovered ? '120px' : '40px',
          overflow: 'hidden',
        }}
        onMouseEnter={() => setIsVolumeHovered(true)}
        onMouseLeave={() => setIsVolumeHovered(false)}
      >
        {/* Иконка громкости */}
        <IconButton onClick={handleToggleMute} sx={{ padding: 0 }}>
          {isMuted || volume === 0 ? (
            <VolumeOffIcon sx={{ color: theme.palette.common.white }} />
          ) : (
            <VolumeUpIcon sx={{ color: theme.palette.common.white }} />
          )}
        </IconButton>

        {/* Слайдер громкости */}
        {isVolumeHovered && (
          <Slider
            value={volume}
            onChange={(event, newValue) => {
              const volumeValue = Array.isArray(newValue)
                ? newValue[0]
                : newValue;

              if (volumeValue === 0) {
                toggleMute();
              } else {
                onVolumeChange(event, volumeValue);
                if (isMuted) {
                  toggleMute();
                }
              }
            }}
            aria-labelledby='volume-slider'
            min={0}
            max={1}
            step={0.1}
            sx={{
              width: '60px',
              color: theme.palette.common.white,
              ml: '16px',
              '& .MuiSlider-thumb': {
                width: 16,
                height: 16,
              },
              '& .MuiSlider-track': {
                borderRadius: '4px',
              },
              '& .MuiSlider-rail': {
                opacity: 0.3,
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default VideoControlButtons;
