/* eslint-disable no-param-reassign */
import axios, { AxiosResponse } from 'axios';
import { SECRET_KEY } from 'constants/index';
import { refreshTokenStart } from 'store/auth';
import { store } from 'store/store';
import { Primitive } from 'typings/index';
import { params2form } from 'utils/index';
import cfg from '../config';

export const API = axios.create({
  baseURL: cfg.API_URL,
});

// Добавляем интерсептор
API.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    // Проверяем, истек ли токен
    if (response.status === 401 && response.data.error === 'Token expired') {
      // Если токен истек, инициируем обновление токена
      store.dispatch(refreshTokenStart());

      // Создаем промис для ожидания обновления токена
      return new Promise((resolve, reject) => {
        const unsubscribe = store.subscribe(() => {
          const state = store.getState().auth; // Получаем текущее состояние аутентификации
          if (state.accessToken) {
            // Если новый токен доступен, пробуем повторить запрос
            error.config.headers['Authorization'] =
              `Bearer ${state.accessToken}`;
            unsubscribe(); // Отписываемся от подписки
            resolve(API.request(error.config)); // Повторяем исходный запрос
          } else if (state.error) {
            // Если есть ошибка, отклоняем промис
            unsubscribe(); // Отписываемся от подписки
            reject(state.error);
          }
        });
      });
    }

    return Promise.reject(error);
  }
);

const generateAPIParams = (
  options: Record<string, Primitive | Primitive[]> | null,
  withToken: boolean = true,
  isFormData: boolean = false
) => {
  const token = localStorage.getItem('token');

  const headers: Record<string, string> = {
    ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
    'Access-Control-Allow-Origin': '*',
    key: SECRET_KEY,
  };

  if (token && withToken) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const query = options ? `?${params2form(options)}` : '';
  return { headers, query };
};

// Функция для загрузки файлов
export const uploadFileToAPI = (
  options: Record<string, Primitive | Primitive[]> | null,
  formData: FormData,
  endpoint: string,
  type: 'post' | 'put' | 'patch' = 'post',
  withToken: boolean = true,
  baseURL?: string,
): Promise<AxiosResponse<unknown>> => {
  const { headers, query } = generateAPIParams(options, withToken, true);
  return API[type](`${endpoint}${query}`, formData, {
    headers,
    baseURL,
  });
};

export const getFromAPI = (
  options: Record<string, Primitive | Primitive[]> | null,
  endpoint: string,
  withToken: boolean = true,
  baseURL?: string
): Promise<AxiosResponse<unknown>> => {
  const { headers, query } = generateAPIParams(options, withToken);
  return API.get(`${endpoint}${query}`, { headers, baseURL });
};

export const postToAPI = (
  options: Record<string, Primitive | Primitive[]> | null,
  body: Record<string, Primitive | Primitive[] | null> | FormData,
  endpoint: string,
  withToken: boolean = true,
  baseURL?: string
): Promise<AxiosResponse<unknown>> => {
  const { headers, query } = generateAPIParams(options, withToken);
  return API.post(`${endpoint}${query}`, body, {
    headers,
    baseURL,
  });
};

export const patchToAPI = (
  options: Record<string, Primitive | Primitive[]> | null,
  body: Record<string, Primitive | Primitive[] | null> | FormData,
  endpoint: string,
  withToken: boolean = true,
  baseURL?: string
): Promise<AxiosResponse<unknown>> => {
  const { headers, query } = generateAPIParams(options, withToken);
  return API.patch(`${endpoint}${query}`, body, {
    headers,
    baseURL,
  });
};

export const putToAPI = (
  options: Record<string, Primitive | Primitive[]> | null,
  body: Record<string, Primitive | Primitive[]>,
  endpoint: string,
  withToken: boolean = true
): Promise<AxiosResponse<unknown>> => {
  const { headers, query } = generateAPIParams(options, withToken);
  return API.put(`${endpoint}${query}`, body, {
    headers,
  });
};

export const deleteFromAPI = (
  options: Record<string, Primitive | Primitive[]> | null,
  endpoint: string,
  withToken: boolean = true
): Promise<AxiosResponse<unknown>> => {
  const { headers, query } = generateAPIParams(options, withToken);
  return API.delete(`${endpoint}${query}`, { headers });
};
