import { Path } from 'constants/index';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import theme from 'styles/theme';

interface IPrivacyButtonProps {
  handleClose: () => void;
}

const PrivacyButton: FC<IPrivacyButtonProps> = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={Path.PRIVACY_POLICY}
      onClick={handleClose}
      style={{
        color: theme.palette.custom.primary.blue,
        textDecoration: 'none',
      }}
    >
      {t('form.button.privacyPolicy')}
    </Link>
  );
};

export default PrivacyButton;
