import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import Footer from './Footer';
import SiteLogo from './SiteLogo';
import { useLocation } from 'react-router-dom';
import { Path } from 'constants/index';

interface ISidebarProps {
  sidebarContent?: React.ReactNode;
  isShowSidebar?: boolean;
}

const Sidebar: FC<ISidebarProps> = ({ sidebarContent, isShowSidebar }) => {
  const theme = useTheme();
  const { pathname } = useLocation()

  return (
    <Box
      sx={{ width: isShowSidebar ? '280px' : '80px', minWidth: isShowSidebar ? '280px' : '80px', background: '#111111' }}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      height="100%"
    >
      <Box>
        {pathname === Path.PRIVACY_POLICY && <Box
          sx={{
            borderRight: `1px solid rgba(110, 113, 132, 0.2)`,
            height: '80px',
            display: 'flex',
          }}
        >
          <SiteLogo />
        </Box>}

        <Box sx={{ p: "36px 16px 18px" }}>
          {sidebarContent}
        </Box>
      </Box>

      {isShowSidebar && <Box pl={2} pr={3} pb={2}>
        <Footer />
      </Box>}
    </Box>
  );
};

export default Sidebar;
