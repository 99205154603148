import { Alert, Box, IconButton, Snackbar } from '@mui/material';
import { FC, useState } from 'react';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import MicOffIcon from '@mui/icons-material/MicOff';
import ButttonCustom from '../Button/Index';
import { useNavigate } from 'react-router-dom';
import { Path } from 'constants/index';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

interface ZegoButtonsProps {
  setMicro: (value: boolean) => void;
  stopStream: () => void;
  startStream: () => void;
  link: string;
}

const ZegoButtons: FC<ZegoButtonsProps> = ({ setMicro, startStream, stopStream, link }) => {
  const navigate = useNavigate()
  const [isMicOn, setIsMicOn] = useState(true)
  const [isStream, setIsStream] = useState(true)
  const [open, setOpen] = useState<boolean>(false);

  const toggleMic = () => {
    setMicro(isMicOn);
    setIsMicOn(!isMicOn)
  }

  const stopStreamZego = () => {
    stopStream()
    navigate(Path.BROADCASTS)
    // if (isStream) {
    //   stopStream()
    //   setIsStream(false)
    // } else {
    //   startStream()
    //   setIsStream(true)
    // }
  }



  const handleCopy = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error copy: ", error);
      });
  };

  return <Box sx={{
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: { xs: 'flex-end', sm: 'center' },
    alignItems: 'center',
    width: '100%',
    height: { xs: 'auto', sm: '114px' },
    background: { xs: 'inherit', sm: 'rgba(110, 113, 132, 0.1)' },
    gap: { xs: 1, sm: 2 },
    p: { xs: '0 12px 20px 0', sm: 0 }
  }}>
    {/* <IconButton sx={{ width: '48px', height: '48px', background: 'rgba(110, 113, 132, 0.1)', color: '#fff' }} onClick={handleCopy}>
      <ReplyAllIcon />
    </IconButton> */}
    <IconButton sx={{ width: '48px', height: '48px', background: 'rgba(110, 113, 132, 0.1)', color: { xs: '#6E7184', sm: '#fff' } }} onClick={toggleMic}>
      {isMicOn ? <KeyboardVoiceIcon /> : <MicOffIcon />}
    </IconButton>
    <IconButton sx={{ width: '48px', height: '48px', background: 'rgba(110, 113, 132, 0.1)', color: '#6E7184', display: { xs: 'flex', sm: 'none' } }} onClick={stopStreamZego}>
      <PowerSettingsNewIcon />
    </IconButton>
    <ButttonCustom sx={{ width: '229px', background: "#F04444", display: { xs: 'none', sm: 'flex' } }} variant="contained" onClick={stopStreamZego} title={isStream ? 'End broadcast' : 'Start broadcast'} />
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={() => setOpen(false)}
    >
      <Alert onClose={() => setOpen(false)} severity="success">
        Link copied!
      </Alert>
    </Snackbar>
  </Box>
};

export default ZegoButtons;