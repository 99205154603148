import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFollowerFollowing } from 'typings/index';

import SubscriptionItem from './SubscriptionItem';

interface ISubscriptionProps {
  followingList: IFollowerFollowing[] | null;
  activeSubscriptionId: string | null;
  onSubscriptionClick: (id: string) => void;
}

const Subscription: FC<ISubscriptionProps> = ({
  followingList,
  activeSubscriptionId,
  onSubscriptionClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box py={1} pr={2}>
      <Typography
        variant='h5'
        sx={{ color: '#fff' }}
        py={1}
        pl={2}
        mb={1}
      >
        {t('information.subscriptions')}:
      </Typography>

      {followingList?.map((item) => (
        <SubscriptionItem
          key={item._id}
          item={item}
          isActive={activeSubscriptionId === item.toUserId._id}
          onClick={onSubscriptionClick}
        />
      ))}
    </Box>
  );
};

export default Subscription;
