import OverlayLoader from 'components/ui/OverlayLoader';
import ProfileContent from 'components/ui/ProfileContent';
import { ContentType } from 'constants/index';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFollowingListStart, getProfileStart } from 'store/user';
import { getUserVideoStart } from 'store/video';
import { getUserSession } from 'utils/utils';

const ProfilePage: FC = () => {
  const dispatch = useDispatch();

  const { userId: toUserId } = useParams<{ userId: string }>();
  const user = getUserSession();
  const userId = user._id;

  // Проверяем, зашел ли пользователь в свой профиль
  const isOwner = useMemo(() => user && toUserId === userId, [user, toUserId]);

  useEffect(() => {
    if (toUserId) {
      // Загружаем видео для пользователя из URL (чей профиль просматривается)
      dispatch(getUserVideoStart({ userId, toUserId }));
    }
  }, [userId, toUserId, dispatch]);

  useEffect(() => {
    if (toUserId && userId) {
      dispatch(getProfileStart({ userId: toUserId }));

      dispatch(
        getFollowingListStart({
          userId: toUserId,
          type: ContentType.FOLLOWING_LIST,
          isOtherUser: userId !== toUserId,
        })
      );

      dispatch(
        getFollowingListStart({
          userId: toUserId,
          type: ContentType.FOLLOWER_LIST,
          isOtherUser: userId !== toUserId,
        })
      );
    }
  }, [userId, toUserId, dispatch]);

  if (!user) {
    return <OverlayLoader />;
  }

  return <ProfileContent isOwner={isOwner} />;
};

export default ProfilePage;
