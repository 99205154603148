export const SECRET_KEY = 'abcdSDgcfdewcoweochGSDCSJKLnwdcwecveqQ';

export enum AuthModalType {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  FORGOT_PASSWORD = 'forgot-password',
  ERROR = 'error',
}

export enum VideoPopoverType {
  COMMENTS = 'comments',
  SHARING = 'sharing',
  DOTS = 'dots',
}

export enum ContentType {
  POST = 'post',
  VIDEO = 'video',
  FOLLOWER_LIST = 'followerList',
  FOLLOWING_LIST = 'followingList',
  USER = 'user',
}

export enum Path {
  ROOT = '/',
  USER_ID_SHORTS = '/:userId/shorts/:videoId',
  SHORTS = '/shorts',
  BROADCASTS = '/broadcasts',
  RECOMMENDEDVIDEOS = '/recommended-videos/:videoId',
  MYVIDEOS = '/:userId/my-videos/:videoId',
  RECOMMENDEDVIDEOSUSERS = '/:userId/recommended-videos/:videoId',
  LOGIN = '/login',
  HELP = '/help',
  REGISTRATION = '/registration',
  FORGOT_PASSWORD = '/forgot-password',
  PRIVACY_POLICY = '/privacy-policy',
  MUSIC = '/music',
  PROFILE = '/:userId/profile',
  UPLOAD = '/upload',
  FOLLOWER_LIST = '/:userId/followerList',
  FOLLOWING_LIST = '/:userId/followingList',
  CHANGE_PROFILE = '/change-profile',
  ROOM = '/room/:streamId',
  ROOMHOST = '/room/host/:streamId',
  POPULARCHANNELS = '/popular-channels',
}

export const countryOptions = [
  { labelKey: 'auth.form.country.russia', value: 'auth.form.country.russia' },
  { labelKey: 'auth.form.country.usa', value: 'auth.form.country.usa' },
  { labelKey: 'auth.form.country.england', value: 'auth.form.country.england' },
];

export const genderOptions = [
  { labelKey: 'auth.form.gender.man', value: 'auth.form.gender.man' },
  { labelKey: 'auth.form.gender.woman', value: 'auth.form.gender.woman' },
  { labelKey: 'auth.form.gender.other', value: 'auth.form.gender.other' },
];
