import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LikeState {
  likeStatusByVideoId: { [videoId: string]: boolean | undefined };
  error: string | null;
}

const initialState: LikeState = {
  likeStatusByVideoId: {},
  error: null,
};

const likeSlice = createSlice({
  name: 'video/like',
  initialState,
  reducers: {
    likeVideoStart: (
      state,
      action: PayloadAction<{
        videoId: string;
        userId: string;
      }>
    ) => {
      state.error = null;
    },
    likeVideoSuccess: (
      state,
      action: PayloadAction<{ videoId: string; isLiked: boolean }>
    ) => {
      const { videoId, isLiked } = action.payload;
      state.likeStatusByVideoId[videoId] = isLiked;
      state.error = null;
    },
    likeVideoFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { likeVideoStart, likeVideoSuccess, likeVideoFailure } =
  likeSlice.actions;

export default likeSlice.reducer;
