import { createSvgIcon } from "@mui/material";

// FIXME: Need extract only document icon, without background
const SuccessUploadIcon = createSvgIcon(
    <svg
        width={74}
        height={73}
        viewBox="0 0 74 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x={2}
            y={1.5}
            width={70}
            height={70}
            rx={14.5}
            stroke="#79FFE4"
            strokeWidth={3}
        />
        <path
            d="M33.3254 45.4999L24.7754 36.9499L26.9129 34.8124L33.3254 41.2249L47.0879 27.4624L49.2254 29.5999L33.3254 45.4999Z"
            fill="url(#paint0_linear_16901_10622)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_16901_10622"
                x1={49.2277}
                y1={45.5008}
                x2={31.9931}
                y2={22.1379}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#395AFC" />
                <stop offset={1} stopColor="#CBE4FF" />
            </linearGradient>
        </defs>
    </svg>,
    "SuccessUpload",
);

export default SuccessUploadIcon;
