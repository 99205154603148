import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import NoVideoData from 'components/ui/NoVideoData';
import VideoItem from 'components/ui/VideoItem';
import { useAppSelector } from 'hooks/useRedux';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Video } from 'typings/index';

interface IContentProps {
  isOwner: boolean;
}

const Content: FC<IContentProps> = ({ isOwner }) => {
  const { t } = useTranslation();

  const { userVideos } = useAppSelector((state) => state.video.getUserVideo);

  if (!userVideos.length && !isOwner) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        sx={{ height: '100%' }}
      >
        <Typography>{t('information.noVideo')}</Typography>
      </Box>
    );
  }

  if (!userVideos.length && isOwner) {
    return <NoVideoData />;
  }

  return (
    <Box sx={{ p: { xs: 2, sm: 0 } }}>
      <Grid container spacing={3} mt={4.5} columns={12}>
        {userVideos.map((video: Video) => (
          <Grid
            size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
            key={video._id}
            display='flex'
          >
            <VideoItem video={video} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Content;
