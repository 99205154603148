import LiveStream from 'components/ui/LiveStream';
import LiveStreamUser from 'components/ui/LiveStream/LiveStreamUser';
import ZegoHost from 'components/ui/LiveStream/ZegoHost';
import ZegoHostSDK from 'components/ui/LiveStream/ZegoHostSDK';
import Zego from 'components/ui/LiveStream/ZegoStream';
import { Path } from 'constants/index';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setRoomId } from 'store/stream';
import { getUserSession } from 'utils/utils';

const RoomPageHost: FC = () => {
  const navigate = useNavigate();
  const user = getUserSession();
  const dispatch = useDispatch();
  const { roomId } = useParams<{ roomId: string }>();

  useEffect(() => {
    if (roomId) {
      dispatch(setRoomId(roomId));
    }
  }, [roomId]);

  useEffect(() => {
    if (!user) {
      navigate(Path.ROOT);
    }
  }, [user, navigate]);

  return <LiveStream><ZegoHostSDK /></LiveStream>;
};

export default RoomPageHost;
