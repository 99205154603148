import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { formatNumber } from 'utils/utils';

interface ICountTypographyProps {
  count: number;
}

const CountTypography: FC<ICountTypographyProps> = ({ count = 0 }) => {
  const theme = useTheme();

  return (
    <Typography
      variant='h6'
      sx={{
        color: theme.palette.custom.primary.white,
        fontFamily: 'Dm sans'
      }}
      mt={0.5}
    >
      {formatNumber(count)}
    </Typography>
  );
};

export default CountTypography;
