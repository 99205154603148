import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentType } from 'constants/index';
import { IFollowerFollowing } from 'typings/index';

interface GetFollowerListState {
  followingList: IFollowerFollowing[];
  followerList: IFollowerFollowing[];
  otherUserFollowingList: IFollowerFollowing[];
  otherUserFollowerList: IFollowerFollowing[];
  isLoading: boolean;
  error: string | null;
}

const initialState: GetFollowerListState = {
  followingList: [],
  followerList: [],
  otherUserFollowingList: [],
  otherUserFollowerList: [],
  isLoading: false,
  error: null,
};

const getFollowerListSlice = createSlice({
  name: 'get/follower-following-list',
  initialState,
  reducers: {
    getFollowingListStart: (
      state,
      action: PayloadAction<{
        userId: string;
        type: ContentType;
        isOtherUser: boolean;
      }>
    ) => {
      state.error = null;
      state.isLoading = true;
    },
    getFollowingListSuccess: (
      state,
      action: PayloadAction<{
        result: IFollowerFollowing[];
        type: ContentType;
        isOtherUser: boolean;
      }>
    ) => {
      const { isOtherUser, type, result } = action.payload;

      if (type === ContentType.FOLLOWING_LIST) {
        if (isOtherUser) {
          state.otherUserFollowingList = result;
        } else {
          state.followingList = result;
        }
      } else if (type === ContentType.FOLLOWER_LIST) {
        if (isOtherUser) {
          state.otherUserFollowerList = result;
        } else {
          state.followerList = result;
        }
      }
      state.error = null;
      state.isLoading = false;
    },
    getFollowingListFailure: (
      state,
      action: PayloadAction<{ error: string; type: ContentType }>
    ) => {
      state.error = action.payload.error;
      state.isLoading = false;
      if (action.payload.type === ContentType.FOLLOWING_LIST) {
        state.followingList = [];
      } else if (action.payload.type === ContentType.FOLLOWER_LIST) {
        state.followerList = [];
      }
    },
  },
});

export const {
  getFollowingListStart,
  getFollowingListSuccess,
  getFollowingListFailure,
} = getFollowerListSlice.actions;

export default getFollowerListSlice.reducer;
