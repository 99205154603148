import App from 'components/App';
import { TitleProvider } from 'context/TitleContext';
import 'i18n/i18n';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const element = document.getElementById('root') as HTMLElement;
const root = createRoot(element);

root.render(
  <HelmetProvider>
    <TitleProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </TitleProvider>
  </HelmetProvider>
);
