import { createSvgIcon } from "@mui/material";

// FIXME: Need extract only document icon, without background
const UploadIcon = createSvgIcon(
    <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.001 11.5L16.001 7.5L10.001 3.5V11.5ZM10.701 17H16.301C16.1844 17.4333 15.9844 17.7833 15.701 18.05C15.4177 18.3167 15.051 18.4833 14.601 18.55L3.70104 19.875C3.15104 19.9583 2.65521 19.8292 2.21354 19.4875C1.77188 19.1458 1.51771 18.7 1.45104 18.15L0.126042 7.225C0.059375 6.675 0.192708 6.18333 0.526042 5.75C0.859375 5.31667 1.30104 5.06667 1.85104 5L3.00104 4.85V6.85L2.10104 6.975L3.45104 17.9L10.701 17ZM7.00104 15C6.45104 15 5.98021 14.8042 5.58854 14.4125C5.19688 14.0208 5.00104 13.55 5.00104 13V2C5.00104 1.45 5.19688 0.979167 5.58854 0.5875C5.98021 0.195833 6.45104 0 7.00104 0H18.001C18.551 0 19.0219 0.195833 19.4135 0.5875C19.8052 0.979167 20.001 1.45 20.001 2V13C20.001 13.55 19.8052 14.0208 19.4135 14.4125C19.0219 14.8042 18.551 15 18.001 15H7.00104ZM7.00104 13H18.001V2H7.00104V13Z"
      fill="currentColor"
    />
  </svg>,
    "Upload",
);

export default UploadIcon;
