import { FC, useState } from 'react';
import Upload from './Upload';
import UploadingVideo from './UploadingVideo';

const UploadPage: FC = () => {
  const [file, setFile] = useState<File>();
  const [videoLength, setVideoLength] = useState(0);

  return (
    <>
      {!file ? <Upload setVideoFile={setFile} setVideoLength={setVideoLength} /> :
        <UploadingVideo file={file} setVideoFile={setFile} videoLength={videoLength} />}
    </>
  );
};

export default UploadPage;
