import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentType } from 'constants/index';

interface ReportStartPayload {
  reportReason: string;
  type: ContentType;
  userId: string;
  videoId?: string;
  postId?: string;
  toUserId?: string;
}

interface ReportState {
  likeStatusByVideoId: { [videoId: string]: boolean | undefined };
  error: string | null;
}

const initialState: ReportState = {
  likeStatusByVideoId: {},
  error: null,
};

const reportVideoSlice = createSlice({
  name: 'post/report',
  initialState,
  reducers: {
    reportStart: (state, action: PayloadAction<ReportStartPayload>) => {
      state.error = null;
    },
    reportSuccess: (
      state,
      action: PayloadAction<{ videoId: string; isLiked: boolean }>
    ) => {
      const { videoId, isLiked } = action.payload;
      state.likeStatusByVideoId[videoId] = isLiked;
      state.error = null;
    },
    reportFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { reportStart, reportSuccess, reportFailure } =
  reportVideoSlice.actions;

export default reportVideoSlice.reducer;
