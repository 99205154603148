import { FormControl } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import FormSelect, {
  FormSelectProps,
} from 'components/common/FormSelect/FormSelect';
import { FieldValues, useController } from 'react-hook-form';

interface StyledFormControlProps {
  hasError?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

const StyledFormControl = styled(
  ({ hasError, disabled, children, ...other }: StyledFormControlProps) => (
    <FormControl {...other}>{children}</FormControl>
  )
)<{ theme?: Theme; hasError?: boolean; disabled?: boolean }>(
  ({ theme, hasError = false, disabled = false }) => ({
    width: '100%',

    '& .MuiSelect-icon': {
      color: `${theme.palette.custom.primary.graphite}70`,
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: '24px',
      fontFamily: 'Albert Sans',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: disabled
        ? `${theme.palette.custom.primary.graphite}45`
        : theme.palette.custom.primary.graphite,

      '&:hover fieldset': {
        borderColor: !disabled
          ? `${theme.palette.custom.primary.graphite}45`
          : undefined,
        backgroundColor: !disabled
          ? hasError
            ? `${theme.palette.custom.primary.extraError}15`
            : `${theme.palette.custom.primary.graphite}10`
          : 'transparent',
      },

      '&.Mui-focused fieldset': {
        borderColor: `${theme.palette.custom.primary.graphite}`,
        backgroundColor: hasError
          ? `${theme.palette.custom.primary.extraError}10`
          : 'transparent',
      },

      '&.Mui-error fieldset': {
        borderColor: `${theme.palette.custom.primary.extraError}`,
        backgroundColor: `${theme.palette.custom.primary.extraError}10`,
      },

      '& legend': {
        width: '0px',
      },
    },

    '& .MuiInputLabel-root': {
      color: hasError
        ? theme.palette.custom.primary.extraError
        : `${theme.palette.custom.primary.graphite}45`,
      transition: 'color 0.2s ease', // Плавный переход цвета
    },

    // Лейбл в состоянии shrink
    '& .MuiInputLabel-shrink': {
      color: hasError
        ? theme.palette.custom.primary.extraError
        : theme.palette.custom.primary.graphite,
      backgroundColor: theme.palette.custom.primary.white,
      opacity: 1,
      padding: '0 6px',
    },

    '& .MuiInputLabel-shrink, &.Mui-focused .MuiInputLabel-root': {
      color: hasError
        ? theme.palette.custom.primary.extraError
        : theme.palette.custom.primary.graphite,
    },

    '&.Mui-disabled .MuiOutlinedInput-root': {
      borderColor: `${theme.palette.custom.primary.graphite}45`,
      backgroundColor: 'transparent',
      filter: 'brightness(0.5)',
    },
    '&.Mui-disabled .MuiInputLabel-root': {
      color: `${theme.palette.custom.primary.graphite}45`,
      filter: 'brightness(0.5)',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.custom.primary.extraError,
    },
  })
);

const StyledFormSelect = <T extends FieldValues>({
  control,
  name,
  disabled,
  ...props
}: FormSelectProps<T>) => {
  const {
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const hasError = !!error;

  return (
    <StyledFormControl hasError={hasError} disabled={disabled}>
      <FormSelect
        {...props}
        name={name}
        disabled={disabled}
        control={control}
      />
    </StyledFormControl>
  );
};

export default StyledFormSelect;
