import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton } from '@mui/material';
import React from 'react';
import theme from 'styles/theme';

const ModalForm: React.FC<{
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}> = ({ open, onClose, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{
        background: 'rgba(28, 27, 32, 0.97)',
      }}
      sx={{
        '.MuiDialog-paper': {
          width: { xs: '100%', sm: 'auto' },
          maxHeight: '100%',
          borderRadius: { xs: 0, sm: '24px' },
          m: 0,
          background: '#090909'
        }
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', sm: '452px' },
          height: { xs: '100vh', sm: 'auto' },
          margin: 'auto',
          padding: { xs: 2, sm: 4 },
          display: { xs: 'flex', sm: 'block' },
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: { xs: 8, sm: 22 },
            top: { xs: 20, sm: 30 },
            color: '#1D1B20',
          })}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Dialog>
  );
};

export default ModalForm;
