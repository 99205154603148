import { getFromAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getCommentsFailure,
  getCommentsStart,
  getCommentsSuccess,
} from 'store/video/slices/getCommentsSlice';

function* getCommentsSaga(action: ReturnType<typeof getCommentsStart>) {
  const endpoint = '/client/postOrvideoComment/getpostOrvideoComments';
  const { videoId } = action.payload;
  const options = { ...action.payload };

  try {
    const response: AxiosResponse = yield call(getFromAPI, options, endpoint);

    const result: any = response.data.postOrVideoComment ?? null;

    if (result) {
      yield put(getCommentsSuccess({ result, videoId }));
    } else {
      yield put(getCommentsFailure('Ошибка: некорректные данные'));
    }
  } catch (e) {
    yield put(getCommentsFailure('Ошибка при выполнении запроса'));
  }
}

export function* watchGetCommentsSaga(): SagaIterator {
  yield takeEvery(getCommentsStart.type, getCommentsSaga);
}
