import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomButton from 'components/ui/Auth/CustomButton';
import CountdownTimer from 'components/ui/CountdownTimer';
import StyledFormTextField from 'components/ui/StyledFormTextField';
import { FC } from 'react';
import { Trans } from 'react-i18next';

interface IEmailConfirmationProps {
  content: string;
  control: any;
  email: string;
  pathReplace: string;
}

const EmailConfirmation: FC<IEmailConfirmationProps> = ({
  content,
  control,
  email,
  pathReplace,
}) => {
  const theme = useTheme();

  return (
    <>
      <Typography variant='h5' mb={3}>
        <Trans
          i18nKey={content}
          components={{
            email: (
              <span style={{ color: theme.palette.custom.primary.blue }}>
                {email}
              </span>
            ),
          }}
        />
      </Typography>
      <Grid container spacing={2} sx={{ mb: '36px' }}>
        <Grid size={12}>
          <StyledFormTextField
            control={control}
            name='code'
            labelKey={`auth.form.code`}
          />
        </Grid>
      </Grid>

      <CustomButton i18nKey='form.button.send' />

      <CountdownTimer path={pathReplace} />
    </>
  );
};

export default EmailConfirmation;
