import { PayloadAction } from '@reduxjs/toolkit';
import { postToAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  googleLoginFailure,
  googleLoginStart,
  googleLoginSuccess,
  loginFailure,
  loginStart,
  loginSuccess,
  refreshTokenFailure,
  refreshTokenStart,
  refreshTokenSuccess,
  registrationFailure,
  registrationStart,
  registrationSuccess,
} from 'store/auth/slices/authSlice';
import { getAllVideoClear } from 'store/video';
import {
  PreparedLoginData,
  PreparedRegistrationData,
  User,
} from 'typings/index';
import { saveUserSession } from 'utils/utils';

interface GoogleLoginPayload {
  idToken: string;
  ref: string;
}

interface GoogleLoginResponse {
  user: User;
  accessToken: string;
  refreshToken: string;
}

//google auth
function* googleLoginSaga({
  payload,
}: PayloadAction<GoogleLoginPayload>): SagaIterator {
  const { idToken, ref } = payload;
  const endpoint = '/client/user/loginorsignup';
  const body = { idToken, ref };

  try {
    const response: AxiosResponse<GoogleLoginResponse> = yield call(
      postToAPI,
      null,
      body,
      endpoint,
      false
    );

    const result: GoogleLoginResponse = response.data ?? null;

    if (result && result.user) {
      const { accessToken, refreshToken, user } = result;

      saveUserSession(user, accessToken, refreshToken);
      yield put(googleLoginSuccess({ user }));

      yield put(getAllVideoClear());

      window.dispatchEvent(new Event('loginStatusChange'));
    } else {
      yield put(googleLoginFailure('No user data received from server'));
    }
  } catch (error) {
    yield put(googleLoginFailure('Login failed'));
  }
}

//login
function* loginSaga({
  payload,
}: PayloadAction<PreparedLoginData>): SagaIterator {
  const body = { ...payload };
  const endpoint = '/client/user/loginorsignup';

  try {
    const response: AxiosResponse = yield call(postToAPI, null, body, endpoint);

    const result = response.data;

    if (result?.user) {
      const { user } = result;

      yield put(loginSuccess(user));
    } else {
      yield put(loginFailure('Ошибка: некорректные данные'));
    }
  } catch (error: any) {
    yield put(loginFailure('Ошибка при выполнении запроса'));
  }
}

//registration
function* registrationSaga({
  payload,
}: PayloadAction<PreparedRegistrationData>) {
  const body = { ...payload };
  const endpoint = '/client/user/loginorsignup';

  try {
    const response: AxiosResponse = yield call(postToAPI, null, body, endpoint);

    const result = response.data;

    if (result?.user) {
      const { user } = result;

      yield put(registrationSuccess(user));
    } else {
      yield put(registrationFailure('Ошибка: некорректные данные'));
    }
  } catch (error) {
    yield put(registrationFailure('Ошибка при выполнении запроса'));
  }
}

// Сага для обновления токена
function* refreshTokenSaga() {
  const refreshToken = localStorage.getItem('refreshToken');
  const body = {
    refreshToken: refreshToken,
  };
  const endpoint = '/auth/refresh';

  // Проверяем наличие refreshToken
  if (!refreshToken) {
    yield put(refreshTokenFailure('Refresh token not found'));
    return;
  }

  try {
    const response: AxiosResponse<{ accessToken: string }> = yield call(
      postToAPI,
      null,
      body,
      endpoint
    );

    // Если ответ успешный, сохраняем новый токен
    const { accessToken } = response.data;

    // Сохраняем токен в sessionStorage
    localStorage.setItem('token', accessToken);

    // Диспатчим действие успеха с новым токеном
    yield put(refreshTokenSuccess({ accessToken: accessToken }));
  } catch (error) {
    console.error('Ошибка обновления токена', error);

    // Если произошла ошибка, диспатчим действие неудачи
    yield put(refreshTokenFailure('Ошибка при выполнении запроса'));
  }
}

export function* watchAuthSaga(): SagaIterator {
  yield takeEvery(googleLoginStart.type, googleLoginSaga);
  yield takeEvery(loginStart.type, loginSaga);
  yield takeEvery(registrationStart.type, registrationSaga);
  yield takeEvery(refreshTokenStart.type, refreshTokenSaga);
}
