import { getFromAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getFollowingListFailure,
  getFollowingListStart,
  getFollowingListSuccess,
} from 'store/user/slices/getFollowerListSlice';

function* getFollowingListSaga(
  action: ReturnType<typeof getFollowingListStart>
) {
  const endpoint = '/client/followerFollowing/followerFollowingList';
  const { userId, type, isOtherUser } = action.payload;
  const options = { userId, type };

  try {
    const response: AxiosResponse = yield call(getFromAPI, options, endpoint);

    const result: any = response.data.followerFollowing ?? null;

    if (result) {
      yield put(getFollowingListSuccess({ result, type, isOtherUser }));
    } else {
      yield put(
        getFollowingListFailure({ error: 'Ошибка: некорректные данные', type })
      );
    }
  } catch (e) {
    yield put(
      getFollowingListFailure({ error: 'Ошибка при выполнении запроса', type })
    );
  }
}

export function* watchGetFollowingListSaga(): SagaIterator {
  yield takeEvery(getFollowingListStart.type, getFollowingListSaga);
}
