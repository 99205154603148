import { Box, Typography, useTheme } from '@mui/material';
import { MAX_WIDTH } from 'components/ui/VideoPlayer/constants'; // MAX_WIDTH = 337
import { FC, useEffect, useRef, useState } from 'react';

interface IVideoDescriptionProps {
  description: string;
}

const VideoDescription: FC<IVideoDescriptionProps> = ({ description }) => {
  const theme = useTheme();
  const [showMore, setShowMore] = useState(false);
  const [displayedText, setDisplayedText] = useState(description);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const ellipsis = '... more';

  // Функция для измерения ширины текста
  const getTextWidth = (text: string, font: string): number => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = font;
      return context.measureText(text).width;
    }
    return 0;
  };

  useEffect(() => {
    if (containerRef.current && !showMore) {
      const containerWidth = containerRef.current.offsetWidth;
      const font = `${theme.typography.h6.fontSize} ${theme.typography.fontFamily}`;
      let truncatedText = description;

      // Вычисляем длину текста с "ellipsis"
      while (
        getTextWidth(truncatedText + ellipsis, font) > containerWidth &&
        truncatedText.length > 0
      ) {
        truncatedText = truncatedText.slice(0, -1); // Обрезаем один символ
      }

      // Добавляем многоточие только если строка обрезана
      setDisplayedText(
        truncatedText.length < description.length
          ? truncatedText + ellipsis
          : description
      );
    } else {
      setDisplayedText(description);
    }
  }, [
    description,
    showMore,
    theme.typography.h6.fontSize,
    theme.typography.fontFamily,
  ]);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  const moreTextColor = `${theme.palette.custom.primary.white}45`;

  return (
    <Box
      onClick={handleToggle}
      sx={{
        width: MAX_WIDTH,
        cursor: 'pointer',
        userSelect: 'none',
        overflow: 'hidden',
      }}
      ref={containerRef}
      mb={1.5}
    >
      <Typography
        variant='h6'
        component='span'
        style={{
          whiteSpace: showMore ? 'normal' : 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: theme.palette.custom.primary.white,
        }}
      >
        {showMore ? (
          <>
            {displayedText.split(' ').slice(0, -1).join(' ')}{' '}
            <span style={{ color: moreTextColor }}>
              {displayedText.split(' ').slice(-1).join(' ')}
            </span>{' '}
          </>
        ) : (
          description
        )}
      </Typography>
    </Box>
  );
};

export default VideoDescription;
