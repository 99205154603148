import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormRadioGroup, {
  FormRadioGroupProps,
} from 'components/common/FormRadioGroup/FormRadioGroup';
import { FieldValues } from 'react-hook-form';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // Стили для Label в обычном состоянии
  '& .MuiFormLabel-root': {
    color: theme.palette.custom.primary.graphite,
    marginBottom: '8px',
    transition: 'color 0.3s ease',

    '& .Mui-error': {
      color: theme.palette.custom.primary.graphite,
    },

    '&.Mui-focused': {
      color: theme.palette.custom.primary.graphite,
    },
  },

  // Стили для радио кнопок
  '& .MuiRadio-root': {
    color: theme.palette.custom.primary.blue,
  },

  // Стили для контейнера радио кнопок
  '& .MuiFormControlLabel-root': {
    borderRadius: '12px',
    padding: '0px 8px',
    color: theme.palette.custom.primary.blue,

    // Стили для радио кнопок при наведении
    '&:hover': {
      backgroundColor: `${theme.palette.custom.primary.blue}10`,
    },
  },

  // Стили для текста ошибки
  '& .MuiFormHelperText-root': {
    color: theme.palette.custom.primary.extraError,
  },
}));

const StyledRadioGroup = <T extends FieldValues>(
  props: FormRadioGroupProps<T>
) => (
  <StyledFormControl>
    <FormRadioGroup {...props} />
  </StyledFormControl>
);

export default StyledRadioGroup;
