import Grid from '@mui/material/Grid2';
import { FC } from 'react';

import { Typography, useTheme } from '@mui/material';
import OverlayLoader from 'components/ui/OverlayLoader';
import { useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { IFollowerFollowing } from 'typings/index';

import SubscribeCardItem from './SubscribeCardItem';

interface ISubscribeListContentProps {
  isOwner: boolean;
  isFollowerType: boolean;
}

const SubscribeListContent: FC<ISubscribeListContentProps> = ({
  isOwner,
  isFollowerType,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    followerList,
    followingList,
    isLoading,
    otherUserFollowerList,
    otherUserFollowingList,
  } = useAppSelector((state) => state.user.getFollowerList);

  const renderSubscribeList = () => {
    switch (true) {
      case isOwner && isFollowerType:
        return followerList;
      case isOwner && !isFollowerType:
        return followingList;
      case !isOwner && isFollowerType:
        return otherUserFollowerList;
      case !isOwner && !isFollowerType:
        return otherUserFollowingList;
      default:
        return;
    }
  };

  const displayedSubscribeList = renderSubscribeList();

  if (isLoading) {
    return <OverlayLoader />;
  }

  return (
    <>
      <Typography
        variant='h4'
        pt={4.5}
        px={4}
        mb={1}
        sx={{ color: `${theme.palette.custom.primary.graphite}` }}
      >
        {isFollowerType
          ? t('information.subscribers')
          : t('information.subscriptions')}
      </Typography>
      <Grid container spacing={3} mt={4.5} columns={12} px={4}>
        {displayedSubscribeList?.map((subscribe: IFollowerFollowing) => (
          <Grid
            size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
            key={subscribe._id}
            display='flex'
          >
            <SubscribeCardItem
              subscribe={subscribe}
              isFollowerType={isFollowerType}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SubscribeListContent;
