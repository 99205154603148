import { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getUserSession } from 'utils/utils';
import SuccessUploadIcon from 'assets/IconsComponents/SuccessUploadIcon';

interface DialogSuccessProps {
  open: boolean;
  onClose: () => void;
  name?: string;
}

const DialogSuccess: FC<DialogSuccessProps> = ({ open, onClose, name }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = getUserSession();
  useEffect(() => {
    open && setTimeout(() => {
      navigate(`/${user._id}/profile`)
    }, 2000)
  }, [open])
  return (
    <Dialog open={open} onClose={onClose} sx={{ '.MuiPaper-root': { borderRadius: '8px', background: 'rgba(11, 11, 11, 1)' } }}>
      <Box sx={{ p: 1, width: '260px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{color: 'rgba(28, 27, 32, 1)'}} />
          </IconButton>
        </Box>
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', mb: 4 }}>
          <Typography variant='h5' sx={{ color: '#fff' }}>
            {t('upload.dialogSuccess.title')}
          </Typography>
          <SuccessUploadIcon onClick={() => navigate(`/${user._id}/profile`)} sx={{ fontSize: '73px', mt: 3, mb: 3, cursor: 'pointer' }} />
          <Typography variant='h5' sx={{ color: '#fff', textAlign: 'center' }}>
            {name}
          </Typography>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default DialogSuccess;
