import { Avatar, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PopularChannelsIcon from 'assets/IconsComponents/PopularChannels';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FC, useEffect, useState } from 'react';
import bgChannel from 'assets/bgChannel.png'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatNumber } from 'utils/utils';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ButttonCustom from 'components/ui/Button/Index';

const mockData = [
  {
    id: 1,
    nameAccont: 'Name Account',
    userName: 'nickname',
    avatar: '',
    image: bgChannel,
    views: 99999999,
    subscribersCount: 9000000,
    isSubscribed: false
  },
  {
    id: 2,
    nameAccont: 'Name Account',
    userName: 'nickname',
    avatar: '',
    image: bgChannel,
    views: 99999999,
    subscribersCount: 9000000,
    isSubscribed: false
  },
  {
    id: 3,
    nameAccont: 'Name Account',
    userName: 'nickname',
    avatar: '',
    image: bgChannel,
    views: 99999999,
    subscribersCount: 9000000,
    isSubscribed: true
  },
  {
    id: 4,
    nameAccont: 'Name Account',
    userName: 'nickname',
    avatar: '',
    image: bgChannel,
    views: 99999999,
    subscribersCount: 9000000,
    isSubscribed: false
  },
  {
    id: 5,
    nameAccont: 'Name Account',
    userName: 'nickname',
    avatar: '',
    image: bgChannel,
    views: 99999999,
    subscribersCount: 9000000,
    isSubscribed: false
  },
  {
    id: 6,
    nameAccont: 'Name Account',
    userName: 'nickname',
    avatar: '',
    image: bgChannel,
    views: 99999999,
    subscribersCount: 9000000,
    isSubscribed: false
  },
  {
    id: 7,
    nameAccont: 'Name Account',
    userName: 'nickname',
    avatar: '',
    image: bgChannel,
    views: 99999999,
    subscribersCount: 9000000,
    isSubscribed: true
  },
  {
    id: 8,
    nameAccont: 'Name Account',
    userName: 'nickname',
    avatar: '',
    image: bgChannel,
    views: 99999999,
    subscribersCount: 9000000,
    isSubscribed: false
  }
]

const PopularChannelsPage: FC = () => {
  const [channels, setChannels] = useState(mockData)

  const handleSubscribe = (id: number) => {
    setChannels(channels.map((item) => {
      if (item.id === id) {
        return { ...item, isSubscribed: !item.isSubscribed }
      } else return item
    }))
  }

  return (
    <Box sx={{ width: { xs: '100%', sm: '90%', lg: '80%', xl: '75%' }, m: '0 auto', p: { xs: '24px 16px', sm: '40px 0px 20px' } }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }}>
        <PopularChannelsIcon sx={{ fontSize: '44px' }} />
        <Typography sx={{ color: '#1C1B20', fontSize: { xs: '22px', sm: '28px' } }}>Popular Channel</Typography>
      </Box>
      <Grid container spacing={{ xs: 1, sm: 2 }}>
        {channels.map((item) => (
          <Grid key={item.id} size={{ xs: 6, md: 4, lg: 3 }}>
            <Box sx={{ position: 'relative', height: { xs: '330px', sm: '430px' }, borderRadius: '12px' }}>
              <Box component='img' alt={item.nameAccont} src={item.image} width="100%" height='100%' sx={{ objectFit: 'cover', borderRadius: '12px' }} />
              <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar src={item.avatar} alt={item.userName} sx={{ width: { xs: '24px', sm: '48px' }, height: { xs: '24px', sm: '48px' }, mb: 1 }} />
                  <Typography sx={{ fontSize: { xs: '16', sm: '28px' }, color: '#fff' }}>{item.nameAccont}</Typography>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Typography sx={{ fontSize: { xs: '14', sm: '16px' }, color: '#fff' }}>{item.userName}</Typography>
                    <CheckCircleIcon sx={{ fontSize: { xs: '16', sm: '18px' }, color: '#395AFC' }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#fff' }}>
                      <VisibilityIcon />
                      <Typography>{formatNumber(item.views)}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#fff' }}>
                      <PersonAddAltOutlinedIcon />
                      <Typography>{formatNumber(item.subscribersCount)}</Typography>
                    </Box>
                  </Box>
                  {!item.isSubscribed ?
                    <ButttonCustom
                      onClick={() => handleSubscribe(item.id)}
                      sx={{ width: { xs: '150px', sm: '190px' }, mt: 2, mb: { xs: 2, sm: 4 } }} title='Subscribe'
                    /> :
                    <ButttonCustom
                      onClick={() => handleSubscribe(item.id)}
                      sx={{ width: { xs: '165px', sm: '190px' }, mt: 2, mb: { xs: 2, sm: 4 }, background: '#EFF2F5', color: '#1C1B20' }}
                      title="You're subscribed"
                    />}
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PopularChannelsPage;