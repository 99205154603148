import { isNil } from 'lodash';
import { Primitive, User } from 'typings/index';

export const params2form = (
  payload: Record<string, Primitive | Primitive[]>
): string =>
  Object.keys(payload)
    .filter((key) => !isNil(payload[key]))
    .map((key) => {
      if (Array.isArray(payload[key])) {
        return (payload[key] as Primitive[])
          .filter((item) => !isNil(item))
          .map(
            (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
          )
          .join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(
        payload[key] as Primitive
      )}`;
    })
    .join('&');

export const formatNumber = (num: number): string => {
  if (num < 1000) {
    return num.toString(); // Для чисел меньше 1000 — просто возвращаем их
  } else if (num >= 1000 && num < 1000000) {
    return (num / 1000).toFixed(1).replace('.0', '') + 'K'; // Для чисел больше 1000 — возвращаем в формате "1.3K"
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1).replace('.0', '') + 'M'; // Для чисел больше 1 000 000 — возвращаем в формате "1.3M"
  } else {
    return (num / 1000000000).toFixed(1).replace('.0', '') + 'B'; // Для миллиардов — в формате "1.3B"
  }
};

// Функция для сохранения данных в sessionStorage
export const saveUserSession = (
  user: User,
  accessToken: string,
  refreshToken: string
) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

// Функция для очистки sessionStorage
export const clearSessionStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

// Функция для получения юзера из sessionStorage
export const getUserSession = () => {
  const user = localStorage.getItem('user');

  // Проверяем, существует ли пользователь
  if (user) {
    return JSON.parse(user); // Парсим JSON, если пользователь найден
  }

  return null; // Если пользователь не найден, возвращаем null
};

export const getIdsFromPathname = (pathname: string) => {
  const match = pathname.match(/^\/([^/]+)\/recommended-videos\/([^/]+)/);

  if (match) {
    const [, userId, videoId] = match;
    return { userId, videoId };
  }

  return { userId: null, videoId: null };
};

export const getIdsMyVideosFromPathname = (pathname: string) => {
  const match = pathname.match(/^\/([^/]+)\/my-videos\/([^/]+)/);

  if (match) {
    const [, userId, videoId] = match;
    return { userId, videoId };
  }

  return { userId: null, videoId: null };
};

export const getIdsShortsFromPathname = (pathname: string) => {
  const match = pathname.match(/^\/([^/]+)\/shorts\/([^/]+)/);

  if (match) {
    const [, userId, videoId] = match;
    return { userId, videoId };
  }

  return { userId: null, videoId: null };
};

export const randomID = (len: number) => {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
};

export const replaceUrl = (url: string) => {
  if (url.includes('admin.staging.iearn.me')) {
    return url.replace('admin.staging.iearn.me', 'api.staging.wne.life')
  }
  return url
}
