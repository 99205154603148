import { yupResolver } from '@hookform/resolvers/yup';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import EmailConfirmation from 'components/ui/EmailConfirmation';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCodeValidationSchema } from 'validation/validationSchema';

interface RegistrationStep3Data {
  code: string;
}

interface IRegistrationStep3Props {
  formData: any;
}

const RegistrationStep3: FC<IRegistrationStep3Props> = ({ formData }) => {
  const { t } = useTranslation();

  const codeValidationSchema = useCodeValidationSchema();

  const defaultValues = {
    code: '',
  };

  const { control, handleSubmit } = useForm<RegistrationStep3Data>({
    defaultValues,
    resolver: yupResolver(codeValidationSchema),
  });

  const onSubmit = (data: RegistrationStep3Data) => {
    console.log('DATA', data);
  };

  return (
    <>
      <ModalTitle title={t('auth.title.emailConfirmation')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailConfirmation
          content={'auth.form.emailConfirmation'}
          control={control}
          email={formData.email}
          pathReplace='/code-repeat'
        />
      </form>
    </>
  );
};

export default RegistrationStep3;
