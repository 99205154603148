import { postToAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  likeVideoFailure,
  likeVideoStart,
  likeVideoSuccess,
} from '../slices/likeVideoSlice';

function* likeVideoSaga(
  action: ReturnType<typeof likeVideoStart>
): SagaIterator {
  const endpoint = '/client/video/likeOrDislikeOfVideo';
  const { userId, videoId } = action.payload;

  const options = { userId, videoId };

  try {
    const response: AxiosResponse = yield call(
      postToAPI,
      options,
      {},
      endpoint
    );

    const result = response.data;

    if (result) {
      yield put(likeVideoSuccess({ videoId, isLiked: result.isLike }));
    } else {
      yield put(likeVideoFailure('Ошибка: Некорректный ответ от сервера'));
    }
  } catch (e) {
    yield put(likeVideoFailure('Ошибка при выполнении запроса на сервер'));
  }
}

export function* watchLikeVideoSaga(): SagaIterator {
  yield takeEvery(likeVideoStart.type, likeVideoSaga);
}
