import OverlayLoader from 'components/ui/OverlayLoader';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useState } from 'react';

import RegistrationStep1 from './Steps/RegistrationStep1';
import RegistrationStep2 from './Steps/RegistrationStep2';
import RegistrationStep3 from './Steps/RegistrationStep3';

interface IRegistrationFormProps {
  handleClose: () => void;
}

const RegistrationForm: FC<IRegistrationFormProps> = ({ handleClose }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const { isLoading } = useAppSelector((state) => state.auth);

  const handleNextStep = (data: any) => {
    setFormData((prev) => ({ ...prev, ...data }));
    setStep((prev) => prev + 1);
  };

  if (isLoading) {
    return <OverlayLoader />;
  }

  return (
    <>
      {step === 1 && (
        <RegistrationStep1 handleClose={handleClose} onNext={handleNextStep} />
      )}
      {step === 2 && <RegistrationStep2 onNext={handleNextStep} />}
      {step === 3 && <RegistrationStep3 formData={formData} />}
    </>
  );
};

export default RegistrationForm;
