import { Box, Button, Dialog, Link, Typography } from '@mui/material';
import LogoIcon from 'assets/IconsComponents/Logo';
import { FC } from 'react';

interface DialogDownloadAppProps {
  open: boolean;
  onClose: () => void;
}

const DialogDownloadApp: FC<DialogDownloadAppProps> = ({ open, onClose }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        display: { xs: 'block', sm: 'none' },
        '.MuiPaper-root': {
          m: 0,
          width: '100%',
          borderRadius: '24px 24px 0 0',
          p: 2
        },
        '.MuiDialog-container': {
          alignItems: 'flex-end'
        },
        '.MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }

      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
        <Box sx={{ height: '4px', width: '32px', background: 'rgba(110, 113, 132, 0.45)' }} />
        <Typography sx={{ color: '#395AFC', fontSize: '22px', lineHeight: '22px' }}>Try our app</Typography>
        <LogoIcon sx={{ width: '60px', height: '60px' }} />
        <Button
          sx={{ textTransform: 'none', zIndex: 9998, color: '#fff', background: '#395AFC', borderRadius: '100px', p: '10px 16px', display: 'flex', alignItems: 'center' }}
          component={Link}
          href="https://artifacts.staging.iearn.me/iearn_latestSigned.apk"
          target="_blank"
          fullWidth
          onClick={onClose}
        >
          Download app
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogDownloadApp;
