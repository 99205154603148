import { Typography } from '@mui/material';

const Footer = () => {
  const startYear = 2024;
  const currentYear = new Date().getFullYear();

  const displayYear =
    currentYear === startYear ? (
      <span>{currentYear}</span>
    ) : (
      <span>{`${startYear} - ${currentYear}`}</span>
    );

  return (
    <footer>
      <Typography variant='h6' sx={{color: 'rgba(110, 113, 132, 1)', fontFamily: 'Dm sans'}}>&copy; {displayYear} IEarn</Typography>
    </footer>
  );
};

export default Footer;
