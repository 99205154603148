import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CustomButton from 'components/ui/Auth/CustomButton';
import OverlayLoader from 'components/ui/OverlayLoader';
import StyledRadioGroup from 'components/ui/StyledRadioGroup';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reportStart } from 'store/report';

import { ContentType } from 'constants/index';
import { getUserSession } from 'utils/utils';
import { transformReportReasons } from './utils';

interface IReportPanelProps {
  handleActionClose: () => void;
  videoId: string;
}

const ReportPanel: FC<IReportPanelProps> = ({ handleActionClose, videoId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = getUserSession();

  const { isLoading: isReportReasonLoading, reportReason } = useAppSelector(
    (state) => state.report.getReportReason
  );

  const [selectedReason, setSelectedReason] = useState<string | null>(null);

  const handleReport = () => {
    if (selectedReason) {
      dispatch(
        reportStart({
          reportReason: selectedReason,
          type: ContentType.VIDEO,
          videoId,
          userId: user._id,
        })
      );
      handleActionClose();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason(event.target.value);
  };

  if (isReportReasonLoading) {
    return <OverlayLoader />;
  }

  return (
    <>
      <Typography
        variant='h3'
        sx={{ mb: 3, color: '#fff' }}
      >
        {t('video.popover.report')}
      </Typography>

      <Stack>
        <StyledRadioGroup
          value={selectedReason}
          onChange={handleChange}
          aria-label='options'
          name='radio-buttons-group'
        >
          <RadioGroup value={selectedReason} onChange={handleChange}>
            {transformReportReasons(reportReason).map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.value}
                control={<Radio sx={{color: 'rgba(121, 255, 228, 1) !important'}} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </StyledRadioGroup>

        <CustomButton
          type='button'
          onClick={handleReport}
          i18nKey='button.report'
          sx={{
            width: 'auto',
            marginBottom: 0,
            padding: '12px 40px',
            alignSelf: 'start',
            color: '#000 !important'
          }}
          disabled={!selectedReason}
        />
      </Stack>
    </>
  );
};

export default ReportPanel;
