import { createTheme } from '@mui/material/styles';
// Определение кастомных цветов и их типов
interface CustomPalette {
  primary: {
    black: string;
    blue: string;
    graphite: string;
    gray: string;
    green: string;
    lightblue: string;
    red: string;
    white: string;
    extraError: string;
  };
}

declare module '@mui/material/styles' {
  interface Palette {
    custom: CustomPalette;
  }

  interface PaletteOptions {
    custom?: Partial<CustomPalette>;
  }
}

const theme = createTheme({
  palette: {
    custom: {
      primary: {
        black: '#1C1B20',
        blue: '#395AFC',
        graphite: '#6E7184',
        gray: '#EFF2F5',
        green: '#395AFC',
        lightblue: '#CBE4FF',
        red: '#CBE4FF',
        white: '#FFFFFF',
        extraError: '#F04444',
      },
    },
  },

  typography: {
    fontFamily: 'Dela Gothic One',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h6: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    h5: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    h4: {
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '36px',
    },
    h3: {
      fontSize: '22px',
      fontWeight: 400,
      lineHeight: '28px',
    },
    h2: {
      fontSize: '36px',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Albert Sans',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
  },
});

export default theme;
