import { Avatar, Box, Stack, Typography, useTheme } from '@mui/material';
import {
  IconChat,
  IconFavorite,
  IconPlay,
  IconVideoUpload,
} from 'assets/index';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Video } from 'typings/index';
import { formatNumber } from 'utils/utils';

interface IVideoItemProps {
  video: Video;
}

const VideoItem: FC<IVideoItemProps> = ({ video }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleVideoClick = () => {
    navigate(`/${video.userId}/my-videos/${video._id}`);
  };
  return (
    <Stack sx={{ width: '100%' }}>
      <Box
        sx={{
          width: '100%',
          height: '250px',
          borderRadius: '24px',
          background: `${theme.palette.custom.primary.graphite}15`,
          overflow: 'hidden',
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 1,
          cursor: 'pointer',
        }}
        onClick={handleVideoClick}
      >
        <Box component='img' src={video.videoImage} />
        {video.videoImage ? (
          <Box
            sx={{
              width: '100%',
              backgroundImage: `${video.videoImage}`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '8px',
            }}
          />
        ) : (
          <IconVideoUpload />
        )}
      </Box>

      {/* Описание, хэштег и пользователь */}
      <Typography
        variant='h6'
        mb={0.5}
        sx={{ color: '#fff' }}
      >
        {video.caption}
      </Typography>

      {!!video.hashTag.length && (
        <Typography
          variant='body2'
          mb={1}
          sx={{ color: 'rgba(121, 255, 228, 1)' }}
        >
          {video.hashTag.map((hashTag) => hashTag)}
        </Typography>
      )}

      <Box display='flex' alignItems='center' mt={1}>
        <Avatar
          src={video.userImage}
          alt='User'
          sx={{ width: 24, height: 24, mr: 1 }}
        />
        <Typography
          variant='h6'
          sx={{ color: theme.palette.custom.primary.graphite, fontFamily: 'Dm sans' }}
        >
          {video.userName}
        </Typography>
      </Box>

      {/* Лайки, комментарии и просмотры */}
      <Box display='flex' mt={1}>
        <CustomTypography icon={IconPlay} count={video.totalViews} />
        <CustomTypography icon={IconFavorite} count={video.totalLikes} />
        <CustomTypography icon={IconChat} count={video.totalComments} />
      </Box>

      {/* Дата загрузки */}
      <Typography
        variant='caption'
        sx={{color: '#fff'}}
        mt={1}
        display='block'
      >
        {new Date(video.createdAt).toLocaleDateString('ru-RU', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </Typography>
    </Stack>
  );
};

export default VideoItem;

interface ICustomTypographyProps {
  icon: React.ComponentType;
  count?: number;
}

const CustomTypography: FC<ICustomTypographyProps> = ({
  icon: Icon,
  count = 0,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        color: theme.palette.custom.primary.graphite,
      }}
    >
      <Icon />
      <Typography variant='h6' mr={2} ml={0.5} sx={{fontFamily: 'Dm sans'}}>
        {formatNumber(count)}
      </Typography>
    </Stack>
  );
};
