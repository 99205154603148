import DefaultSidebarContent from 'components/ui/DefaultSidebarContent';
import PrivacyPolicySidebarContent from 'components/ui/PrivacyPolicySidebarContent';
import ProtectedSidebarContent from 'components/ui/ProtectedSidebarContent';
import { Path } from 'constants/index';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserSession } from 'utils/utils';

interface SidebarContentProviderProps {
  closeMenu?: () => void;
  isShowSidebar?: boolean;
}

const SidebarContentProvider: FC<SidebarContentProviderProps> = ({ closeMenu, isShowSidebar }) => {
  const location = useLocation();

  const isLoggedIn = getUserSession();

  if (location.pathname === Path.PRIVACY_POLICY) {
    return <PrivacyPolicySidebarContent />;
  }

  if (!isLoggedIn) {
    return <DefaultSidebarContent isShowSidebar={isShowSidebar} />;
  }

  return <ProtectedSidebarContent isShowSidebar={isShowSidebar} closeMenu={closeMenu} />;
};

export default SidebarContentProvider;
