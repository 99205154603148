import { getFromAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getUserVideoFailure,
  getUserVideoStart,
  getUserVideoSuccess,
} from 'store/video/slices/getUserVideoSlice';

function* getUserVideoSaga(action: ReturnType<typeof getUserVideoStart>) {
  const endpoint = '/client/video/videosOfUser';
  const { userId, toUserId } = action.payload;
  const options = { userId, toUserId };

  try {
    const response: AxiosResponse = yield call(getFromAPI, options, endpoint);

    const result: any = response.data.data ?? null;

    if (result) {
      yield put(getUserVideoSuccess({ result }));
    } else {
      yield put(getUserVideoFailure('Ошибка: некорректные данные'));
    }
  } catch (e) {
    yield put(getUserVideoFailure('Ошибка при выполнении запроса'));
  }
}

export function* watchGetUserVideoSaga(): SagaIterator {
  yield takeEvery(getUserVideoStart.type, getUserVideoSaga);
}
