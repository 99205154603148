import { Avatar, Button, Stack, Typography, useTheme } from '@mui/material';
import { IconArtist } from 'assets';
import VideoDescription from 'components/ui/VideoPlayer/VideoDescription';
import cfg from 'config/index';
import { Path } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { followStart } from 'store/user';
import { Video } from 'typings/index';
import { getUserSession } from 'utils/utils';

interface IAboutVideoProps {
  isLoggedIn: boolean;
  video: Video;
}

const AboutVideo: FC<IAboutVideoProps> = ({ isLoggedIn, video }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getUserSession();
  const { userId } = useParams<{ userId: string }>();

  const { error: storeSubscribeError } = useAppSelector(
    (state) => state.user.follow
  );
  const [isFollowed, setIsFollowed] = useState<boolean>(video.isFollow);
  const [isPrevFollowed, setIsPrevFollowed] = useState<boolean>(video.isFollow);

  const isOwner = useMemo(() => userId === video.userId, [userId]);

  const userImage = `${cfg.API_URL}${video?.videoUrl}`;
  const songImage = `${cfg.API_URL}${video?.songImage}`;
  const singerName = video?.singerName
    ? video.singerName
    : t('additional_info.unknown_artist');
  const songTitle = video?.songTitle
    ? video.songTitle
    : t('additional_info.unknown_song');

  useEffect(() => {
    if (storeSubscribeError) {
      setIsFollowed(isPrevFollowed);
    }
  });

  const handleFollowClick = () => {
    if (!isLoggedIn) {
      navigate(Path.LOGIN);

      return;
    }

    const followStatus = !isFollowed;
    setIsFollowed(followStatus);
    setIsPrevFollowed(isFollowed);

    dispatch(
      followStart({
        fromUserId: user._id,
        toUserId: video.userId,
      })
    );
  };

  const targetPath = `/${video.userId}/profile`;

  const handleUserClick = () => {
    navigate(targetPath);
  };

  return (
    <Stack
      sx={{
        position: 'absolute',
        bottom: '24px',
        left: '24px',
        zIndex: 1,
      }}
    >
      <Stack direction='row' spacing={1} alignItems='center' mb={2}>
        <Stack
          direction='row'
          alignItems='center'
          sx={{ cursor: 'pointer' }}
          onClick={handleUserClick}
        >
          <Avatar
            src={userImage}
            alt='username'
            sx={{ width: 48, height: 48 }}
          />
          <Typography
            variant='h6'
            sx={{
              color: theme.palette.custom.primary.white,
              ml: 1,
            }}
          >
            {video.userName}
          </Typography>
        </Stack>
        {!isOwner && (
          <Button
            variant='outlined'
            sx={{
              border: `1px solid ${theme.palette.custom.primary.white}`,
              borderRadius: '100px',
              color: theme.palette.custom.primary.white,
              height: '40px',
              textTransform: 'none',
              marginLeft: '16px !important',
            }}
            onClick={handleFollowClick}
          >
            <Typography
              variant='h6'
              sx={{
                color: theme.palette.custom.primary.white,
              }}
            >
              {isFollowed ? t('button.unsubscribe') : t('button.subscribe')}
            </Typography>
          </Button>
        )}
      </Stack>

      {/* Описание видео */}
      <VideoDescription description={video.caption} />

      {/* Информация о музыке */}
      <Stack direction='row' alignItems='center'>
        {video.songImage ? (
          <Avatar
            src={songImage}
            alt='Song Image'
            sx={{ width: 24, height: 24 }}
          />
        ) : (
          <IconArtist />
        )}
        <Typography
          ml={1}
          variant='h6'
          sx={{ color: theme.palette.custom.primary.white }}
        >
          {singerName} - {songTitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AboutVideo;
