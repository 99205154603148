import { Box, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserSession } from 'utils/utils';
import { io, Socket } from 'socket.io-client';
import cfg from 'config/index';
import CommentsPanelStream from './CommentsPanelStream';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import CommentsPanelStreamHost from './CommentsPanelStreamHost';

const LiveStream: FC<PropsWithChildren> = ({ children }) => {
  const { streamId = '' } = useParams<{ streamId: string }>();
  const user = getUserSession();
  const socketInstance = useRef<Socket | null>(null);
  const [comments, setComments] = useState<any>([])
  const [views, setViews] = useState<any>(0)
  const [lessViews, setLessViews] = useState<any>(null)
  const [addViews, setAddViews] = useState<any>(0)
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    let interval: any;
    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (time: number) => {
    const hours = String(Math.floor(time / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (!socketInstance.current) {
      socketInstance.current = io(`${cfg.API_URL}/?globalRoom=globalRoom:${user._id}`).connect()

      socketInstance.current.on('connect', () => {
        console.log('Подключение к сокету установлено');
      });

      socketInstance.current.on('liveChat', (data) => {
        console.log('liveChatliveChat:', data);
        data && setComments((prevComments: any) => [...prevComments, JSON.parse(data)])
      });

      socketInstance.current.on('addView', (data) => {
        setAddViews(data)
      });

      socketInstance.current.on('lessView', (data) => {
        setLessViews(data)
      });
    }

    return () => {
      if (socketInstance.current) {
        socketInstance.current.emit('lessView', JSON.stringify({
          liveHistoryId: streamId?.split('_')[0] || ''
        }));
        socketInstance.current.emit('endLive', JSON.stringify({
          liveHistoryId: streamId?.split('_')[0] || '',
          userId: user._id
        }));
        socketInstance.current.disconnect();
        console.log('Отключение от сокета');
      }
    };
  }, [user._id]);

  const handleSendComment = (newComment: string) => {
    if (newComment.trim() !== '') {
      if (socketInstance.current) {
        socketInstance.current.emit('liveChat', JSON.stringify({
          liveHistoryId: streamId?.split('_')[0] || '',
          commentText: newComment,
          userId: user._id,
          userName: user.userName,
          userImage: user.image,
        }));
      }
    }
  };

  useEffect(() => {
    if (socketInstance.current) {
      socketInstance.current.emit('liveRoomConnect', JSON.stringify({
        liveHistoryId: streamId?.split('_')[0] || ''
      }));
    }
  }, [socketInstance.current])

  useEffect(() => {
    setViews(lessViews && lessViews < addViews ? lessViews : addViews)
  }, [lessViews, addViews])

  const closeComments = () => setShowChat(false)

  return <Grid container width="100%" height="100%" sx={{ background: { xs: '#fff', sm: 'inherit' } }}>
    <Grid size={{ xs: 12, sm: 8 }}>
      <Box width="100%" sx={{ position: 'relative', height: { xs: '100%', sm: '100%' } }}>
        {children}
        <Box sx={{ position: 'absolute', top: 20, left: 40, display: 'flex', alignItems: 'center', gap: 1, width: '85%', justifyContent: { xs: 'flex-end', sm: 'flex-start' } }}>
          <Box sx={{ width: '61px', height: '28px', background: '#F04444', p: '3px 10px' }}>
            <Typography sx={{ fontSize: '14px', color: '#fff' }}>On air</Typography>
          </Box>
          <Box sx={{ background: 'rgba(110, 113, 132, 0.1)', p: '3px 10px', display: { xs: 'none', sm: 'flex' } }}>
            <Typography sx={{ fontSize: '14px', color: '#fff' }}>{formatTime(time)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <VisibilityOutlinedIcon sx={{ color: '#6E7184' }} />
            <Typography sx={{ color: '#6E7184' }}>{views}</Typography>
          </Box>
        </Box>
        <IconButton
          sx={{
            width: '48px',
            height: '48px',
            background: 'rgba(110, 113, 132, 0.1)',
            color: '#6E7184',
            display: { xs: 'flex', sm: 'none' },
            position: 'absolute', bottom: 20, right: 125
          }}
          onClick={() => setShowChat(true)}>
          <ChatIcon />
        </IconButton>
      </Box>
    </Grid>
    <Grid size={{ xs: 12, sm: 4 }}
      sx={{ p: { xs: '0 16px', sm: 0 }, display: { xs: 'none', sm: 'block' } }}
    >
      <Box sx={{ background: { xs: '#EFF2F5', sm: '#fff' }, p: 3, height: { xs: 'auto', sm: '100%' }, borderRadius: {xs:'16px', sm: 0} }}>
        <CommentsPanelStreamHost handleSendComment={handleSendComment} comments={comments} />
      </Box>
    </Grid>
    <Box sx={{ display: { xs: showChat ? 'block' : 'none', sm: 'none' }, position: 'fixed', bottom: 0, width: '100%', height: 'calc(100vh - 106px)' }}>
      <CommentsPanelStreamHost closeComments={closeComments} handleSendComment={handleSendComment} comments={comments} />
    </Box>
  </Grid>
};

export default LiveStream;
