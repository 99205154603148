import { Box } from '@mui/material';
import { ZegoExpressEngine } from 'zego-express-engine-webrtc';
import { APP_ID, SERVER_SECRET } from 'components/ui/LiveStream/constants';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserSession, randomID } from 'utils/utils';
import generateToken from './GenerateToken';
import ZegoButtons from './ZegoButtons';

const ZegoHost: FC = () => {
  const { streamId = '' } = useParams<{ streamId: string }>();
  const user = getUserSession();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const zegoEngineRef = useRef<ZegoExpressEngine | null>(null);
  const roomId = streamId.split('_')[0]
  useEffect(() => {
    const initializeStream = async () => {
      if (!APP_ID || !SERVER_SECRET) {
        alert('Серверные данные недействительны. Проверьте настройки.');
        return;
      }

      if (!roomId || !roomId.trim()) {
        alert('Room ID отсутствует или некорректен.');
        return;
      }

      const userID = user?._id || 'test';
      const userName = user?.userName || `Host_${randomID(5)}`;

      console.log('Initializing Zego Engine with params:', {
        roomId,
        userID,
        userName,
      });

      const token = generateToken(userID, 86400);

      const zegoEngine = new ZegoExpressEngine(APP_ID, SERVER_SECRET);
      zegoEngineRef.current = zegoEngine;
      zegoEngine.setLogConfig({
        logLevel: 'error'
      })
      try {
        // Login to the room
        console.log('Logging into room...');
        await zegoEngine.loginRoom(roomId, token, {
          userID,
          userName,
        });

        console.log('Creating local stream...');
        const localStream = await zegoEngine.createStream({
          camera: {
            videoQuality: 2,
            facingMode: 'user',
          },
        });

        await zegoEngine.startPublishingStream(`${roomId}_${user?._id}_call`, localStream);
        const remoteStream = await zegoEngine.startPlayingStream(`${roomId}_${user?._id}_call`);

        const remoteView = zegoEngine.createRemoteStreamView(remoteStream);

        remoteView.play("streamHost", { enableAutoplayDialog: true });

      } catch (error: any) {
        if (error.name === 'NotFoundError') {
          console.error('Camera or microphone not found.');
          alert('Камера или микрофон не найдены. Подключите устройства и попробуйте снова.');
        } else {
          console.error('Ошибка при инициализации стрима:', error);
          alert('Не удалось создать стрим. Проверьте настройки.');
        }
      }
    };

    initializeStream();

    return () => {
      if (zegoEngineRef.current) {
        zegoEngineRef.current.logoutRoom(roomId);
        zegoEngineRef.current = null;
      }
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    };
  }, [roomId, user]);

  const setMicro = (value: boolean) => {
    if (zegoEngineRef.current) {
      zegoEngineRef.current.muteMicrophone(value);
    }
  }

  const stopStream = () => {
    if (zegoEngineRef.current) {
      zegoEngineRef.current.stopPlayingStream(`${roomId}_${user?._id}_call`);
    }
  };

  const startStream = () => {
    if (zegoEngineRef.current) {
      zegoEngineRef.current.startPlayingStream(`${roomId}_${user?._id}_call`);
    }
  };

  return <Box width="100%" height="100%" position="relative">
    <Box
      className="streamHost"
      id="streamHost"
      width="100%"
      sx={{ height: { xs: 'calc(100vh - 64px)', sm: 'calc(100vh - 82px)' } }}
      ref={zegoEngineRef}
    ></Box>
    <ZegoButtons setMicro={setMicro} stopStream={stopStream} startStream={startStream} link={`${process.env.REACT_APP_BASE_URL}/room/${roomId}_${user?._id}_call`} />
  </Box>;
};

export default ZegoHost;