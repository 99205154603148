import { FC, useEffect, useState } from 'react';
import { Avatar, Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButttonCustom from '../Button/Index';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateUserProfileSchema } from 'validation/validationSchema';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CustomTextField from '../TextField';
import { User } from 'typings/index';
import { patchToAPI, uploadFileToAPI } from 'api/index';
import cfg from 'config/index';
import { useDispatch } from 'react-redux';
import { getProfileSuccess } from 'store/user';

interface DialogEditProfileProps {
    user: User | null;
}

type FormUpdateUserProfile = {
    image?: File;
    name?: string;
    userName?: string
}

const defaultValues = {
    image: undefined,
    name: '',
    userName: '',
};


const DialogEditProfile: FC<DialogEditProfileProps> = ({ user }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const dispatch = useDispatch();
    const updateUserProfileSchema = useUpdateUserProfileSchema();
    const { control, handleSubmit, setValue } = useForm<FormUpdateUserProfile>({
        defaultValues,
        resolver: yupResolver(updateUserProfileSchema),
    });
    const onClose = () => setOpen(false)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setValue('image', file);
            const objectUrl = URL.createObjectURL(file);
            setImageUrl(objectUrl);
            const formData = new FormData();
            formData.append('image', file as File);
            uploadFileToAPI(null, formData, `${cfg.API_URL}/client/user/update?userId=${user?._id}`, 'patch')
                .then(() => {
                    setOpen(true);
                })
        }
    };

    const onSubmit = handleSubmit((data) => {
        if (user) {
            patchToAPI(null, { name: data.name || '', userName: data.userName || '' }, `${cfg.API_URL}/client/user/update?userId=${user._id}`)
                .then(({ data }: any) => {
                    if (data?.user) {
                        dispatch(getProfileSuccess({ result: data.user }))
                    }
                    onClose()
                })
        }
    });

    useEffect(() => {
        if (user) {
            setValue('name', user.name)
            setValue('userName', user.userName)
            setImageUrl(user.image)
        }
    }, [user])

    return (
        <>
            <ButttonCustom
                sx={{ height: '40px', width: { xs: '100%', sm: 'auto' } }}
                onClick={() => setOpen(true)}
                title={t('button.changeTheProfile')}
            />
            <Dialog
                open={open}
                onClose={onClose}
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: { xs: 0, sm: '24px' },
                        width: { xs: '100%', sm: '640px' },
                        m: 0,
                        height: { xs: '100%', sm: 'auto' },
                        maxHeight: '100%',
                        background: 'rgba(14, 14, 14, 1)'
                    }
                }}>
                <Box sx={{ p: { xs: 2, sm: 4 } }}>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between' }}>
                        <Typography sx={{ color: 'rgba(121, 255, 228, 1)', fontSize: '28px' }}>{t('profile.changeProfile.title')}</Typography>
                        <IconButton onClick={onClose}>
                            <CloseOutlinedIcon sx={{ color: '#1C1B20' }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={onClose}>
                                <CloseOutlinedIcon sx={{ color: '#1C1B20' }} />
                            </IconButton>
                        </Box>
                        <Typography sx={{ color: 'rgba(121, 255, 228, 1)', fontSize: '28px', textAlign: 'center', mt: 3 }}>{t('profile.changeProfile.title')}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: { xs: 2, sm: 4 } }}>
                        <Avatar src={imageUrl} alt='avatar' sx={{ width: '100px', height: '100px' }} />
                        <Button
                            component="label"
                            variant='text'
                            startIcon={<FileUploadOutlinedIcon />}
                            sx={{ color: 'rgba(121, 255, 228, 1)', background: 'inherit', textTransform: 'none' }}
                        >
                            Replace the photo
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>
                    </Box>
                    <Stack gap={4} mt={1}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                                <CustomTextField
                                    placeholder={t("profile.changeProfile.name")}
                                    fullWidth
                                    label={t("profile.changeProfile.name")}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="userName"
                            render={({ field }) => (
                                <CustomTextField
                                    placeholder={t("profile.changeProfile.nickname")}
                                    label={t("profile.changeProfile.nickname")}
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Stack>
                    <ButttonCustom onClick={onSubmit} fullWidth title='Save' sx={{ mt: 5, height: '56px' }} />
                    <ButttonCustom onClick={onClose} fullWidth title='Cancel' sx={{ mt: 2, height: '56px', background: 'rgba(239, 242, 245, 1)', color: '#1C1B20' }} />
                </Box>
            </Dialog >
        </>
    );
};

export default DialogEditProfile;
