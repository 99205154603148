// import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
// import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
// import { useLoginValidationSchema } from 'validation/validationSchema';
import SocialAuthButtons from 'components/ui/Auth/LoginForm/SocialAuthButtons';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import OverlayLoader from 'components/ui/OverlayLoader';
import { Path } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import { getUserSession } from 'utils/utils';
import { Box } from '@mui/material';

// interface FormData {
//   value: string;
//   // password: string;
//   isRememberDevice?: boolean;
// }

interface ILoginFormProps {
  handleClose: () => void;
}

const LoginForm: FC<ILoginFormProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading } = useAppSelector((state) => state.auth);

  const isLoggedIn = getUserSession();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(Path.ROOT);
    }
  }, [isLoggedIn]);
  // const dispatch = useDispatch();

  // const initialValues = {
  //   value: '',
  //   // password: '',
  //   isRememberDevice: false,
  // };

  // const loginValidationSchema = useLoginValidationSchema();

  // const { control, handleSubmit, reset } = useForm<FormData>({
  //   defaultValues: initialValues,
  //   resolver: yupResolver(loginValidationSchema),
  // });

  // const onSubmit = (data: any) => {
  //   console.log('DATA', data);
  //   const prepareData = prepareLoginData({ value: data.value });

  //   dispatch(loginStart(prepareData));
  //   reset();
  // };

  if (isLoading) {
    return <OverlayLoader />;
  }

  return (
    <Box>
      <ModalTitle title={t('auth.title.loginin')} />
      <SocialAuthButtons handleClose={handleClose} />
    </Box>

    // <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    //   <ModalTitle title={t('auth.title.login')} />
    //   <form onSubmit={handleSubmit(onSubmit)}>
    //     <Grid container spacing={2} mb={1}>
    //       <Grid size={12}>
    //         <StyledFormTextField
    //           control={control}
    //           name='value'
    //           labelKey={`auth.form.username`}
    //         />
    //       </Grid>
    //       {/* <Grid size={12}>
    //         <StyledFormTextField
    //           control={control}
    //           name='password'
    //           labelKey='auth.form.password'
    //           type='password'
    //         />
    //       </Grid> */}
    //     </Grid>

    //     {/* <ForgotPasswordButton handleClose={handleClose} /> */}

    //     <SocialAuthButtons />

    //     <Box mb={1}>
    //       <FormCheckbox
    //         control={control}
    //         name='isRememberDevice'
    //         label={t('auth.form.rememberThisDevice')}
    //         variant='h6'
    //       />
    //     </Box>

    //     <CustomButton i18nKey='form.button.login' />

    //     <Typography
    //       variant='body2'
    //       sx={{
    //         color: '#fff',
    //         mb: '16px',
    //       }}
    //     >
    //       <Trans
    //         i18nKey='auth.form.acceptTerms'
    //         components={{
    //           privacyPolicy: <PrivacyButton handleClose={handleClose} />,
    //         }}
    //       />
    //     </Typography>

    //     <Typography variant='h5'>
    //       <Trans
    //         i18nKey='auth.form.registrationPrompt'
    //         components={{
    //           register: (
    //             <ButtonPrompt
    //               handleClose={handleClose}
    //               content={t('form.button.register')}
    //               path={Path.REGISTRATION}
    //             />
    //           ),
    //         }}
    //       />
    //     </Typography>
    //   </form>
    // </GoogleOAuthProvider>
  );
};

export default LoginForm;
