import { Box, MenuItem as MuiMenuItem, Typography } from '@mui/material';
import React from 'react';

interface CustomMenuItemProps {
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
  rotate?: boolean;
}

const CustomMenuItem: React.FC<CustomMenuItemProps> = ({
  onClick,
  icon,
  label,
  rotate = false,
}) => {
  return (
    <MuiMenuItem
      onClick={onClick}
      sx={{
        p: 0,
        // '&:hover': {
        //   // backgroundColor: (theme) => theme.palette.custom.primary.blue,
        //   color: (theme) => theme.palette.custom.primary.white,
        //   '.icon': {
        //     color: (theme) => theme.palette.custom.primary.white,
        //   },
        //   transition: 'color 0.1s',
        // },
        color: "#fff",

      }}
    >
      <Box
        sx={{
          transform: rotate ? 'rotate(-90deg)' : 'none',
          marginRight: '12px',
          transition: 'color 0.1s',
          display: 'flex',
        }}
      >
        {icon}
      </Box>
      <Typography sx={{ fontFamily: 'DM Sans' }}>
        {label}
      </Typography>
    </MuiMenuItem>
  );
};

export default CustomMenuItem;
