import { Box, ListItem, Typography, useTheme } from '@mui/material';
import { commonMenu } from 'components/common/SidebarContentProvider/constants';
import CustomButton from 'components/ui/Auth/CustomButton';
import CustomDivider from 'components/ui/CustomDivider';
import DownloadAppIcons from 'components/ui/DownloadAppIcons';
import SidebarList from 'components/ui/SidebarList';
import { Path } from 'constants/index';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

interface DefaultSidebarContentProps {
  isShowSidebar?: boolean;
}

const DefaultSidebarContent: FC<DefaultSidebarContentProps> = ({ isShowSidebar }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeMenuItem, setActiveMenuItem] = useState<Path>(Path.RECOMMENDEDVIDEOS);

  const handleMenuClick = (path: Path) => {
    if (path === Path.BROADCASTS) {
      navigate('/login')
    } else {
      setActiveMenuItem(path);
      navigate(path);
    }

  };

  const handleLogin = () => {
    navigate(Path.LOGIN);
  };

  return (
    <Box>
      <SidebarList
        menu={commonMenu}
        activeMenuItem={activeMenuItem}
        handleMenuClick={handleMenuClick}
        isShowSidebar={isShowSidebar}
      />

      {isShowSidebar && <CustomDivider />}

      {isShowSidebar && <Typography
        variant='h6'
        sx={{
          color: '#6E7184',
          padding: '8px 16px',
          margin: '8px 0',
          fontFamily: 'Dm sans'
        }}
      >
        {t('sidebar.information')}
      </Typography>}

      {isShowSidebar && <CustomButton
        type='button'
        onClick={handleLogin}
        i18nKey='form.button.login'
        sx={{ mb: 1, background: '#fff' }}
      />}
      {/* 
      <CustomDivider />

      <Box display='flex' alignItems='center' py={1} px={2} my={1}>
        <IconHelp />
        <Typography variant='h6' ml={1.5}>
          {t('sidebar.help')}
        </Typography>
      </Box> */}

      {isShowSidebar && <CustomDivider />}

      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '24px',
          p: isShowSidebar ? 2 : '4px 16px',
          cursor: 'pointer',
          bgcolor:
            activeMenuItem === Path.HELP
              ? `rgba(121, 255, 228, 0.4)`
              : 'transparent',
          '&:hover': {
            bgcolor: `rgba(121, 255, 228, 0.1)`,
          },
        }}
        onClick={() => handleMenuClick(Path.HELP)}
      >
        <HelpOutlineOutlinedIcon sx={{ fontSize: '18px', color: '#fff' }} />
        {isShowSidebar && <Typography
          variant='h6'
          ml={1.5}
          sx={{ color: '#fff', fontFamily: 'Dm sans' }}
        >
          Help
        </Typography>}
      </ListItem>

      {isShowSidebar && <CustomDivider />}

      {isShowSidebar && <DownloadAppIcons />}
    </Box>
  );
};

export default DefaultSidebarContent;
