import OverlayLoader from 'components/ui/OverlayLoader';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useState } from 'react';

import PasswordRecoveryStep1 from './Steps/PasswordRecoveryStep1';
import PasswordRecoveryStep2 from './Steps/PasswordRecoveryStep2';
import PasswordRecoveryStep3 from './Steps/PasswordRecoveryStep3';
import PasswordRecoveryStep4 from './Steps/PasswordRecoveryStep4';

const PasswordRecoveryForm: FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const { isLoading } = useAppSelector((state) => state.auth);

  const handleNextStep = (data: any) => {
    setFormData((prev) => ({ ...prev, ...data }));
    setStep((prev) => prev + 1);
  };

  if (isLoading) {
    return <OverlayLoader />;
  }

  return (
    <>
      {step === 1 && <PasswordRecoveryStep1 onNext={handleNextStep} />}
      {step === 2 && (
        <PasswordRecoveryStep2 formData={formData} onNext={handleNextStep} />
      )}
      {step === 3 && <PasswordRecoveryStep3 onNext={handleNextStep} />}
      {step === 4 && <PasswordRecoveryStep4 />}
    </>
  );
};

export default PasswordRecoveryForm;
