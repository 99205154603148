import { Stack, useTheme } from '@mui/material';
import CustomButton from 'components/ui/Auth/CustomButton';
import { FC } from 'react';

interface IAddCancelButtonGroupProps {
  handleAddComment: () => void;
  handleCancelComment: () => void;
}

const AddCancelButtonGroup: FC<IAddCancelButtonGroupProps> = ({
  handleAddComment,
  handleCancelComment,
}) => {
  const theme = useTheme();

  return (
    <Stack direction='row' spacing={1}>
      <CustomButton
        i18nKey='button.send'
        type='button'
        onClick={handleAddComment}
        style={{
          padding: '10px 48px',
          width: 'auto',
          marginBottom: 0,
          marginRight: '12px',
          height: '40px',
        }}
      />
      <CustomButton
        i18nKey='button.cancel'
        type='button'
        onClick={handleCancelComment}
        style={{
          padding: '10px 48px',
          width: 'auto',
          marginBottom: 0,
          marginLeft: 0,
          height: '40px',
          backgroundColor: theme.palette.custom.primary.gray,
          border: 'none',
          color: '#fff',
        }}
      />
    </Stack>
  );
};

export default AddCancelButtonGroup;
