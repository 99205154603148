import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Popover, useTheme } from '@mui/material';
import CommentsPanel from 'components/ui/CommentsPanel';
import ReportPanel from 'components/ui/ReportPanel';
import SharingPanel from 'components/ui/SharingPanel';
import { VideoPopoverType } from 'constants/index';
import { FC } from 'react';
import { Video } from 'typings/index';

interface IVideoInformationPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleActionClose: () => void;
  popoverType: VideoPopoverType;
  video: Video;
}

const VideoInformationPopover: FC<IVideoInformationPopoverProps> = ({
  anchorEl,
  handleActionClose,
  popoverType,
  video,
}) => {
  const theme = useTheme();

  const renderContent = () => {
    switch (popoverType) {
      case VideoPopoverType.COMMENTS:
        return <CommentsPanel video={video} />;
      case VideoPopoverType.SHARING:
        return <SharingPanel video={video} />;
      case VideoPopoverType.DOTS:
        return (
          <ReportPanel
            videoId={video._id}
            handleActionClose={handleActionClose}
          />
        );
      default:
        return <CommentsPanel video={video} />;
    }
  };

  const anchorPosition = anchorEl
    ? {
        top: anchorEl.getBoundingClientRect().top,
        left: anchorEl.getBoundingClientRect().right + window.scrollX + 48,
      }
    : undefined;

  const popoverHeight =
    popoverType === VideoPopoverType.COMMENTS ? '85%' : 'auto';
  const popoverWidth =
    popoverType === VideoPopoverType.COMMENTS ? '448px' : '408px';

  return (
    <Popover
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
      open={Boolean(anchorEl)}
      onClose={handleActionClose}
      anchorReference='anchorPosition'
      anchorPosition={anchorPosition}
      PaperProps={{
        sx: {
          p: '24px',
          borderRadius: '24px',
          width: popoverWidth,
          height: popoverHeight,
          background: 'rgba(14, 14, 14, 1)',
        },
      }}
    >
      {renderContent()}

      <IconButton
        aria-label='close'
        onClick={handleActionClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: '#fff',
        }}
      >
        <CloseIcon />
      </IconButton>
    </Popover>
  );
};

export default VideoInformationPopover;
