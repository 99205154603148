import { Avatar, Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getUserSession } from 'utils/utils';
import { io, Socket } from 'socket.io-client';
import cfg from 'config/index';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommentsPanelStream from './CommentsPanelStream';

const LiveStream: FC<PropsWithChildren> = ({ children }) => {
  const { streamId = '' } = useParams<{ streamId: string }>();
  const theme = useTheme();
  const user = getUserSession();
  const socketInstance = useRef<Socket | null>(null);
  const [comments, setComments] = useState<any>([])
  const [views, setViews] = useState<any>(0)
  const [lessViews, setLessViews] = useState<any>(null)
  const [addViews, setAddViews] = useState<any>(0)
  const [showChat, setShowChat] = useState(false)
 
  const [streamData] = useState<any>(JSON.parse(localStorage.getItem('stream') || ''))
  useEffect(() => {
    if (!socketInstance.current) {
      socketInstance.current = io(`${cfg.API_URL}/?globalRoom=globalRoom:${user._id}`).connect()

      socketInstance.current.on('connect', () => {
        console.log('Подключение к сокету установлено');
      });

      socketInstance.current.on('liveChat', (data) => {
        data && setComments((prevComments: any) => [...prevComments, JSON.parse(data)])
      });

      socketInstance.current.on('addView', (data) => {
        setAddViews(data)
      });

      socketInstance.current.on('lessView', (data) => {
        setLessViews(data)
      });
    }

    return () => {
      if (socketInstance.current) {
        socketInstance.current.emit('lessView', JSON.stringify({
          liveHistoryId: streamId?.split('_')[0] || '',
          userId: user._id
        }));
        socketInstance.current.disconnect();
        console.log('Отключение от сокета');
      }
    };
  }, [user._id, streamId]);

  const handleSendComment = (newComment: string) => {
    if (newComment.trim() !== '') {
      if (socketInstance.current) {
        socketInstance.current.emit('liveChat', JSON.stringify({
          liveHistoryId: streamId?.split('_')[0] || '',
          commentText: newComment,
          userId: user._id,
          userName: user.userName,
          userImage: user.image,
        }));
      }
    }
  };

  useEffect(() => {
    if (socketInstance.current) {
      socketInstance.current.emit('liveRoomConnect', JSON.stringify({
        liveHistoryId: streamId?.split('_')[0] || ''
      }));
      socketInstance.current.emit('addView', JSON.stringify({
        liveHistoryId: streamId?.split('_')[0] || '',
        userId: user._id,
        userName: user.userName,
      }));
    }
  }, [socketInstance.current])

  useEffect(() => {
    setViews(lessViews && lessViews < addViews ? lessViews : addViews)
  }, [lessViews, addViews])

  const openChat = () => setShowChat(true)
  const closeChat = () => setShowChat(false)

  return <Box width="100%" sx={{ p: { xs: 0, sm: 2 }, background: { xs: '#fff', sm: 'inherit' }, display: { xs: 'block', sm: 'flex' }, height: '100%' }}>
    <Grid container width="100%" spacing={3}>
      <Grid size={{ xs: 12, sm: 8 }}>
        <Box width="100%" sx={{ position: 'relative', height: { xs: '232px', sm: '86%' } }}>
          {children}
          <Box sx={{ position: 'absolute', top: 20, left: 100, display: { xs: 'none', sm: 'flex' }, alignItems: 'center', gap: 1, zIndex: 9999 }}>
            <VisibilityOutlinedIcon sx={{ color: '#6E7184' }} />
            <Typography sx={{ color: '#6E7184' }}>{views}</Typography>
          </Box>
        </Box>
        {!showChat && <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: { xs: '0 16px', sm: 0 }, width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Typography sx={{ color: '#1C1B20', mb: 1, fontSize: { xs: '16px', sm: '28px' } }}>{streamData.name}</Typography>
              <Box sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center', gap: 1 }}>
                <VisibilityOutlinedIcon sx={{ color: '#6E7184' }} />
                <Typography sx={{ color: '#6E7184' }}>{views}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar
                src={streamData.image}
                alt='username'
                sx={{ width: 48, height: 48 }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Typography variant='h6' sx={{ color: '#fff' }}>{streamData.userName}</Typography>
                <CheckCircleIcon sx={{ color: theme.palette.custom.primary.blue, fontSize: '14px', mt: '2px' }} />
              </Box>
            </Box>
          </Box>
        </Box>}
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}
        sx={{ p: { xs: '0 16px', sm: 0 } }}
      >
        <Box sx={{ height: { xs: 'auto', sm: '100%' }, borderRadius: '16px' }}>
          <CommentsPanelStream showChat={showChat} openChat={openChat} closeChat={closeChat} handleSendComment={handleSendComment} comments={comments} />
        </Box>
      </Grid>
    </Grid>
  </Box >;
};

export default LiveStream;
