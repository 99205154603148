import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportReason } from 'typings/index';

interface FollowState {
  reportReason: ReportReason[];
  error: string | null;
  isLoading: boolean;
}

const initialState: FollowState = {
  reportReason: [],
  error: null,
  isLoading: false,
};

const getReportReasonSlice = createSlice({
  name: 'report/reason/get',
  initialState,
  reducers: {
    getReportReasonStart: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    getReportReasonSuccess: (
      state,
      action: PayloadAction<{ result: ReportReason[] }>
    ) => {
      state.reportReason = action.payload.result;
      state.error = null;
      state.isLoading = false;
    },
    getReportReasonFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  getReportReasonStart,
  getReportReasonSuccess,
  getReportReasonFailure,
} = getReportReasonSlice.actions;

export default getReportReasonSlice.reducer;
