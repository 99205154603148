import { Box, Button } from '@mui/material';
import { ZegoExpressEngine } from 'zego-express-engine-webrtc';
import { APP_ID, MAIN_SERVER_URL, SERVER_SECRET } from 'components/ui/LiveStream/constants';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserSession } from 'utils/utils';
import generateToken from './GenerateToken';

const ZegoStream: FC = () => {
  const user = getUserSession();
  const { streamId = '' } = useParams<{ streamId: string }>();
  const remoteStreamContainerRef = useRef<any>(null);
  const [isConnected, setIsConnected] = useState(false);
  const zegoEngineRef = useRef<ZegoExpressEngine | null>(null);
  const roomId = streamId?.split('_')[0]
  const userId = streamId?.split('_')[1]

  useEffect(() => {
    const initializeStream = async () => {
      if (!roomId || typeof roomId !== 'string' || !roomId.trim()) {
        console.error('Ошибка: roomId должен быть строкой и не может быть пустым');
        alert('Room ID отсутствует или некорректен. Пожалуйста, проверьте ссылку.');
        return;
      }

      const userID = user?._id || 'guest';
      const userName = user?.userName || 'Guest';

      if (!userID || !userName) {
        console.error('Ошибка: userID или userName отсутствует');
        alert('Параметры пользователя некорректны.');
        return;
      }

      const zegoEngine = new ZegoExpressEngine(APP_ID, MAIN_SERVER_URL);
      zegoEngineRef.current = zegoEngine;
      zegoEngine.setLogConfig({
        logLevel: "error"
      });
      const token = generateToken(userID, 86400);

      try {
        await zegoEngine.loginRoom(roomId, token, {
          userID,
          userName,
        });

        console.log('Успешно вошли в комнату:', roomId);

        const remoteStream = await zegoEngine.startPlayingStream(`${roomId}_${userId}_call`);

        const remoteView = zegoEngine.createRemoteStreamView(remoteStream);

        remoteView.play("streamUser", { enableAutoplayDialog: true });

      } catch (error) {
        console.error('Ошибка при подключении или создании стрима:', error);
        alert(`Ошибка: ${error instanceof Error ? error.message : 'Неизвестная ошибка'}`);
      }
    };

    initializeStream();

    return () => {
      const roomId = streamId.split('_')[0]
      setIsConnected(false);
      if (zegoEngineRef.current) {
        zegoEngineRef.current.logoutRoom(roomId);
        zegoEngineRef.current = null;
      }
      if (remoteStreamContainerRef.current) {
        remoteStreamContainerRef.current.innerHTML = '';
      }
    };
  }, [streamId, user]);

  return (
    <Box width="100%" height="100%" position="relative">
      <Box ref={zegoEngineRef} className="streamUser" id="streamUser" width="100%" sx={{ height: { xs: '232px', sm: 'calc(100vh - 220px)', borderRadius: { xs: 0, sm: '16px' } } }}></Box>
    </Box>
  );
};

export default ZegoStream;