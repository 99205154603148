import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { IconRedThumbUp, IconThumbUp } from 'assets/index';
import OverlayLoader from 'components/ui/OverlayLoader';
import { ContentType } from 'constants/index';
import EmojiPicker from 'emoji-picker-react';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  addCommentStart,
  likeCommentStart,
  setInitialLikeStatus,
} from 'store/video';
import { Video } from 'typings/index';
import { formatNumber, getUserSession } from 'utils/utils';

import CommentActionPopover from 'components/ui/CommentActionPopover';
import { OwnerType } from '../CommentsPanel/constants';
import AddCommentField from '../CommentsPanel/AddCommentField';
import AddCommentFieldStreamHost from './AddCommentFieldStreamHost';

interface CommentsPanelStreamProps {
  handleSendComment: (val: string) => void;
  comments: any;
  closeComments?: () => void;
}

const CommentsPanelStreamHost: FC<CommentsPanelStreamProps> = ({ handleSendComment, comments, closeComments }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = getUserSession();
  // const videoIdOwner = video.userId;

  // // Проверяем, зашел ли пользователь в свой профиль
  // const isVideoOwner = useMemo(
  //   () => user && videoIdOwner === user._id,
  //   [user, videoIdOwner]
  // );

  const { isLoading, videoComments } = useAppSelector(
    (state) => state.video.getComments
  );

  const {
    likeStatusByCommentId,
    likeCountsByCommentId,
    error: storeLikeError,
  } = useAppSelector((state) => state.video.likeComment);

  const [commentText, setCommentText] = useState('');
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [currentCommentId, setCurrentCommentId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverType, setPopoverType] = useState<OwnerType>(
    OwnerType.VIDEO_OWNER
  );

  // Локальное состояние для хранения лайков и статуса лайка
  const [likeCounts, setLikeCounts] = useState<{ [key: string]: number }>(
    videoComments.reduce((acc: { [key: string]: number }, comment) => {
      acc[comment._id] = comment.totalLikes || 0;
      return acc;
    }, {})
  );

  const [isLikedByCommentId, setIsLikedByCommentId] = useState<{
    [key: string]: boolean;
  }>(
    videoComments.reduce((acc: { [key: string]: boolean }, comment) => {
      acc[comment._id] = comment.isLike || false;
      return acc;
    }, {})
  );

  const [prevLikeState, setPrevLikeState] = useState<{
    [key: string]: { liked: boolean; count: number };
  }>({});

  useEffect(() => {
    const initialLikeStatus = videoComments.reduce(
      (
        acc: { [key: string]: { isLike: boolean; likeCount: number } },
        comment
      ) => {
        acc[comment._id] = {
          isLike: comment.isLike || false,
          likeCount: comment.totalLikes || 0,
        };
        return acc;
      },
      {}
    );

    dispatch(setInitialLikeStatus(initialLikeStatus));
  }, [videoComments, dispatch]);

  const handleAddComment = () => {
    if (!commentText.trim()) return;

    // dispatch(
    //   addCommentStart({
    //     videoId: video._id,
    //     userId: user._id,
    //     commentText,
    //     type: ContentType.VIDEO,
    //   })
    // );
    handleSendComment(commentText)
    setCommentText('');
  };

  const handleCancelComment = () => {
    setCommentText('');
  };

  const handleKeyPress = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddComment();
    }
  };

  const handleLikeClick = (commentId: string) => {
    const currentLikeStatus = isLikedByCommentId[commentId];
    const updatedLikeStatus = !currentLikeStatus;

    // Сохраняем предыдущее состояние
    setPrevLikeState((prevState) => ({
      ...prevState,
      [commentId]: {
        liked: currentLikeStatus,
        count: likeCounts[commentId],
      },
    }));

    // Обновляем UI мгновенно
    setIsLikedByCommentId((prev) => ({
      ...prev,
      [commentId]: updatedLikeStatus,
    }));
    setLikeCounts((prevLikeCounts) => ({
      ...prevLikeCounts,
      [commentId]: updatedLikeStatus
        ? (prevLikeCounts[commentId] || 0) + 1
        : (prevLikeCounts[commentId] || 1) - 1,
    }));

    // Отправляем запрос на сервер
    dispatch(
      likeCommentStart({
        postOrVideoCommentId: commentId,
        userId: user._id,
      })
    );
  };

  // Возвращаем предыдущее состояние при ошибке
  useEffect(() => {
    if (storeLikeError) {
      Object.keys(prevLikeState).forEach((commentId) => {
        setIsLikedByCommentId((prev) => ({
          ...prev,
          [commentId]: prevLikeState[commentId].liked,
        }));
        setLikeCounts((prev) => ({
          ...prev,
          [commentId]: prevLikeState[commentId].count,
        }));
      });
    }
  }, [storeLikeError, prevLikeState]);

  const handleEmojiSelect = (emoji: any) => {
    setCommentText(commentText + emoji.emoji);
    setIsEmojiPickerOpen(false);
  };

  const handleSetIsEmojiPickerOpen = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const handleActionCommentClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string,
    type: OwnerType
  ) => {
    setPopoverType(type);
    setAnchorEl(e.currentTarget);
    setCurrentCommentId(id);
  };

  const handleActionCommentClose = () => {
    setAnchorEl(null);
    setCurrentCommentId(null);
  };

  if (isLoading) {
    return <OverlayLoader />;
  }

  return (
    <>
      <Stack justifyContent='space-between' height='100%'>
        <Stack height='90%'>
          <Typography variant='h3' sx={{ mb: 2, display: { xs: 'none', sm: 'block' } }}>
            Chat
          </Typography>

          {/* Комментарии */}
          <Stack sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, pr: 2, height: 'calc(100vh - 250px)' }}>
            {comments && comments.map((comment: any, i: number) => {
              return (
                <Stack key={i} direction='row' spacing={2} mb={1}>
                  {/* <Avatar src={comment.userImage} alt='username' /> */}
                  <Box sx={{ flexGrow: 1, p: { xs: 2, sm: 0 } }}>
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      mb={0.5}
                    >
                      <Stack direction='row' alignItems='center'>
                        <Typography
                          variant='h5'
                          sx={{ color: {xs:'#fff', sm: '#000'} }}
                          mr={1}
                        >
                          {comment.userName}
                        </Typography>
                        {/* <Typography
                          variant='body2'
                          sx={{
                            color: `${theme.palette.custom.primary.graphite}70`,
                          }}
                        >
                          {comment.time}
                        </Typography> */}
                      </Stack>
                      {/* {(isVideoOwner || isCommentOwner) && (
                        <IconButton
                          onClick={(e) =>
                            handleActionCommentClick(
                              e,
                              comment._id,
                              isCommentOwner
                                ? OwnerType.COMMENT_OWNER
                                : OwnerType.VIDEO_OWNER
                            )
                          }
                        >
                          <IconKebabMenu />
                        </IconButton>
                      )} */}
                    </Stack>
                    <Typography
                      variant='h6'
                      sx={{ color: {xs:'#fff', sm: '#000'} }}
                      mb={1}
                    >
                      {comment.commentText}
                    </Typography>
                    {/* <Stack direction='row' alignItems='center'>
                      <IconButton
                        onClick={() => handleLikeClick(comment._id)}
                        sx={{
                          '&:hover': {
                            bgcolor: theme.palette.custom.primary.white,
                          },
                        }}
                      >
                        {isLiked ? <IconRedThumbUp /> : <IconThumbUp />}
                      </IconButton>
                      <Typography
                        variant='h6'
                        sx={{
                          color: isLiked
                            ? theme.palette.custom.primary.extraError
                            : theme.palette.custom.primary.graphite,
                        }}
                      >
                        {formatNumber(likeCount)}
                      </Typography>
                    </Stack> */}
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </Stack>

        {/* Поле ввода комментария */}
        <AddCommentFieldStreamHost
          commentText={commentText}
          handleAddComment={handleAddComment}
          handleCancelComment={handleCancelComment}
          handleKeyPress={handleKeyPress}
          handleSetIsEmojiPickerOpen={handleSetIsEmojiPickerOpen}
          image={user.image}
          setCommentText={setCommentText}
          sx={{ background: '#fff' }}
          closeComments={closeComments}
        />

        {/* Эмодзи-пикер */}
        {isEmojiPickerOpen && (
          <Box sx={{ position: 'absolute', bottom: '80px' }}>
            <EmojiPicker onEmojiClick={handleEmojiSelect} />
          </Box>
        )}
      </Stack>
      <CommentActionPopover
        anchorEl={anchorEl}
        handleActionClose={handleActionCommentClose}
        popoverType={popoverType}
        currentCommentId={currentCommentId}
      />
    </>
  );
};

export default CommentsPanelStreamHost;
