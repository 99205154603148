import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { IconPhotoUpload } from 'assets';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PhotoUpload = () => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<string | undefined>(
    undefined
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedFile(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      mb={3}
    >
      <IconButton
        color='primary'
        aria-label='upload picture'
        component='label'
        sx={{ fontSize: 64 }}
      >
        <input
          hidden
          accept='image/*'
          type='file'
          onChange={handleFileChange}
        />

        {selectedFile ? (
          <Avatar
            src={selectedFile}
            alt='Uploaded photo'
            sx={{ width: '100px', height: '100px' }}
          />
        ) : (
          <IconPhotoUpload />
        )}
      </IconButton>
      <Typography variant='h6'>
        {selectedFile ? t('auth.form.change') : t('auth.form.upload')}
      </Typography>
    </Box>
  );
};

export default PhotoUpload;
