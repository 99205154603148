import { createSvgIcon } from "@mui/material";

// FIXME: Need extract only document icon, without background
const GooglePlayIcon = createSvgIcon(
    <svg
        width={20}
        height={24}
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.0839844 21.7801V2.22005C0.0839844 1.5412 0.46389 0.94289 1.02258 0.666748L12.0287 12.0001L1.02258 23.3334C0.46389 23.0458 0.0839844 22.459 0.0839844 21.7801ZM15.5149 15.5899L3.49197 22.7466L12.9784 12.9781L15.5149 15.5899ZM19.2581 10.6309C19.638 10.9415 19.9173 11.4248 19.9173 12.0001C19.9173 12.5754 19.6715 13.0356 19.2804 13.3578L16.7216 14.8766L13.9282 12.0001L16.7216 9.1236L19.2581 10.6309ZM3.49197 1.25355L15.5149 8.41023L12.9784 11.0221L3.49197 1.25355Z"
            fill="#79FFE4"
        />
    </svg>,
    "GooglePlay",
);

export default GooglePlayIcon;
