import { Avatar, Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatNumber } from 'utils/utils';
import BroadcastIcon from 'assets/IconsComponents/Broadcast';
import ButttonCustom from 'components/ui/Button/Index';
import { BroadcastRes } from 'typings/broadcast';

interface CardStreamProps {
  stream: BroadcastRes
}

const CardStream: FC<CardStreamProps> = ({ stream }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box sx={{
      p: { xs: 2, xl: 4 },
      borderRadius: '16px',
      background: '#fff',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        background: 'linear-gradient(315deg, #395AFC 0.01%, #CBE4FF 100%)'

      }
    }}>
      <Typography variant='h3' sx={{ color: '#fff' }}>{stream.name}</Typography>
      <Grid container spacing={1} sx={{ alignItems: 'flex-end' }}>
        <Grid size={{ xs: 12, md: 7 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
            <Avatar
              src={stream.image}
              alt='username'
              sx={{ width: 48, height: 48 }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <Typography variant='h6' sx={{ color: theme.palette.custom.primary.graphite }}>{stream.userName}</Typography>
              <CheckCircleIcon sx={{ color: theme.palette.custom.primary.blue, fontSize: '14px', mt: '2px' }} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, background: '#F04444', p: '0 4px', borderRadius: '2px' }}>
              <BroadcastIcon sx={{ fontSize: '12px', color: '#fff' }} />
              <Typography sx={{ color: '#fff', fontSize: '12px' }}>On air</Typography>
            </Box>
          </Box>
          <Typography
            variant='h6'
            sx={{ color: theme.palette.custom.primary.graphite, mt: 1 }}
          >
            {t('broadcasts.audience')}: {formatNumber(stream.view)}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 5 }} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <ButttonCustom
            sx={{
              background: '#EFF2F5',
              color: '#fff',
              width: { xs: '100% !important', sm: 'auto' },
              mt: { xs: '10px !important', sm: 0 }
            }}
            title={t('broadcasts.watchBroadcast')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardStream;
