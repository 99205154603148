import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IComment } from 'typings/index';

interface ICommentProps {
  userId: string;
  type: string;
  commentText: string;
  postId?: string;
  videoId?: string;
}

interface LikeState {
  videoComment: IComment | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: LikeState = {
  videoComment: null,
  error: null,
  isLoading: false,
};

const addCommentSlice = createSlice({
  name: 'add/comment',
  initialState,
  reducers: {
    addCommentStart: (state, action: PayloadAction<ICommentProps>) => {
      state.error = null;
      state.isLoading = true;
    },
    addCommentSuccess: (state, action: PayloadAction<{ result: IComment }>) => {
      state.videoComment = action.payload.result;
      state.error = null;
      state.isLoading = false;
    },
    addCommentFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
      state.videoComment = null;
    },
  },
});

export const { addCommentStart, addCommentSuccess, addCommentFailure } =
  addCommentSlice.actions;

export default addCommentSlice.reducer;
