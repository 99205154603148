import React, { createContext, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

interface TitleContextProps {
  setTitle: (title: string) => void;
}

const TitleContext = createContext<TitleContextProps | undefined>(undefined);

export const TitleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [title, setTitle] = useState<string>('IEarn');

  useEffect(() => {
    // Этот эффект обновляет заголовок вкладки, когда `title` меняется
    document.title = title;
  }, [title]);

  return (
    <TitleContext.Provider value={{ setTitle }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </TitleContext.Provider>
  );
};

export const useTitle = (): TitleContextProps => {
  const context = useContext(TitleContext);
  if (!context) {
    throw new Error('useTitle must be used within a TitleProvider');
  }
  return context;
};
