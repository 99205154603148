/* eslint-disable */

function insertEnvVars(value: unknown) {
  const isValidVar = typeof value === 'string' && !/\${/.test(value);

  return isValidVar ? value : '';
}

const {
  REACT_APP_API_URL = insertEnvVars('${API_URL}'),
  REACT_APP_DADATA_API_URL = insertEnvVars('${DADATA_API_URL}'),
} = process.env;

// const {
//   REACT_APP_API_URL,
//   REACT_APP_DADATA_API_URL,
// } = process.env;


export default {
  API_URL: REACT_APP_API_URL,
  DADATA_API_URL: REACT_APP_DADATA_API_URL,
};





