import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UploadProps {
  file: File | undefined;
  setVideoFile: Dispatch<SetStateAction<File | undefined>>
}

const LoadingVideoProgress: FC<UploadProps> = ({ file, setVideoFile }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [progress, setProgress] = useState(30);

  const increaseProgress = () => {
    setProgress((prev) => (prev >= 100 ? 0 : prev + 10));
  };
  return (
    <Box sx={{
      background: 'rgba(110, 113, 132, 0.1)',
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%'
    }}
    >
      <Box sx={{
        borderRadius: '50%',
        width: '57px',
        height: '57px',
        border: '3px solid rgba(110, 113, 132, 0.2)',
        position: 'relative'
      }}>
        <CircularProgress
          variant="determinate"
          value={progress}
          size={56}
          thickness={2}
          sx={{
            color: '#79FFE4 !important',
            position: 'absolute',
            top: '-2px',
            left: '-2px',
            transform: 'rotate(90deg) !important'
          }} />
        <CloseIcon onClick={() => setVideoFile(undefined)} sx={{ position: 'absolute', top: '28%', left: '28%', cursor: 'pointer' }} />
      </Box>

      <Typography variant="h6" sx={{ color: theme.palette.custom.primary.graphite, textAlign: 'center', mt: 1, fontFamily: 'Dm sans', fontWeight: 'bold' }}>
        {`${Math.round(progress)}%`}
      </Typography>
      <Typography variant="h6" sx={{ color: theme.palette.custom.primary.graphite, textAlign: 'center', mt: 1 }}>
        {file?.name}
      </Typography>
      {/* <Button variant="contained" color="primary" onClick={increaseProgress} sx={{ mt: 2 }}>
        Increase Progress
      </Button> */}
    </Box>

  );
};

export default LoadingVideoProgress;
