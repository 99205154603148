import { yupResolver } from '@hookform/resolvers/yup';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import EmailConfirmation from 'components/ui/EmailConfirmation';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCodeValidationSchema } from 'validation/validationSchema';

interface PasswordRecoveryStep2Data {
  code: string;
}

interface IPasswordRecoveryStep2Props {
  onNext: (data: PasswordRecoveryStep2Data) => void;
  formData: any;
}

const PasswordRecoveryStep2: FC<IPasswordRecoveryStep2Props> = ({
  onNext,
  formData,
}) => {
  const { t } = useTranslation();

  const codeValidationSchema = useCodeValidationSchema();

  const defaultValues = {
    code: '',
  };

  const { control, handleSubmit } = useForm<PasswordRecoveryStep2Data>({
    defaultValues,
    resolver: yupResolver(codeValidationSchema),
  });

  const onSubmit = (data: PasswordRecoveryStep2Data) => {
    onNext(data);
  };

  return (
    <>
      <ModalTitle title={t('auth.title.passwordRecovery')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailConfirmation
          content={'auth.form.passwordRecoveryInfo'}
          control={control}
          email={formData.email}
          pathReplace='/recovery-password'
        />
      </form>
    </>
  );
};

export default PasswordRecoveryStep2;
