import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LikeCommentState {
  likeStatusByCommentId: {
    [postOrVideoCommentId: string]: boolean | undefined;
  };
  likeCountsByCommentId: { [postOrVideoCommentId: string]: number | undefined };
  error: string | null;
}

const initialState: LikeCommentState = {
  likeStatusByCommentId: {},
  likeCountsByCommentId: {},
  error: null,
};

const likeCommentSlice = createSlice({
  name: 'comment/like',
  initialState,
  reducers: {
    likeCommentStart: (
      state,
      action: PayloadAction<{
        postOrVideoCommentId: string;
        userId: string;
      }>
    ) => {
      state.error = null;
    },
    likeCommentSuccess: (
      state,
      action: PayloadAction<{ postOrVideoCommentId: string; isLike: boolean }>
    ) => {
      const { postOrVideoCommentId, isLike } = action.payload;

      state.likeStatusByCommentId[postOrVideoCommentId] = isLike;
      state.error = null;
    },
    likeCommentFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setInitialLikeStatus: (
      state,
      action: PayloadAction<{
        [postOrVideoCommentId: string]: { isLike: boolean; likeCount: number };
      }>
    ) => {
      for (const [commentId, { isLike, likeCount }] of Object.entries(
        action.payload
      )) {
        state.likeStatusByCommentId[commentId] = isLike;
        state.likeCountsByCommentId[commentId] = likeCount;
      }
    },
  },
});

export const {
  likeCommentStart,
  likeCommentSuccess,
  likeCommentFailure,
  setInitialLikeStatus,
} = likeCommentSlice.actions;

export default likeCommentSlice.reducer;
