import { Divider, TypographyProps, useTheme } from '@mui/material';
import React from 'react';

interface FormTitleProps extends TypographyProps {
  className?: string;
}

const CustomDivider: React.FC<FormTitleProps> = ({ className }) => {
  const theme = useTheme();

  return (
    <Divider
      sx={{
        borderColor: `${theme.palette.custom.primary.graphite}20`,
      }}
      className={className}
    />
  );
};

export default CustomDivider;
