import { Stack } from '@mui/material';
import { IconLogin, IconRegister } from 'assets';
import CustomMenuItem from 'components/common/Header/CustomMenuItem';
import { AuthModalType } from 'constants/index';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AuthCustomMenuProps {
  handleOpenModal: (type: AuthModalType) => void;
}

const AuthCustomMenu: React.FC<AuthCustomMenuProps> = ({ handleOpenModal }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={3} p={3}>
      <CustomMenuItem
        onClick={() => handleOpenModal(AuthModalType.LOGIN)}
        icon={<IconLogin />}
        label={t('header.login')}
        rotate
      />
      {/* <CustomMenuItem
        onClick={() => handleOpenModal(AuthModalType.REGISTRATION)}
        icon={<IconRegister />}
        label={t('header.register')}
      /> */}
    </Stack>
  );
};

export default AuthCustomMenu;
