import { postToAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  likeCommentFailure,
  likeCommentStart,
  likeCommentSuccess,
} from '../slices/likeCommentSlice';

function* likeCommentSaga(
  action: ReturnType<typeof likeCommentStart>
): SagaIterator {
  const endpoint = '/client/postOrvideoComment/likeOrDislikeOfComment';
  const { userId, postOrVideoCommentId } = action.payload;

  const options = { userId, postOrVideoCommentId };

  try {
    const response: AxiosResponse = yield call(
      postToAPI,
      options,
      {},
      endpoint
    );

    const result = response.data;

    if (result) {
      yield put(
        likeCommentSuccess({ postOrVideoCommentId, isLike: result.isLike })
      );
    } else {
      yield put(likeCommentFailure('Ошибка: Некорректный ответ от сервера'));
    }
  } catch (e) {
    yield put(likeCommentFailure('Ошибка при выполнении запроса на сервер'));
  }
}

export function* watchLikeCommentSaga(): SagaIterator {
  yield takeEvery(likeCommentStart.type, likeCommentSaga);
}
