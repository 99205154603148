import AuthModalForm from 'components/ui/Auth/AuthModalForm';
import useModal from 'hooks/useModal';
import { FC } from 'react';

const AuthPage: FC = () => {
  const { isOpen, closeModal, modalType } = useModal();

  return <AuthModalForm open={isOpen} onClose={closeModal} type={modalType} />;
};

export default AuthPage;
