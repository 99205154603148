import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid2';
import CustomButton from 'components/ui/Auth/CustomButton';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import PhotoUpload from 'components/ui/PhotoUpload';
import StyledFormRadioGroup from 'components/ui/StyledFormRadioGroup';
import StyledFormSelect from 'components/ui/StyledFormSelect';
import StyledFormTextField from 'components/ui/StyledFormTextField';
import { countryOptions, genderOptions } from 'constants/index';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useProfileInfoValidationSchema } from 'validation/validationSchema';

interface RegistrationStep2Data {
  fullName: string;
  nickname: string;
  clientId: number;
  country: string;
  gender: string;
  aboutMe?: string;
}

interface IRegistrationStep2Props {
  onNext: (data: RegistrationStep2Data) => void;
}

const RegistrationStep2: FC<IRegistrationStep2Props> = ({ onNext }) => {
  const { t } = useTranslation();

  const profileInfoValidationSchema = useProfileInfoValidationSchema();

  const defaultValues = {
    fullName: '',
    nickname: '',
    clientId: 10,
    country: '',
    aboutMe: '',
    gender: '',
  };

  const { control, handleSubmit } = useForm<RegistrationStep2Data>({
    defaultValues,
    resolver: yupResolver(profileInfoValidationSchema),
  });

  const onSubmit = (data: RegistrationStep2Data) => {
    onNext(data);
  };

  return (
    <>
      <ModalTitle title={t('auth.title.registrationProfile')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhotoUpload />
        <Grid container spacing={2} sx={{ mb: '36px' }}>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='fullName'
              labelKey={`auth.form.fullName`}
            />
          </Grid>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='nickname'
              labelKey={`auth.form.nickname`}
            />
          </Grid>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='clientId'
              labelKey={`auth.form.clientId`}
              disabled
            />
          </Grid>
          <Grid size={12}>
            <StyledFormSelect
              control={control}
              name='country'
              labelKey='auth.form.country.label'
              options={countryOptions}
            />
          </Grid>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='aboutMe'
              labelKey={`auth.form.aboutMe`}
              multiline
            />
          </Grid>
          <Grid size={12}>
            <StyledFormRadioGroup
              control={control}
              name='gender'
              labelKey='auth.form.gender.label'
              options={genderOptions}
            />
          </Grid>
        </Grid>

        <CustomButton i18nKey='form.button.save' />
      </form>
    </>
  );
};

export default RegistrationStep2;
