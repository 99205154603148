import OverlayLoader from 'components/ui/OverlayLoader';
import VideoFeed from 'components/ui/VideoFeed';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getAllVideoStart,
  getUserVideoStart,
  setCurrentPlayingVideoId,
} from 'store/video';
import { getIdsFromPathname, getIdsShortsFromPathname, getUserSession } from 'utils/utils';

const ShortsPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { isLoading: isGetAllVideosLoading, videos } = useAppSelector(
    (state) => state.video.getAllVideo
  );
  const { isLoading: isGetUserVideosLoading, userVideos } = useAppSelector(
    (state) => state.video.getUserVideo
  );

  const user = getUserSession();
  const userId = user?._id || null;
  const { userId: userIdParams, videoId: videoIdParams } = getIdsShortsFromPathname(
    location.pathname
  );

  useEffect(() => {
    if ((!videos.length && userIdParams === userId) || !userId) {
      dispatch(getAllVideoStart({ userId }));
    }
  }, [userIdParams]);

  useEffect(() => {
    if (userIdParams && userIdParams !== userId) {
      dispatch(getUserVideoStart({ userId, toUserId: userIdParams }));
    }
  }, []);

  // Проверяем, если `videoId` указано в URL и устанавливаем его как текущее видео
  useEffect(() => {
    if (videoIdParams) {
      dispatch(setCurrentPlayingVideoId(videoIdParams));
    }
  }, [videoIdParams]);

  const displayedVideos = userIdParams === userId ? videos : userVideos;

  // const displayedVideos = !userId ? videos : userVideos;

  if (isGetAllVideosLoading || isGetUserVideosLoading) {
    <OverlayLoader />;
  }

  return (
    <VideoFeed
      videos={displayedVideos}
      userId={userIdParams}
      videoId={videoIdParams}
      scrollRef={scrollRef}
      pageUrl='shorts'
    />
  );
};

export default ShortsPage;