import { Box, Link, Typography } from '@mui/material';
import { Path } from 'constants/index';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { privacyMenuItems } from './constants';

const PrivacyPolicySidebarContent: FC = () => {
  const [activeSection, setActiveSection] = useState<string>('section1');
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === Path.PRIVACY_POLICY) {
      const handleScroll = () => {
        let currentSection = 'section1';

        privacyMenuItems.forEach(({ id }) => {
          const section = document.getElementById(id);
          if (section) {
            const offsetTop = section.getBoundingClientRect().top;
            if (offsetTop <= window.innerHeight * 0.25) {
              currentSection = id;
            }
          }
        });

        setActiveSection(currentSection);
      };

      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [location.pathname]);

  return (
    <Box sx={{height: '80vh', overflow: 'auto'}}>
      {privacyMenuItems.map((item) => (
         <Link
         key={item.id}
         onClick={(e) => {
           e.preventDefault();
           const section = document.getElementById(item.id);
           if (section) {
             section.scrollIntoView({ behavior: 'smooth', block: 'start' });
             localStorage.setItem('activeSection', item.id); // Обновляем localStorage
             setActiveSection(item.id);
           }
         }}
         sx={{
           textDecoration: 'none',
           cursor: 'pointer',
         }}
       >
         <Typography
           variant='h6'
           mb={2}
           sx={{
             color: activeSection === item.id ? 'rgba(121, 255, 228, 1)' : '#fff',
           }}
         >
           {item.label}
         </Typography>
       </Link>
      ))}
    </Box>
  );
};

export default PrivacyPolicySidebarContent;
