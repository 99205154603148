import { configureStore } from '@reduxjs/toolkit';

import { sagaMiddleware } from './middlewares';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
