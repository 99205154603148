import { createSvgIcon } from "@mui/material";

// FIXME: Need extract only document icon, without background
const DownloadIcon = createSvgIcon(
    <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9993 17.6667L4.91602 10.5834L6.89935 8.52925L10.5827 12.2126V0.666748H13.416V12.2126L17.0993 8.52925L19.0827 10.5834L11.9993 17.6667ZM3.49935 23.3334C2.72018 23.3334 2.05317 23.056 1.49831 22.5011C0.943446 21.9463 0.666016 21.2792 0.666016 20.5001V16.2501H3.49935V20.5001H20.4993V16.2501H23.3327V20.5001C23.3327 21.2792 23.0553 21.9463 22.5004 22.5011C21.9455 23.056 21.2785 23.3334 20.4993 23.3334H3.49935Z"
      fill="#79FFE4"
    />
  </svg>,
    "Download",
);

export default DownloadIcon;
