import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import FormCheckbox from 'components/common/FormCheckbox';
import ButtonPrompt from 'components/ui/Auth/ButtonPrompt';
import CustomButton from 'components/ui/Auth/CustomButton';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import PrivacyButton from 'components/ui/Auth/PrivacyButton';
import StyledFormTextField from 'components/ui/StyledFormTextField';
import { Path } from 'constants/index';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useRegistrationValidationSchema } from 'validation/validationSchema';

interface RegistrationStep1Data {
  email: string;
  password: string;
  confirmPassword: string;
  isPrivacy: boolean;
}

interface IRegistrationStep1Props {
  handleClose: () => void;
  onNext: (data: RegistrationStep1Data) => void;
}

const RegistrationStep1: FC<IRegistrationStep1Props> = ({
  handleClose,
  onNext,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const registrationValidationSchema = useRegistrationValidationSchema();

  const defaultValues = {
    email: '',
    password: '',
    confirmPassword: '',
    isPrivacy: false,
  };

  const { control, handleSubmit } = useForm<RegistrationStep1Data>({
    defaultValues,
    resolver: yupResolver(registrationValidationSchema),
  });

  const onSubmit = (data: RegistrationStep1Data) => {
    onNext(data);
  };

  return (
    <>
      <ModalTitle title={t('auth.title.registration')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ mb: '36px' }}>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='email'
              labelKey={`auth.form.email`}
              type='email'
            />
          </Grid>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='password'
              labelKey='auth.form.password'
              type='password'
            />
          </Grid>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='confirmPassword'
              labelKey='auth.form.confirmPassword'
              type='password'
            />
          </Grid>
        </Grid>

        <CustomButton i18nKey='form.button.login' />

        <FormCheckbox
          control={control}
          name='isPrivacy'
          label={
            <Trans
              i18nKey='auth.form.agreeTerms'
              components={{
                privacyPolicy: <PrivacyButton handleClose={handleClose} />,
              }}
            />
          }
          variant='body2'
          errorColor={theme.palette.custom.primary.extraError}
        />

        <Typography variant='h5' mt={2}>
          <Trans
            i18nKey='auth.form.loginPrompt'
            components={{
              login: (
                <ButtonPrompt
                  content={t('form.button.login')}
                  handleClose={handleClose}
                  path={Path.LOGIN}
                />
              ),
            }}
          />
        </Typography>
      </form>
    </>
  );
};

export default RegistrationStep1;
