import CommentIcon from '@mui/icons-material/Comment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { IconButton, Stack } from '@mui/material';
import { IconOpenMusic } from 'assets';
import ActionIconButton from 'components/ui/ActionIconButton';
import CountTypography from 'components/ui/CountTypography';
import VideoInformationPopover from 'components/ui/VideoInformationPopover';
import { ContentType, Path, VideoPopoverType } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getReportReasonStart } from 'store/report';
import { getCommentsStart, getSharingLinkVideoStart } from 'store/video';
import { likeVideoStart } from 'store/video/slices/likeVideoSlice';
import { Video } from 'typings/index';
import { getUserSession } from 'utils/utils';

interface IVideoActionIconsProps {
  handleOpenMusic: () => void;
  isLoggedIn: boolean;
  video: Video;
}

const VideoActionIcons: FC<IVideoActionIconsProps> = ({
  handleOpenMusic,
  isLoggedIn,
  video,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getUserSession();

  const { error: storeLikeError } = useAppSelector(
    (state) => state.video.likeVideo
  );

  const { reportReason } = useAppSelector(
    (state) => state.report.getReportReason
  );

  const [isLiked, setIsLiked] = useState<boolean>(video.isLike);
  const [likeCount, setLikeCount] = useState<number>(video.totalLikes);
  const [isShowComments, setIsShowComments] = useState(false);
  const [isShared, setIsShared] = useState<boolean>(false);
  const [isOpenedInfo, setIsOpenedInfo] = useState<boolean>(false);
  const [prevLikeState, setPrevLikeState] = useState<{
    liked: boolean;
    count: number;
  }>({ liked: isLiked, count: likeCount });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverType, setPopoverType] = useState<VideoPopoverType>(
    VideoPopoverType.COMMENTS
  );

  useEffect(() => {
    if (storeLikeError) {
      setIsLiked(prevLikeState.liked);
      setLikeCount(prevLikeState.count);
    }
  }, [storeLikeError]);

  const handleLikeClick = () => {
    if (!isLoggedIn) {
      navigate(Path.LOGIN);

      return;
    }

    const newLikeStatus = !isLiked;
    setPrevLikeState({ liked: isLiked, count: likeCount });
    setIsLiked(newLikeStatus);
    setLikeCount(newLikeStatus ? likeCount + 1 : likeCount - 1);

    dispatch(
      likeVideoStart({
        videoId: video._id,
        userId: user._id,
      })
    );
  };

  const renderFunc = (type: VideoPopoverType) => {
    switch (true) {
      case VideoPopoverType.COMMENTS === type:
        return setIsShowComments((prev) => !prev);
      case VideoPopoverType.SHARING === type:
        return setIsShared(!isShared);
      case VideoPopoverType.DOTS === type:
        return setIsOpenedInfo(!isOpenedInfo);
    }
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: VideoPopoverType
  ) => {
    if (VideoPopoverType.SHARING !== type && !isLoggedIn) {
      navigate(Path.LOGIN);

      return;
    }

    setPopoverType(type);
    setAnchorEl(event.currentTarget);
    renderFunc(type);

    VideoPopoverType.COMMENTS === type &&
      dispatch(
        getCommentsStart({
          userId: user._id,
          type: ContentType.VIDEO,
          videoId: video._id,
        })
      );

    VideoPopoverType.DOTS === type &&
      reportReason.length === 0 &&
      dispatch(getReportReasonStart());
  };

  const handleActionClose = () => {
    setAnchorEl(null);
    setIsShared(false);
    setIsShowComments(false);
    setIsOpenedInfo(false);
  };

  return (
    <>
      <Stack
        direction='column'
        spacing={2}
        sx={{
          position: 'absolute',
          bottom: '24px',
          right: '24px',
          zIndex: 1,
          alignItems: 'center',
        }}
      >
        {/* Лайк */}
        <Stack direction='column' alignItems='center'>
          <ActionIconButton
            icon={<ThumbUpIcon />}
            isClicked={isLiked}
            onClick={handleLikeClick}
          />
          <CountTypography count={likeCount} />
        </Stack>

        {/* Комментарии */}
        <Stack direction='column' alignItems='center'>
          <ActionIconButton
            icon={<CommentIcon />}
            isClicked={isShowComments}
            onClick={(e) => handleActionClick(e, VideoPopoverType.COMMENTS)}
          />
          <CountTypography count={video.totalComments} />
        </Stack>

        {/* Расшарить */}
        <Stack direction='column' alignItems='center'>
          <ActionIconButton
            icon={<ShareIcon />}
            isClicked={isShared}
            onClick={(e) => handleActionClick(e, VideoPopoverType.SHARING)}
          />
          <CountTypography count={video.shareCount} />
        </Stack>

        {/* Настройки (три точки) */}
        <ActionIconButton
          icon={<MoreVertIcon />}
          isClicked={isOpenedInfo}
          onClick={(e) => handleActionClick(e, VideoPopoverType.DOTS)}
        />

        {/* Музыка */}
        {/* <IconButton sx={{ p: 0 }} onClick={handleOpenMusic}>
          <IconOpenMusic />
        </IconButton> */}
      </Stack>

      <VideoInformationPopover
        anchorEl={anchorEl}
        handleActionClose={handleActionClose}
        popoverType={popoverType}
        video={video}
      />
    </>
  );
};

export default VideoActionIcons;
