import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles';
import FormTextField, {
    FormTextFieldProps,
} from 'components/common/FormTextField/FormTextField';
import { FieldValues, useController } from 'react-hook-form';

interface StyledFormControlProps {
    hasError?: boolean;
    disabled?: boolean;
    sx?: SxProps;
}

const CustomTextField = ({
    disabled,
    sx,
    ...props
}: StyledFormControlProps & TextFieldProps) => {
    const theme = useTheme();

    return (
        <TextField
            {...props}
            disabled={disabled}
            sx={[
                {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '24px',
                        fontFamily: 'Dm sans',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '0.5px',
                        background: 'rgba(33, 33, 33, 0.4)',
                        color: disabled
                            ? `${theme.palette.custom.primary.graphite}45`
                            : '#fff',

                        '& input::placeholder': {
                            color: `${theme.palette.custom.primary.graphite}45`,
                            opacity: 1,
                        },
                        '& input': {
                            p: '16px'
                        },
                        '& fieldset': {
                            border: 'none',
                        },

                        '&:hover fieldset': {
                            border: 'none',
                        },

                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },

                        '&.MuiInputBase-root.MuiInputBase-adornedStart.MuiInputBase-formControl fieldset': {
                            border: 'none',
                        },

                        '&.Mui-error fieldset': {
                            border: 'none',
                        },

                        '&:hover.Mui-error fieldset': {
                            border: 'none',
                        },
                    },
                    '& MuiFormLabel-root':{
                        color: 'rgba(121, 255, 228, 1)'
                    },
                }, ...Array.isArray(sx) ? sx : [sx]]}
        />
    );
};

export default CustomTextField;