import { FC } from 'react';
import { Link } from 'react-router-dom';
import theme from 'styles/theme';

interface IButtonPromptProps {
  content: React.ReactNode;
  handleClose: () => void;
  path: string;
}

const ButtonPrompt: FC<IButtonPromptProps> = ({
  content,
  handleClose,
  path,
}) => {
  return (
    <Link
      to={path}
      onClick={handleClose}
      style={{
        color: theme.palette.custom.primary.blue,
        textDecoration: 'none',
      }}
    >
      {content}
    </Link>
  );
};

export default ButtonPrompt;
