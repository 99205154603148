import { Box, Button, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface ICountdownTimerProps {
  path: string;
}

const CountdownTimer: FC<ICountdownTimerProps> = ({ path }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (seconds === 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);

  const handleRepeatRequest = () => {
    console.log('Повторный запрос отправлен');
    setSeconds(60);
  };

  return (
    <Box sx={{ marginTop: '12px' }}>
      {seconds > 0 ? (
        <Typography variant='h6'>
          <Trans
            i18nKey='auth.form.repeatRequestInformation'
            values={{ seconds }}
            components={{
              seconds: (
                <span style={{ color: theme.palette.custom.primary.blue }}>
                  {seconds} {t('auth.form.seconds')}
                </span>
              ),
            }}
          />
        </Typography>
      ) : (
        <Button
          onClick={handleRepeatRequest}
          sx={{
            color: theme.palette.custom.primary.blue,
            textTransform: 'none',
            p: 0,
            lineHeight: '20px',
          }}
        >
          {t('form.button.repeatRequest')}
        </Button>
      )}
    </Box>
  );
};

export default CountdownTimer;
