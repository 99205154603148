import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyArp-yJUOhND38QlFHcE-FfhMv3fT-WoVY",
  authDomain: "wne-life-prod.firebaseapp.com",
  projectId: "wne-life-prod",
  storageBucket: "wne-life-prod.firebasestorage.app",
  messagingSenderId: "633800040078",
  appId: "1:633800040078:web:8453e372b79628d61c33df",
  measurementId: "G-G7EDDSBBJ0"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
