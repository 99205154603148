import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VideoData } from 'typings/index';

interface UploadVideoState {
  isUploading: boolean;
  error: string | null;
  videoData: VideoData | null;
}

const initialState: UploadVideoState = {
  isUploading: false,
  error: null,
  videoData: null,
};

export const uploadVideoSlice = createSlice({
  name: 'video/upload',
  initialState,
  reducers: {
    uploadVideoStart: (
      state,
      action: PayloadAction<{ videoData: { file: File }; userId: string }>
    ) => {
      state.isUploading = true;
      state.error = null;
      state.videoData = null;
    },
    uploadVideoSuccess: (state, { payload }) => {
      state.isUploading = false;
      state.videoData = payload;
      state.error = null;
    },
    uploadVideoFailure: (state, { payload }) => {
      state.isUploading = false;
      state.error = payload;
    },
  },
});

export const { uploadVideoStart, uploadVideoSuccess, uploadVideoFailure } =
  uploadVideoSlice.actions;

export default uploadVideoSlice.reducer;
