import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomButton from 'components/ui/Auth/CustomButton';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import StyledFormTextField from 'components/ui/StyledFormTextField';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePasswordRecoveryValidationSchema } from 'validation/validationSchema';

interface IRegistrationStep1Props {
  onNext: (data: FormData) => void;
}

interface FormData {
  email: string;
}

const PasswordRecoveryStep1: FC<IRegistrationStep1Props> = ({ onNext }) => {
  const { t } = useTranslation();

  const passwordRecoveryValidationSchema =
    usePasswordRecoveryValidationSchema();

  const defaultValues = {
    email: '',
  };

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(passwordRecoveryValidationSchema),
  });

  const onSubmit = (data: FormData) => {
    onNext(data);
  };

  return (
    <>
      <ModalTitle title={t('auth.title.passwordRecovery')} />

      <Typography variant='h5' mb={3}>
        {t('auth.form.passwordRecovery')}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mb={3}>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='email'
              labelKey={`auth.form.email`}
              type='email'
            />
          </Grid>
        </Grid>

        <CustomButton i18nKey='form.button.send' />
      </form>
    </>
  );
};

export default PasswordRecoveryStep1;
