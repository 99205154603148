import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
  SxProps,
  useTheme,
} from '@mui/material';
import { IconSmile } from 'assets/index';
import AddCancelButtonGroup from 'components/ui/CommentsPanel/AddCancelButtonGroup';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IAddCommentFieldProps {
  commentText: string;
  handleAddComment: () => void;
  handleCancelComment: () => void;
  handleKeyPress: (event: { key: string; preventDefault: () => void }) => void;
  handleSetIsEmojiPickerOpen: () => void;
  image: string;
  setCommentText: (e: any) => void;
  sx?: SxProps;
}

const AddCommentField: FC<IAddCommentFieldProps> = ({
  commentText,
  handleAddComment,
  handleCancelComment,
  handleKeyPress,
  handleSetIsEmojiPickerOpen,
  image,
  setCommentText,
  sx
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      alignItems='start'
      spacing={1}
      sx={{ position: 'sticky', bottom: 0 }}
    >
      <Avatar src={image} alt='User avatar' />
      <Box width='100%'>
        <InputBase
          placeholder={t('information.add_comment')}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          onKeyPress={handleKeyPress}
          multiline
          fullWidth
          sx={{
            borderRadius: '24px',
            padding: '5px 16px',
            color: `${theme.palette.custom.primary.graphite}`,
            fontFamily: 'DM Sans',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            border: `1px solid ${theme.palette.custom.primary.graphite}45`,
            mb: 1.5,
            ...sx
          }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={handleSetIsEmojiPickerOpen}>
                <IconSmile />
              </IconButton>
            </InputAdornment>
          }
        />
        {commentText && (
          <AddCancelButtonGroup
            handleAddComment={handleAddComment}
            handleCancelComment={handleCancelComment}
          />
        )}
      </Box>
    </Stack>
  );
};

export default AddCommentField;
