import { Box, Grid2, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import VideoCameraIcon from 'assets/IconsComponents/VideoCamera';
import ButttonCustom from 'components/ui/Button/Index';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';
import HdIcon from 'assets/IconsComponents/Hd';
import AspectRatioRoundedIcon from '@mui/icons-material/AspectRatioRounded';

interface UploadProps {
  setVideoFile: Dispatch<SetStateAction<File | undefined>>
  setVideoLength: Dispatch<SetStateAction<number>>
}

const Upload: FC<UploadProps> = ({ setVideoFile, setVideoLength }) => {
  const { t } = useTranslation();
  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const videoElement = document.createElement('video');
      const fileURL = URL.createObjectURL(file);

      videoElement.src = fileURL;
      videoElement.onloadedmetadata = () => {
        URL.revokeObjectURL(fileURL);
        const duration = videoElement.duration;
        if (duration > 3600) {
          alert('Превышена длительность видео');
        } else {
          setVideoFile(file);
          setVideoLength(duration)
        }
      };
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'video/*': ['.mp4', '.mkv', '.avi', '.mov'],
    },
    multiple: false,
  });
  const items = [
    {
      title: t('upload.description.title1'),
      desc: t('upload.description.desc1'),
      subDesc: t('upload.description.subDesc1'),
      image: <VideocamOutlinedIcon sx={{ color: "rgba(255, 255, 255, 0.45)", fontSize: '26px' }} />
    },
    {
      title: t('upload.description.title2'),
      desc: t('upload.description.desc2'),
      subDesc: t('upload.description.subDesc2'),
      image: <VideoFileOutlinedIcon sx={{ color: "rgba(255, 255, 255, 0.45)", fontSize: '26px' }} />
    },
    {
      title: t('upload.description.title3'),
      desc: t('upload.description.desc3'),
      subDesc: t('upload.description.subDesc3'),
      image: <HdIcon sx={{ color: "rgba(255, 255, 255, 0.45)", fontSize: '26px' }} />
    },
    {
      title: t('upload.description.title4'),
      desc: t('upload.description.desc4'),
      image: <AspectRatioRoundedIcon sx={{ color: "rgba(255, 255, 255, 0.45)", fontSize: '26px' }} />
    }
  ]
  return (
    <Box sx={{
      display: 'flex',
      height: { xs: 'auto', sm: '100%' },
      width: { xs: 'auto', sm: '100%' },
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#0B0B0B'
    }}>
      <Box sx={{ width: { xs: '100%', sm: '70%' } }}>
        <Box
          {...getRootProps()}
        >
          <Box sx={{
            background: 'rgba(110, 113, 132, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'flex-start', sm: 'center' },
            borderRadius: { xs: 0, sm: "16px" },
            height: { xs: 'auto', sm: '380px' },
            cursor: 'pointer',
            mb: { xs: 0, sm: 2 },
            p: '16px 8px'
          }}>
            <input {...getInputProps()} />
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.45)', fontSize: { xs: '16px', sm: '28px' }, mb: { xs: 2, sm: 0 } }}>
              {t('upload.desc')}
            </Typography>
            <Typography sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.45)', fontSize: '16px', display: { xs: 'none', sm: 'block' }, fontFamily: 'Dm sans' }}>
              {t('upload.drag')}
            </Typography>
            <Box sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
              width: '100px',
              background: 'rgba(110, 113, 132, 0.1)',
              borderRadius: '100px',
              mt: { xs: 2, sm: 0 },
              mb: { xs: 2, sm: 0 }
            }}
            >
              <VideoCameraIcon sx={{ fontSize: '52px' }} />
            </Box>
            <ButttonCustom
              sx={{ mt: 2, display: { xs: 'none', sm: 'block' } }}
              title={t('upload.btn')}
            />
            <Box sx={{ display: { xs: 'block', sm: 'none' }, pb: 1, background: { xs: 'inherit', sm: '#030303' } }}>
              <Stack gap={2} mb={3}>
                {items.map((item) => (
                  <Box key={item.title} sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                    {item.image}
                    <Box>
                      <Typography variant="h6" sx={{ color: '#fff', lineHeight: '22px', mb: '4px', fontFamily: 'Dm sans', fontWeight: 'bold' }}>{item.title}</Typography>
                      <Typography sx={{ color: '#fff', fontSize: '12px', lineHeight: '16px', fontFamily: 'Dm sans' }}>{item.desc}</Typography>
                      <Typography sx={{ color: '#fff', fontSize: '12px', lineHeight: '16px', fontFamily: 'Dm sans' }}>{item.subDesc}</Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
              <ButttonCustom
                fullWidth
                title={t('upload.btn')}
              />
            </Box>
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {items.map((item) => (
            <Grid key={item.title} size={{ sm: 6, lg: 3 }} >
              <Box sx={{ p: '20px 24px', background: { xs: 'inherit', sm: '#030303' }, borderRadius: '16px', height: '100%' }}>
                {item.image}
                <Typography variant="h6" sx={{ color: '#fff', lineHeight: '22px', mb: '4px', fontFamily: 'Dm sans', fontWeight: 'bold' }}>{item.title}</Typography>
                <Typography sx={{ color: '#fff', fontSize: '12px', lineHeight: '16px', fontFamily: 'Dm sans' }}>{item.desc}</Typography>
                <Typography sx={{ color: '#fff', fontSize: '12px', lineHeight: '16px', fontFamily: 'Dm sans' }}>{item.subDesc}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Upload;
