import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'typings/index';

interface GetProfileState {
  user: User | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: GetProfileState = {
  user: null,
  isLoading: false,
  error: null,
};

const getProfileSlice = createSlice({
  name: 'get/profile',
  initialState,
  reducers: {
    getProfileStart: (state, action: PayloadAction<{ userId: string }>) => {
      state.error = null;
      state.isLoading = true;
    },
    getProfileSuccess: (state, action: PayloadAction<{ result: User }>) => {
      const { result } = action.payload;

      state.user = result;
      state.error = null;
      state.isLoading = false;
    },
    getProfileFailure: (state, action: PayloadAction<{ error: string }>) => {
      state.error = action.payload.error;
      state.isLoading = false;
      state.user = null;
    },
  },
});

export const { getProfileStart, getProfileSuccess, getProfileFailure } =
  getProfileSlice.actions;

export default getProfileSlice.reducer;
