import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface streamRoomState {
  roomId: string;
}

const initialState: streamRoomState = {
  roomId: '',
};

const streamRoomSlice = createSlice({
  name: 'set/stream/room',
  initialState,
  reducers: {
    setRoomId(state, action: PayloadAction<string>) {
      state.roomId = action.payload;
    },
    clearRoom(state) {
      state.roomId = '';
    },
  },
});

export const { clearRoom, setRoomId } = streamRoomSlice.actions;
export default streamRoomSlice.reducer;
