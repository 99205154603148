import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

const PrivacyPolicyViewer: FC = () => {

  useEffect(() => {
    const handleScroll = () => {
      document.querySelectorAll('.titlePrivacy').forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight * 0.25) {
          localStorage.setItem('activeSection', el.id); // Сохраняем в localStorage
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Box p={2} sx={{ color: '#fff', width: { xs: '100%', sm: '80%' }, m: { xs: '0 auto', sm: '100px auto 0' } }}>
      <Typography sx={{ fontSize: '28px' }}>Privacy policy</Typography>
      <Typography sx={{ fontSize: '12px', fontFamily: 'Dm sans' }}>Last updated August 31, 2024</Typography>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        This Privacy Notice for __________ ('we', 'us', or 'our'), describes how and why we might access, collect, store, use, and/or share ('process') your personal information when you use our services ('Services'), including when you:
        <ul>
          <li style={{ margin: '15px 0' }}>
            Download and use our mobile application (AFreeTest), or any other application of ours that links to this Privacy Notice
          </li>
          <li style={{ margin: '15px 0' }}>
            Use AFreeTest. Social Network “AFreeTest”: This platform serves as the core of the ecosystem, providing users with comprehensive tools for interacting with photo and video content. Users can not only create, edit, and share various types of media but also actively participate in the community through likes, comments, and subscriptions. The “AFreeTest” social network integrates all key features, ensuring convenience and an intuitive interface, making the content interaction process natural and engaging. The platform also supports integration with other ecosystem services, providing a seamless user experience. Messenger “AFreeTest”: The “AFreeTest” Messenger is an integral part of the ecosystem, offering a universal solution for all types of communication. It features personal messaging, group chats, as well as audio and video calls, making it a convenient tool for staying connected in any circumstances. The messenger is closely integrated with other services in the “AFreeTest” ecosystem, allowing for instant content sharing and easy switching between communication and interaction with media materials. This creates a unified digital space where users can easily interact with each other and with content.
          </li>
          <li style={{ margin: '15px 0' }}>
            Engage with us in other related ways, including any sales, marketing, or events
          </li>
        </ul>
        Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at afreevid@gmail.com.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section1'>Summary of key points</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
        <br /> <br />
        What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.
        <br /> <br />
        Do we process any sensitive personal information? Some of the information may be considered 'special' or 'sensitive' in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.
        <br /> <br />
        Do we collect any information from third parties? We do not collect any information from third parties.
        <br /> <br />
        How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.
        <br /> <br />
        In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.
        <br /> <br />
        How do we keep your information safe? We have adequate organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.
        <br /> <br />
        What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.
        <br /> <br />
        How do you exercise your rights? The easiest way to exercise your rights is by visiting afreevid@gmail.com, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
        <br /> <br />
        Want to learn more about what we do with any information we collect? Review the Privacy Notice in full.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section2'>
        What information do we collect?
      </p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        Personal information you disclose to us
        <br /> <br />
        In Short: We collect personal information that you provide to us.
        <br /> <br />
        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
        <br /> <br />
        Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
        <br /> <br />
        <ul>
          <li style={{ margin: '15px 0' }}>
            email addresses
          </li>
          <li style={{ margin: '15px 0' }}>
            usernames
          </li>
          <li style={{ margin: '15px 0' }}>
            passwords
          </li>
          <li style={{ margin: '15px 0' }}>
            names
          </li>
        </ul>
        Sensitive Information. We do not process sensitive information.
        <br /> <br />
        Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, X, or other social media account. If you choose to register in this way, we will collect certain profile information about you from the social media provider, as described in the section called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.
        <br /> <br />
        Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
        <br /> <br />
        <ul>
          <li style={{ margin: '15px 0' }}>
            Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
          </li>
          <li style={{ margin: '15px 0' }}>
            Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
          </li>
        </ul>
        This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
        <br /> <br />
        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
        <br /> <br />
        Information automatically collected
        <br /> <br />
        In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
        <br /> <br />
        We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
        <br /> <br />
        Like many businesses, we also collect information through cookies and similar technologies.
        <br /> <br />
        The information we collect includes:
        <br /> <br />
        <ul>
          <li style={{ margin: '15px 0' }}>
            Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
          </li>
          <li style={{ margin: '15px 0' }}>
            Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
          </li>
          <li style={{ margin: '15px 0' }}>
            Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
          </li>
        </ul>
        Google API
        <br /> <br />
        Our use of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section3'>
        How do we process your information?
      </p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</span>
        <br /> <br />
        We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
        <ul>
          <li style={{ margin: '15px 0' }}>
            To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.
          </li>
          <li style={{ margin: '15px 0' }}>
            To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.
          </li>
          <li style={{ margin: '15px 0' }}>
            To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
          </li>
          <li style={{ margin: '15px 0' }}>
            To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.
          </li>
          <li style={{ margin: '15px 0' }}>
            To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.
          </li>
          <li style={{ margin: '15px 0' }}>
            To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
          </li>
          <li style={{ margin: '15px 0' }}>
            To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.
          </li>
          <li style={{ margin: '15px 0' }}>
            To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
          </li>
        </ul>
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section4'>What legal bases do we rely on to process your personal information?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</span>
        <br /> <br />
        If you are located in the EU or UK, this section applies to you.
        <br /> <br />
        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
        <ul>
          <li style={{ margin: '15px 0' }}>
            Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about <span style={{ color: 'rgba(121, 255, 228, 1)' }}>withdrawing your consent.</span>
          </li>
          <li style={{ margin: '15px 0' }}>
            Performance of a Contract. We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
          </li>
          <li style={{ margin: '15px 0' }}>
            Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to
          </li>
          <li style={{ margin: '15px 0' }}>
            Support our marketing activities
          </li>
          <li style={{ margin: '15px 0' }}>
            Diagnose problems and/or prevent fraudulent activities
          </li>
          <li style={{ margin: '15px 0' }}>
            Understand how our users use our products and services so we can improve user experience
          </li>
          <li style={{ margin: '15px 0' }}>
            Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
          </li>
          <li style={{ margin: '15px 0' }}>
            Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
          </li>
        </ul>
        In legal terms, we are generally the 'data controller' under European data protection laws of the personal information described in this Privacy Notice, since we determine the means and/or purposes of the data processing we perform. This Privacy Notice does not apply to the personal information we process as a 'data processor' on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing agreement is the 'data controller' responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers' privacy practices, you should read their privacy policies and direct any questions you have to them.
        <br /> <br />
        If you are located in Canada, this section applies to you.
        <br /> <br />
        We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can <span style={{ color: 'rgba(121, 255, 228, 1)' }}>withdraw your consent</span> at any time.
        <br /> <br />
        In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
        <ul>
          <li style={{ margin: '15px 0' }}>
            If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
          </li>
          <li style={{ margin: '15px 0' }}>
            For investigations and fraud detection and prevention
          </li>
          <li style={{ margin: '15px 0' }}>
            For business transactions provided certain conditions are met
          </li>
          <li style={{ margin: '15px 0' }}>
            If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
          </li>
          <li style={{ margin: '15px 0' }}>
            For identifying injured, ill, or deceased persons and communicating with next of kin
          </li>
          <li style={{ margin: '15px 0' }}>
            If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
          </li>
          <li style={{ margin: '15px 0' }}>
            If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
          </li>
          <li style={{ margin: '15px 0' }}>
            If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
          </li>
          <li style={{ margin: '15px 0' }}>
            If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
          </li>
          <li style={{ margin: '15px 0' }}>
            If the collection is solely for journalistic, artistic, or literary purposes
          </li>
          <li style={{ margin: '15px 0' }}>
            If the information is publicly available and is specified by the regulations
          </li>
        </ul>
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section5'>When and with whom do we share your personal information?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: We may share information in specific situations described in this section and/or with the following third parties.</span>
        <br /> <br />
        We may need to share your personal information in the following situations:
        <ul>
          <li style={{ margin: '15px 0' }}>
            Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
          </li>
          <li style={{ margin: '15px 0' }}>
            Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
          </li>
        </ul>
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section6'>Do we use cookies and other tracking technologies?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: We may use cookies and other tracking technologies to collect and store your information.</span>
        <br /> <br />
        We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
        <br /> <br />
        We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
        <br /> <br />
        To the extent these online tracking technologies are deemed to be a 'sale'/'sharing' (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?'</span>
        <br /> <br />
        Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
        <br /> <br />
        Google Analytics
        <br /> <br />
        We may share your information with Google Analytics to track and analyse the use of the Services. The Google Analytics Advertising Features that we may use include: Google Analytics Demographics and Interests Reporting and Remarketing with Google Analytics. To opt out of being tracked by Google Analytics across the Services, visit <span style={{ color: 'rgba(121, 255, 228, 1)' }}>https://tools.google.com/dlpage/gaoptout</span>. You can opt out of Google Analytics Advertising Features through Ads Settings and <span style={{ color: 'rgba(121, 255, 228, 1)' }}>Ad Settings</span> for mobile apps. Other opt out means include <span style={{ color: 'rgba(121, 255, 228, 1)' }}>http://optout.networkadvertising.org/</span> and <span style={{ color: 'rgba(121, 255, 228, 1)' }}>http://www.networkadvertising.org/mobile-choice</span>. For more information on the privacy practices of Google, please visit the <span style={{ color: 'rgba(121, 255, 228, 1)' }}>Google Privacy & Terms page</span>.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section7'>How do we handle your social logins?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</span>
        <br /> <br />
        Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
        <br /> <br />
        We will use the information we receive only for the purposes that are described in this Privacy Notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section8'>How long do we keep your information?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}> In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this Privacy Notice unless otherwise required by law.</span>
        <br /> <br />
        We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than three (3) months past the termination of the user's account.
        <br /> <br />
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section9'>How do we keep your information safe?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</span>
        <br /> <br />
        We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section10'>Do we collect information from minors?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: We do not knowingly collect data from or market to children under 18 years of age.</span>
        <br /> <br />
        We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at afreevid@gmail.com.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section11'>
        What are your privacy rights?
      </p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</span>
        <br /> <br />
        In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</span> below.
        <br /> <br />
        We will consider and act upon any request in accordance with applicable data protection laws.
        <br /> <br />
        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <span style={{ color: 'rgba(121, 255, 228, 1)' }}>Member State data protection authority or UK data protection authority</span>.
        <br /> <br />
        If you are located in Switzerland, you may contact the <span style={{ color: 'rgba(121, 255, 228, 1)' }}>Federal Data Protection and Information Commissioner</span>.
        <br /> <br />
        Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</span> below.
        <br /> <br />
        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
        <br /> <br />
        Account Information
        <br /> <br />
        If you would at any time like to review or change the information in your account or terminate your account, you can:
        <ul>
          <li style={{ margin: '15px 0' }}>
            Log in to your account settings and update your user account.
          </li>
        </ul>
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
        <br /> <br />
        Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
        <br /> <br />
        If you have questions or comments about your privacy rights, you may email us at afreevid@gmail.com.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section12'>Controls for do-not-track features</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
        <br /> <br />
        California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognising or honouring DNT signals, we do not respond to them at this time.
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section13'>Do United States residents have specific privacy rights?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.</span>
        <br /> <br />
        Categories of Personal Information We Collect
        <br /> <br />
        We have collected the following categories of personal information in the past twelve (12) months:
        <br /> <br />
        <table className="custom-table" style={{ color: '#fff', width: '100%' }}>
          <thead>
            <tr>
              <th>Category</th>
              <th style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Examples</th>
              <th style={{ textAlign: 'center' }}>Collected</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A. Identifiers</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
              <td style={{ textAlign: 'center' }}>YES</td>
            </tr>
            <tr>
              <td>B. Personal information as defined in the California Customer Records statute</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Name, contact information, education, employment, employment history, and financial information</td>
              <td style={{ textAlign: 'center' }}>YES</td>
            </tr>
            <tr>
              <td>C. Protected classification characteristics under state or federal law</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</td>
              <td style={{ textAlign: 'center' }}>YES</td>
            </tr>
            <tr>
              <td>D. Commercial information</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Transaction information, purchase history, financial details, and payment information</td>
              <td style={{ textAlign: 'center' }}>NO</td>
            </tr>
            <tr>
              <td>E. Biometric information</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Fingerprints and voiceprints</td>
              <td style={{ textAlign: 'center' }}>NO</td>
            </tr>
            <tr>
              <td>F. Internet or other similar network activity</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
              <td style={{ textAlign: 'center' }}>YES</td>
            </tr>
            <tr>
              <td>G. Geolocation data</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Device location</td>
              <td style={{ textAlign: 'center' }}>YES</td>
            </tr>
            <tr>
              <td>H. Audio, electronic, sensory, or similar information</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Images and audio, video or call recordings created in connection with our business activities</td>
              <td style={{ textAlign: 'center' }}>YES</td>
            </tr>
            <tr>
              <td>I. Professional or employment-related information</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
              <td style={{ textAlign: 'center' }}>NO</td>
            </tr>
            <tr>
              <td>J. Education Information</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Student records and directory information</td>
              <td style={{ textAlign: 'center' }}>NO</td>
            </tr>
            <tr>
              <td>K. Inferences drawn from collected personal information</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
              <td style={{ textAlign: 'center' }}>NO</td>
            </tr>
            <tr>
              <td>L. Sensitive personal Information</td>
              <td style={{ borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }}></td>
              <td style={{ textAlign: 'center' }}>NO</td>
            </tr>
          </tbody>
        </table>
        <br /> <br />
        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
        <ul>
          <li style={{ margin: '15px 0' }}>
            Receiving help through our customer support channels;
          </li>
          <li style={{ margin: '15px 0' }}>
            Participation in customer surveys or contests; and
          </li>
          <li style={{ margin: '15px 0' }}>
            Facilitation in the delivery of our Services and to respond to your inquiries.
          </li>
        </ul>
        We will use and retain the collected personal information as needed to provide the Services or for:
        <ul>
          <li style={{ margin: '15px 0' }}>
            Category A - As long as the user has an account with us, and 3 month after.
          </li>
          <li style={{ margin: '15px 0' }}>
            Category B - As long as the user has an account with us, and 3 month after.
          </li>
          <li style={{ margin: '15px 0' }}>
            Category C - As long as the user has an account with us, and 3 month after.
          </li>
          <li style={{ margin: '15px 0' }}>
            Category F - As long as the user has an account with us, and 3 month after.
          </li>
          <li style={{ margin: '15px 0' }}>
            Category G - As long as the user has an account with us, and 3 month after.
          </li>
        </ul>
        Sources of Personal Information
        <br /> <br />
        Learn more about the sources of personal information we collect in <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'WHAT INFORMATION DO WE COLLECT?'</span>
        <br /> <br />
        How We Use and Share Personal Information
        <br /> <br />
        Learn about how we use your personal information in the section, <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'HOW DO WE PROCESS YOUR INFORMATION?'</span>
        <br /> <br />
        Will your information be shared with anyone else?
        <br /> <br />
        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'</span>
        <br /> <br />
        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling' of your personal information.
        <br /> <br />
        We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
        <br /> <br />
        Your Rights
        <br /> <br />
        You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
        <ul>
          <li style={{ margin: '15px 0' }}>
            Right to know whether or not we are processing your personal data
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to access your personal data
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to correct inaccuracies in your personal data
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to request the deletion of your personal data
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to obtain a copy of the personal data you previously shared with us
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to non-discrimination for exercising your rights
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to opt out of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ('profiling')
          </li>
        </ul>
        Depending upon the state where you live, you may also have the following rights:
        <ul>
          <li style={{ margin: '15px 0' }}>
            Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon’s privacy law
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)
          </li>
          <li style={{ margin: '15px 0' }}>
            Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)
          </li>
        </ul>
        How to Exercise Your Rights
        <br /> <br />
        To exercise these rights, you can contact us by visiting afreevid@gmail.com, by emailing us at afreevid@gmail.com, or by referring to the contact details at the bottom of this document.
        <br /> <br />
        Under certain US state data protection laws, you can designate an authorised agent to make a request on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with applicable laws.
        <br /> <br />
        Request Verification
        <br /> <br />
        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
        <br /> <br />
        If you submit the request through an authorised agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
        <br /> <br />
        Appeals
        <br /> <br />
        Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at afreevid@gmail.com. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
        <br /> <br />
        California 'Shine The Light' Law
        <br /> <br />
        California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</span>
      </p>
      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section14'>Do other regions have specific privacy rights?</p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: You may have additional rights based on the country you reside in.</span>
        <br /> <br />
        Australia and New Zealand
        <br /> <br />
        We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).
        <br /> <br />
        This Privacy Notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.
        <br /> <br />
        If you do not wish to provide the personal information necessary to fulfil their applicable purpose, it may affect our ability to provide our services, in particular:
        <ul>
          <li style={{ margin: '15px 0' }}>
            offer you the products or services that you want
          </li>
          <li style={{ margin: '15px 0' }}>
            respond to or help with your requests
          </li>
          <li style={{ margin: '15px 0' }}>
            manage your account with us
          </li>
          <li style={{ margin: '15px 0' }}>
            confirm your identity and protect your account
          </li>
        </ul>
        At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'</span>
        <br /> <br />
        If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy Principles to the <span style={{ color: 'rgba(121, 255, 228, 1)' }}>Office of the Australian Information Commissioner</span> and a breach of New Zealand's Privacy Principles to the <span style={{ color: 'rgba(121, 255, 228, 1)' }}>Office of New Zealand Privacy Commissioner.</span>
        <br /> <br />
        Republic of South Africa
        <br /> <br />
        At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section <span style={{ color: 'rgba(121, 255, 228, 1)' }}>'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'</span>
        <br /> <br />
        If you are unsatisfied with the manner in which we address any complaint with regard to our processing of personal information, you can contact the office of the regulator, the details of which are:
        <br /> <br />
        <span style={{ color: 'rgba(121, 255, 228, 1)' }}>The Information Regulator (South Africa)</span><br />
        General enquiries: <span style={{ color: 'rgba(121, 255, 228, 1)' }}>enquiries@inforegulator.org.za</span><br />
        Complaints (complete POPIA/PAIA form 5): <span style={{ color: 'rgba(121, 255, 228, 1)' }}>PAIAComplaints@inforegulator.org.za & POPIAComplaints@inforegulator.org.za</span>
      </p>

      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section15'>
        Do we make updates to this notice?
      </p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        <span style={{ color: 'rgba(110, 113, 132, 1)' }}>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</span>
        <br /> <br />
        We may update this Privacy Notice from time to time. The updated version will be indicated by an updated 'Revised' date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
      </p>
      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section15'>
        How can you contact us about this notice?
      </p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        If you have questions or comments about this notice, you may email us at afreevid@gmail.com or contact us by post at:
        <br /> <br />
        The John Green Build<br />
        27 Bolton Road Flat 2<br />
        Bradford BD1 4DR<br />
        England
        <br /> <br />
        If you are a resident in the European Economic Area or Switzerland, we are the 'data controller' of your personal information. We have appointed Andrejs Atmorskis to be our representative in the EEA and Switzerland. You can contact them directly regarding our processing of your information, by email at afreevid@gmail.com, by phone at +37124796397, or by post to:
        <br /> <br />
        The John Green Building<br />
        27 Bolton Road Flat 2<br />
        Bradford, West Yorkshire BD1 4DR<br />
        United Kingdom
        <br /> <br />
        If you are a resident in the United Kingdom, we are the 'data controller' of your personal information. We have appointed Andrejs Atmorskis to be our representative in the UK. You can contact them directly regarding our processing of your information, by email at afreevid@gmail.com, by phone at +37124796397, or by post to:
        <br /> <br />
        The John Green Build<br />
        27 Bolton Road Flat 2<br />
        Bradford BD1 4DR<br />
        England
      </p>
      <p style={{ fontSize: '28px' }} className='titlePrivacy' id='section16'>
        How can you review, update, or delete the data we collect from you?
      </p>
      <p style={{ fontFamily: 'Dm sans', fontSize: '12px' }}>
        Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please visit: afreevid@gmail.com.
      </p>
    </Box >
  );
};

export default PrivacyPolicyViewer;
