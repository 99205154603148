import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingVideoProgress from './components/LoadingVideoProgress';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUploadVideoSchema } from 'validation/validationSchema';
import CustomTextField from 'components/ui/TextField';
import ScrollContainer from 'components/ui/ScrollContainer';
import AddIcon from '@mui/icons-material/Add';
import ButttonCustom from 'components/ui/Button/Index';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DialogSuccess from '../DialogSuccess';
import cfg from 'config/index';
import { uploadFileToAPI } from 'api/index';
import { LoaderBackdrop } from 'components/ui/Loader';


interface UploadProps {
  file: File | undefined;
  setVideoFile: Dispatch<SetStateAction<File | undefined>>;
  videoLength: number;
}

const UploadingVideo: FC<UploadProps> = ({ file, setVideoFile, videoLength }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [playerReady, setPlayerReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [frames, setFrames] = useState<string[]>([]);
  const [selectedFrame, setSelectedFrame] = useState<string>();
  const [open, setOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const playerRef = useRef<ReactPlayer | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const uploadVideoSchema = useUploadVideoSchema();

  const { control, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(uploadVideoSchema),
    shouldUnregister: true,
    defaultValues: {
      headline: '',
      hashtags: ['asdasd'],
      cover: [],
      file: undefined
    }
  });

  const sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    beforeChange: (_current: number, next: number) => setSlideIndex(next)
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };

  const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || undefined;
    const newCovers = [...frames]
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        newCovers.push(reader.result as string)
        setFrames(newCovers)
        setValue('cover', newCovers)
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append('video', file as File);
    formData.append('videoTime', Math.floor(videoLength).toString());
    formData.append('caption', data.headline);

    uploadFileToAPI(null, formData, `${cfg.API_URL}/client/video/uploadvideo`)
      .then(() => {
        setIsLoading(false)
        setOpen(true);
      })
  });


  const extractFrames = async () => {
    if (!playerRef.current || !canvasRef.current || !videoUrl) return;

    const videoElement = playerRef.current.getInternalPlayer() as HTMLVideoElement;

    if (!videoElement) {
      console.error("Video element is not available.");
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    await new Promise<void>((resolve) => {
      if (videoElement.readyState >= 1) {
        resolve();
      } else {
        videoElement.onloadedmetadata = () => resolve();
      }
    });

    const duration = videoElement.duration;
    const frameCount = 5;
    const timeInterval = duration / (frameCount + 1);
    const extractedFrames: string[] = [];

    for (let i = 1; i <= frameCount; i++) {
      const time = i * timeInterval;
      await new Promise<void>((resolve) => {
        videoElement.currentTime = time;
        videoElement.onseeked = () => {
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          extractedFrames.push(canvas.toDataURL('image/jpeg'));
          resolve();
        };
      });
    }

    setFrames(extractedFrames);
    setValue('cover', extractedFrames)
  };

  useEffect(() => {
    if (file) {
      setValue('headline', file.name)
      setValue('file', file)
      setVideoUrl(URL.createObjectURL(file))
    }
  }, [file])

  useEffect(() => {
    if (playerReady && videoUrl) {
      extractFrames();
    }
  }, [playerReady, videoUrl]);

  return (
    <LoaderBackdrop isLoading={isLoading}>
      <Box sx={{ p: { xs: 0, sm: '40px 0px 20px' }, width: { xs: '100%', sm: '90%', lg: '80%', xl: '75%' }, m: '0 auto' }}>
        <Typography variant="h2" sx={{ color: '#fff', display: { xs: 'none', sm: 'block' } }}>
          {t('upload.uploadingVideo.title')}
        </Typography>
        <Typography variant="h5" sx={{ color: theme.palette.custom.primary.graphite, mt: 1, display: { xs: 'none', sm: 'block' } }}>
          {t('upload.uploadingVideo.desc')}
        </Typography>
        <Box sx={{ borderRadius: { xs: 0, sm: '16px' }, background: 'rgba(19, 19, 21, 1)', p: { xs: 2, sm: 3 }, mt: { xs: 0, sm: 4 } }}>
          <Grid container spacing={{ xs: 1, sm: 4 }}>
            <Grid size={{ xs: 5.5, sm: 4 }}>
              {videoUrl ? <Box sx={{
                background: 'rgba(110, 113, 132, 0.1)',
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: { xs: 0, sm: 4 }
              }}>
                <ReactPlayer
                  url={videoUrl}
                  playing={true}
                  width="100%"
                  height="100%"
                  onReady={() => setPlayerReady(true)}
                  muted
                  loop
                />
                <ReactPlayer
                  url={videoUrl}
                  ref={playerRef}
                  playing={true}
                  width="100%"
                  height="100%"
                  onReady={() => setPlayerReady(true)}
                  muted
                  style={{ display: 'none' }}
                />
                <canvas
                  ref={canvasRef}
                  style={{ display: 'none' }}
                  width={320}
                  height={180}
                /></Box> :
                <LoadingVideoProgress file={file} setVideoFile={setVideoFile} />
              }
            </Grid>
            <Grid size={{ xs: 6.5, sm: 8 }}>
              <Stack gap={4}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'flex-end' }}>
                    <Typography variant="h5" sx={{ color: '#fff', mt: 1 }}>
                      {t('upload.uploadingVideo.headline')}
                    </Typography>
                    <Typography sx={{ color: theme.palette.custom.primary.graphite, mt: 1, fontSize: '12px', fontFamily: 'Dm sans' }}>
                      {watch('headline').length}/150
                    </Typography>
                  </Box>
                  <Controller
                    control={control}
                    name="headline"
                    render={({ field, fieldState }) => (
                      <CustomTextField
                        fullWidth
                        {...field}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                        placeholder='Video Title'
                        inputProps={{ maxLength: 150 }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& input': {
                              p: '10px 16px'
                            },
                            borderRadius: '12px',
                            background: '#030303',
                            color: 'rgba(110, 113, 132, 0.45)'
                          },
                          display: { xs: 'none', sm: 'block' },

                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="headline"
                    render={({ field, fieldState }) => (
                      <CustomTextField
                        fullWidth
                        // {...field}
                        placeholder='Video Title'
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                        multiline
                        minRows={4}
                        inputProps={{ maxLength: 150 }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& input': {
                              p: '10px 16px'
                            },
                            borderRadius: '12px',
                            background: '#030303',
                            color: 'rgba(255, 255, 255, 0.45)'
                          },
                          display: { xs: 'block', sm: 'none' }
                        }}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Typography variant="h5" sx={{ color: '#fff', mb: 1 }}>
                    {t('upload.uploadingVideo.hashtags')}
                  </Typography>
                  <Controller
                    control={control}
                    name="hashtags"
                    render={({ field, fieldState }) => (
                      <CustomTextField
                        fullWidth
                        // {...field}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                        inputProps={{ maxLength: 150 }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& input': {
                              p: '10px 16px',
                              background: '#030303',
                              color: 'rgba(121, 255, 228, 1)',
                              borderRadius: '12px',
                            },
                            borderRadius: '12px'
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Typography variant="h5" sx={{ color: '#fff', mb: 1 }}>
                    {t('upload.uploadingVideo.cover')}
                  </Typography>
                  <Box sx={{ border: '1px solid rgba(110, 113, 132, 0.1)', borderRadius: '12px', p: 1, background: 'rgba(3, 3, 3, 1)' }}>
                    <ScrollContainer sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center"
                    }}>
                      {frames.map((item, i) => (
                        <Box onClick={()=> setSelectedFrame(item)} key={i} sx={{position: 'relative'}}>
                          <Box
                            component='img'
                            src={item as string}
                            sx={{ minWidth: '116px', maxWidth: '116px', height: '206px', borderRadius: '12px', objectFit: 'cover' }}
                          />
                          {selectedFrame === item && <Box sx={{
                            background: 'rgba(57, 90, 252, 0.35)',
                            width: '100%',
                            height: '206px',
                            borderRadius: '12px',
                            position: 'absolute',
                            top: 0,
                            left: 0
                          }}
                          />}
                        </Box>
                      ))}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          cursor: 'pointer',
                          minWidth: '116px',
                          height: '206px',
                          borderRadius: '12px',
                          border: '1px solid rgba(110, 113, 132, 0.45)',
                          m: '0 1px 0 0'
                        }}
                        component="label"
                      >
                        <Box sx={{
                          color: '#fff',
                          background: 'rgba(110, 113, 132, 0.1)',
                          minWidth: '10px',
                          p: '12px 12px 7px',
                          borderRadius: '12px',
                          '&:hover': {
                            background: theme.palette.custom.primary.blue,
                            color: '#fff'
                          }
                        }}>
                          <AddIcon />
                        </Box>
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={uploadImage}
                        />
                      </Box>
                    </ScrollContainer>
                  </Box>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={3} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Grid size={{ xs: 4 }} />
            <Grid size={{ xs: 8 }} sx={{ display: 'flex', gap: 2 }}>
              <ButttonCustom
                onClick={() => setVideoFile(undefined)}
                sx={{ background: "rgba(110, 113, 132, 0.2)", color: 'rgba(110, 113, 132, 1)', boxShadow: 'none' }}
                title={t('upload.uploadingVideo.delete')}
              />
              <ButttonCustom
                onClick={onSubmit}
                disabled={!watch('headline') || watch('hashtags')?.length < 1 || watch('cover')?.length < 1}
                title={t('upload.uploadingVideo.download')} />
            </Grid>
          </Grid>
          <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="h5" sx={{ color: '#fff' }}>
                {t('upload.uploadingVideo.changeCover')}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton
                  onClick={handlePrev}
                  sx={{
                    background: 'rgba(121, 255, 228, 1)',
                    borderRadius: '8px',
                    position: 'relative',
                    width: '32px',
                    height: '32px',
                    color: '#fff',
                    '&:hover': {
                      background: 'rgba(121, 255, 228, 1)'
                    }
                  }}
                >
                  <ArrowBackIosIcon sx={{
                    fontSize: '16px',
                    position: 'absolute',
                    top: '8px',
                    right: '5px'
                  }} />
                </IconButton>
                <IconButton
                  onClick={handleNext}
                  sx={{
                    background: 'rgba(121, 255, 228, 1)',
                    borderRadius: '8px',
                    position: 'relative',
                    width: '32px',
                    height: '32px',
                    color: '#fff',
                    '&:hover': {
                      background: 'rgba(121, 255, 228, 1)'
                    }
                  }}
                >
                  <ArrowBackIosIcon sx={{
                    transform: 'rotate(180deg)',
                    fontSize: '16px',
                    position: 'absolute',
                    top: '8px',
                    left: '5px'
                  }} />
                </IconButton>
              </Box>
            </Box>
            <Slider ref={sliderRef} {...settings}>
              <Box sx={{ p: '4px' }}>
                <Box
                  sx={{
                    display: 'flex !important',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    height: '176px',
                    borderRadius: '12px',
                    border: '1px solid rgba(110, 113, 132, 0.45)'
                  }}
                  component="label"
                >
                  <Box sx={{
                    color: '#fff',
                    background: 'rgba(110, 113, 132, 0.1)',
                    minWidth: '10px',
                    p: '12px 12px 7px',
                    borderRadius: '12px',
                    '&:hover': {
                      background: theme.palette.custom.primary.blue,
                      color: '#fff'
                    }
                  }}>
                    <AddIcon />
                  </Box>
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={uploadImage}
                  />
                </Box>
              </Box>
              {frames.map((item, index) => (
                <Box key={index} onClick={() => setSelectedFrame(item)} sx={{
                  p: '4px',
                  display: "flex",
                }}>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      component='img'
                      src={item}
                      width='100%'
                      height="176px"
                      sx={{
                        borderRadius: '8px',
                        objectFit: 'cover',
                      }}
                    />
                    {selectedFrame === item && <Box sx={{
                      background: 'rgba(57, 90, 252, 0.35)',
                      width: '100%',
                      height: '176px',
                      borderRadius: '8px',
                      position: 'absolute',
                      top: 0,
                      left: 0
                    }}
                    />}
                  </Box>
                </Box>
              ))}
            </Slider>
            <Box mt={1} pb={3}>
              <Typography variant="h5" sx={{ color: '#fff',  mb: 1 }}>
                {t('upload.uploadingVideo.hashtags')}
              </Typography>
              <Controller
                control={control}
                name="hashtags"
                render={({ field, fieldState }) => (
                  <CustomTextField
                    fullWidth
                    // {...field}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    inputProps={{ maxLength: 150 }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& input': {
                          p: '10px 16px'
                        },
                        borderRadius: '12px'
                      },
                    }}
                  />
                )}
              />
              <ButttonCustom
                onClick={onSubmit}
                disabled={!watch('headline') || watch('hashtags')?.length < 1 || watch('cover')?.length < 1}
                title={t('upload.uploadingVideo.download')}
                sx={{ mt: 3 }}
                fullWidth />
            </Box>
          </Box>
        </Box>
        <DialogSuccess open={open} onClose={() => setOpen(false)} name={file?.name} />
      </Box>
    </LoaderBackdrop>
  );
};

export default UploadingVideo;
