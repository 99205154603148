import { getFromAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getProfileFailure,
  getProfileStart,
  getProfileSuccess,
} from 'store/user/slices/getProfileSlice';

function* getProfileSaga(action: ReturnType<typeof getProfileStart>) {
  const endpoint = '/client/user/getProfile';
  const { userId } = action.payload;
  const options = { userId };

  try {
    const response: AxiosResponse = yield call(getFromAPI, options, endpoint);

    const result: any = response.data.user ?? null;

    if (result) {
      yield put(getProfileSuccess({ result }));
    } else {
      yield put(getProfileFailure({ error: 'Ошибка: некорректные данные' }));
    }
  } catch (e) {
    yield put(getProfileFailure({ error: 'Ошибка при выполнении запроса' }));
  }
}

export function* watchGetProfileSaga(): SagaIterator {
  yield takeEvery(getProfileStart.type, getProfileSaga);
}
