import { Avatar, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import CustomButton from 'components/ui/Auth/CustomButton';
import { ContentType, Path } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { followStart } from 'store/user';
import { formatNumber, getUserSession } from 'utils/utils';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DialogEditProfile from './DialogEditProfile';

const Information: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const currentUser = getUserSession();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    followingList,
    followerList,
    otherUserFollowingList,
    otherUserFollowerList,
  } = useAppSelector((state) => state.user.getFollowerList);

  const { user } = useAppSelector((state) => state.user.getProfile);

  const { error: storeSubscribeError } = useAppSelector(
    (state) => state.user.follow
  );

  const isUserFollowed = followingList.some(
    (item: { toUserId: { _id: string } }) => item.toUserId._id === user?._id
  );

  const isSameId = currentUser._id === user?._id;

  const followerListCount = followerList.length;
  const followingListCount = followingList.length;
  const otherUserFollowingListCount = otherUserFollowingList.length;

  const [isFollowed, setIsFollowed] = useState<boolean>(isUserFollowed);
  const [isPrevFollowed, setIsPrevFollowed] = useState<boolean>(isUserFollowed);
  const [otherUserFollowerListCount, setOtherUserFollowerListCount] =
    useState<number>(otherUserFollowerList.length);
  const [prevOtherUserFollowerListCount, setPrevOtherUserFollowerListCount] =
    useState<number>(otherUserFollowerList.length);

  const displayedFollowerListCount = isSameId
    ? followerListCount
    : otherUserFollowerListCount;
  const displayedFollowingListCount = isSameId
    ? followingListCount
    : otherUserFollowingListCount;

  const handleGoToChangeProfile = () => {
    navigate(Path.CHANGE_PROFILE);
  };

  useEffect(() => {
    if (storeSubscribeError) {
      setIsFollowed(isPrevFollowed);
      setOtherUserFollowerListCount(prevOtherUserFollowerListCount);
    }
  });

  const handleSubscribe = () => {
    const followStatus = !isFollowed;
    setIsFollowed(followStatus);
    setIsPrevFollowed(isFollowed);
    setOtherUserFollowerListCount((prev) => (followStatus ? ++prev : --prev));
    setPrevOtherUserFollowerListCount((prev) => prev);

    dispatch(
      followStart({
        fromUserId: currentUser._id,
        toUserId: user?._id || currentUser._id,
      })
    );
  };

  useEffect(() => {
    setIsFollowed(isUserFollowed);
    setOtherUserFollowerListCount(otherUserFollowerList.length);
    setPrevOtherUserFollowerListCount(otherUserFollowerList.length);
  }, [user?._id, otherUserFollowerList]);

  return (
    <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'center', sm: 'flex-start' }, my: { xs: 0, sm: 4.5 } }}>
      <Avatar
        src={user?.image}
        alt='User Image'
        sx={{ width: { xs: 140, sm: 164 }, height: { xs: 140, sm: 164 }, marginRight: { xs: 0, sm: '24px' }, mt: { xs: 2, sm: 0 } }}
      />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: { xs: 'center', sm: 'flex-start' }, alignItems: 'center', mt: { xs: 2, sm: 0 } }}>
          <Typography
            sx={{
              textAlign: { xs: 'center', sm: 'start' },
              fontSize: { xs: '16px', sm: '28px' },
              color: '#fff'
            }}>
            {user?.name}
          </Typography>
          <CheckCircleRoundedIcon sx={{ color: 'rgba(121, 255, 228, 1)', fontSize: '17px' }} />
        </Box>
        <Typography
          sx={{
            color: theme.palette.custom.primary.graphite,
            mb: 0.5,
            fontWeight: 500,
            textAlign: { xs: 'center', sm: 'start' },
            fontSize: { xs: '14px', sm: '16px' },
            fontFamily: 'Dm sans'
          }}
        >
          {user?.userName}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.custom.primary.graphite,
            mb: 0.5,
            fontWeight: 500,
            textAlign: { xs: 'center', sm: 'start' },
            fontSize: { xs: '14px', sm: '16px' },
            fontFamily: 'Dm sans'
          }}
        >
          {user?.bio}
        </Typography>
        {/* <Stack direction='row' mb={3} alignItems='center'>
          <CustomTypography
            count={displayedFollowerListCount}
            content='subscribers'
            type={ContentType.FOLLOWER_LIST}
            userId={user?._id || currentUser._id}
          />
          <Dot />
          <CustomTypography
            count={displayedFollowingListCount}
            content='subscriptions'
            type={ContentType.FOLLOWING_LIST}
            userId={user?._id || currentUser._id}
          />
          <Dot />
          <CustomTypography count={followerListCount} content='likes' />
        </Stack> */}
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, mt: { xs: 3, sm: 2 }, gap: 4 }}>
          <Stack sx={{ alignItems: 'center' }}>
            <Typography variant='h4' sx={{ color: '#fff' }}>
              {formatNumber(displayedFollowerListCount)}
            </Typography>
            <Typography variant='h6' sx={{
              color: theme.palette.custom.primary.graphite,
              fontFamily: 'Dm sans'
            }}>
              Subscribers
            </Typography>
          </Stack>
          <Stack sx={{
            alignItems: 'center',
            borderRight: '1px solid rgba(110, 113, 132, 0.45)',
            borderLeft: '1px solid rgba(110, 113, 132, 0.45)',
            p: '0 16px'
          }}>
            <Typography variant='h4' sx={{ color: '#fff' }}>
              {formatNumber(displayedFollowingListCount)}
            </Typography>
            <Typography variant='h6' sx={{
              color: theme.palette.custom.primary.graphite,
              fontFamily: 'Dm sans'
            }}>
              Subscriptions
            </Typography>
          </Stack>
          <Stack sx={{ alignItems: 'center' }}>
            <Typography variant='h4' sx={{ color: '#fff' }}>
              {formatNumber(followerListCount)}
            </Typography>
            <Typography variant='h6' sx={{
              color: theme.palette.custom.primary.graphite,
              fontFamily: 'Dm sans'
            }}>
              Likes
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 3, width: '100%', p: { xs: '0 16px', sm: 0 } }}>
          {isSameId ? (
            <DialogEditProfile user={user} />
          ) : (
            <CustomButton
              style={{
                height: '40px',
                marginBottom: 0,
                padding: '18px 24px',
                width: 'auto',
              }}
              onClick={handleSubscribe}
              i18nKey={isFollowed ? 'button.unsubscribe' : 'button.subscribe'}
            />
          )}
          <IconButton sx={{
            background: 'rgba(110, 113, 132, 0.1)',
            borderRadius: '50%'
          }}>
            <ShareOutlinedIcon sx={{ color: 'rgba(110, 113, 132, 0.45)' }} />
          </IconButton>
        </Box>
      </Box>
    </Stack>
  );
};

export default Information;

interface ICustomTypographyProps {
  count: number;
  type: ContentType;
  userId: string;
  content?: string;
}

const CustomTypography: FC<ICustomTypographyProps> = ({
  count = 0,
  content,
  userId,
  type,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGoToSubscribeList = (type: ContentType) => {
    navigate(`/${userId}/${type}`);
  };

  return (
    <Typography
      variant='h6'
      sx={{
        color: theme.palette.custom.primary.graphite,
        cursor: count > 0 ? 'pointer' : 'default',
      }}
      onClick={() => (count > 0 ? handleGoToSubscribeList(type) : undefined)}
    >
      {formatNumber(count)} {t(`information.${content}`)}
    </Typography>
  );
};
