import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { IconVideoUpload } from 'assets';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { uploadVideoStart } from 'store/video';
import { getUserSession } from 'utils/utils';

const NoVideoData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const user = getUserSession();

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      dispatch(uploadVideoStart({ videoData: { file }, userId: user._id }));
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100%', mt: { xs: 3, sm: 0 }, pb: { xs: 8, sm: 0 } }}
    >
      <Typography
        sx={{ color: `${theme.palette.custom.primary.graphite}45`, mb: { xs: 0, sm: 1 }, fontSize: { xs: '16px', sm: '28px' } }}
      >
        {t('information.upload_video_title')}
      </Typography>
      <Typography
        sx={{ color: `${theme.palette.custom.primary.graphite}45`, mb: { xs: 1, sm: 3 }, fontSize: { xs: '14px', sm: '16px' } }}
      >
        {t('information.upload_video_content')}
      </Typography>

      <IconButton
        color='primary'
        aria-label='upload video'
        component='label'
        sx={{ width: { xs: '65px', sm: '120px' }, height: { xs: '65px', sm: '120px' } }}>
        <input
          hidden
          accept='video/*'
          type='file'
          onChange={handleVideoUpload}
        />
        <IconVideoUpload />
      </IconButton>
      <Typography
        variant='h6'
        sx={{ color: theme.palette.custom.primary.blue }}
      >
        {t('button.video_upload')}
      </Typography>
    </Box>
  );
};

export default NoVideoData;
