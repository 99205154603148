import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import theme from 'styles/theme';

export interface FormCheckboxProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label?: ReactNode;
  errorMessage?: string | FieldError;
  defaultValue?: boolean;
  errorColor?: string;
  variant?: 'body1' | 'body2' | 'h6';
}

const FormCheckbox = <T extends FieldValues>({
  control,
  name,
  label,
  errorMessage,
  defaultValue = false,
  errorColor,
  variant = 'body1',
}: FormCheckboxProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue as PathValue<T, Path<T>>}
    render={({ field, fieldState }) => (
      <FormControl error={!!fieldState.error} component='fieldset'>
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={field.value}
              onChange={(event) => field.onChange(event.target.checked)}
              sx={{
                '&.Mui-checked': {
                  color: theme.palette.custom.primary.blue,
                },
              }}
            />
          }
          label={<Typography variant={variant}>{label}</Typography>}
        />
        {fieldState.error || errorMessage ? (
          <FormHelperText
            style={{ color: errorColor || 'custom.primary.extraError' }}
          >
            {fieldState.error?.message ||
              (typeof errorMessage === 'string'
                ? errorMessage
                : errorMessage?.message)}
          </FormHelperText>
        ) : null}
      </FormControl>
    )}
  />
);

export default FormCheckbox;
