import { AuthModalType, Path } from 'constants/index';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState<AuthModalType | null>(null);

  const { error } = useAppSelector((state) => state.auth);

  const openModal = (type: AuthModalType) => {
    setModalType(type);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    navigate(Path.RECOMMENDEDVIDEOS);
    // error ? navigate(`/${formType}`) : navigate(Path.ROOT);
  };

  useEffect(() => {
    const path = location.pathname;

    if (!!error) {
      openModal(AuthModalType.ERROR);
    }

    if (!isOpen && !error) {
      if (path === Path.LOGIN) {
        openModal(AuthModalType.LOGIN);
      } else if (path === Path.REGISTRATION) {
        openModal(AuthModalType.REGISTRATION);
      } else if (path === Path.FORGOT_PASSWORD) {
        openModal(AuthModalType.FORGOT_PASSWORD);
      }
    }
  }, [location.pathname, error, isOpen]);

  return {
    isOpen,
    modalType,
    openModal,
    closeModal,
  };
};

export default useModal;
