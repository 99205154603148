import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { followSuccess } from 'store/user';
import {
  addCommentSuccess,
  getCommentsSuccess,
  likeVideoSuccess,
  uploadVideoSuccess,
} from 'store/video';
import { Video } from 'typings/index';

interface GetVideoState {
  videos: Video[];
  isLoading: boolean;
  error: string | null;
  limit: number;
  start: number;
}

const initialState: GetVideoState = {
  videos: [],
  isLoading: false,
  error: null,
  limit: 20,
  start: 1,
};

export const getAllVideoSlice = createSlice({
  name: 'get/all_video',
  initialState,
  reducers: {
    getAllVideoStart: (
      state,
      action: PayloadAction<{ userId: string | null; videoId?: string | null }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    getAllVideoSuccess: (state, { payload }) => {
      const newVideos = payload.result.filter(
        (newVideo: any) => !state.videos.some((existingVideo) => existingVideo._id === newVideo._id)
      );
      state.videos = [...state.videos, ...newVideos];
      state.error = null;
      state.isLoading = false;
      state.start += state.limit;
    },
    getAllVideoFailure: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    getAllVideoClear: (state) => {
      state.error = null;
      state.isLoading = false;
      state.videos = [];
      state.start = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(likeVideoSuccess, (state, { payload }) => {
      const { videoId, isLiked } = payload;

      state.videos = state.videos.map((video) => {
        if (video._id === videoId) {
          return {
            ...video,
            isLike: isLiked,
            totalLikes: isLiked ? video.totalLikes + 1 : video.totalLikes - 1,
          };
        }

        return video;
      });
    });
    builder.addCase(followSuccess, (state, { payload }) => {
      const { toUserId, isFollow } = payload;

      state.videos = state.videos.map((video) => {
        if (video.userId === toUserId) {
          return {
            ...video,
            isFollow,
          };
        }

        return video;
      });
    });
    builder.addCase(uploadVideoSuccess, (state, { payload }) => {
      state.videos = [payload, ...state.videos];
    });
    builder.addCase(addCommentSuccess, (state, { payload }) => {
      const { videoId } = payload.result;

      state.videos = state.videos.map((video) => {
        if (video._id === videoId) {
          return {
            ...video,
            totalComments: video.totalComments + 1,
          };
        }

        return video;
      });
    });
    builder.addCase(getCommentsSuccess, (state, { payload }) => {
      state.videos = state.videos.map((video) => {
        if (video._id === payload.result.videoId) {
          return {
            ...video,
            totalComments: payload.result.length,
          };
        }

        return video;
      });
    });
  },
});

export const {
  getAllVideoClear,
  getAllVideoStart,
  getAllVideoSuccess,
  getAllVideoFailure,
} = getAllVideoSlice.actions;

export default getAllVideoSlice.reducer;
