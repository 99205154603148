import { Box, Tab, Tabs } from '@mui/material';
import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CustomDivider from '../CustomDivider';
import Content from './Content';

interface IMenuProps {
  isOwner: boolean;
}

const Menu: FC<IMenuProps> = ({ isOwner }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ mt: { xs: 3, sm: 0 } }}>
      <Box display='flex' sx={{ mb: '-1px', width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { backgroundColor: 'rgba(121, 255, 228, 1)', height: 3, bottom: 16 } }}
          sx={{ '.MuiTabs-flexContainer': { justifyContent: { xs: 'center', sm: 'flex-start' } }, width: '100%', display: { xs: 'none', sm: 'flex' }, }}>
          <Tab
            icon={<VideoLibraryOutlinedIcon />}
            iconPosition="start"
            label="My Videos"
            value={0}
            sx={{
              textTransform: 'none',
              color: value === 0 ? '#fff !important' : '#fff !important',
              mr: 5,
              // minWidth: '120px',
              minHeight: '10px',
              p: 0
            }}
          />
          {/* <Tab
            icon={<FavoriteBorderIcon />}
            iconPosition="start"
            label="Liked videos"
            value={1}
            sx={{
              textTransform: 'none',
              color: value === 1 ? '#1C1B20 !important' : 'rgba(110, 113, 132, 0.45)',
              mr: 5,
              // minWidth: '120px',
              minHeight: '10px',
              p: 0
            }}
          /> */}
        </Tabs>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { backgroundColor: 'rgba(121, 255, 228, 1)', height: 3, bottom: 16 } }}
          sx={{ '.MuiTabs-flexContainer': { justifyContent: { xs: 'center', sm: 'flex-start' } }, width: '100%', display: { xs: 'flex', sm: 'none' } }}>
          <Tab
            icon={<VideoLibraryOutlinedIcon />}
            iconPosition="start"
            value={0}
            sx={{
              textTransform: 'none',
              color: value === 0 ? '#fff !important' : '#fff !important',
              mx: 8,
              minWidth: '40px',
              minHeight: '10px',
              p: 0
            }}
          />
          {/* <Tab
            icon={<FavoriteBorderIcon />}
            iconPosition="start"
            value={1}
            sx={{
              textTransform: 'none',
              color: value === 1 ? '#1C1B20 !important' : 'rgba(110, 113, 132, 0.45)',
              mx: 8,
              minWidth: '40px',
              minHeight: '10px',
              p: 0
            }}
          /> */}
        </Tabs>
      </Box>
      <Box sx={{ mt: '-18px', width: '100%' }}>
        <CustomDivider />
      </Box>
      {value === 0 && <Content isOwner={isOwner} />}
    </Box>
  );
};

export default Menu;
