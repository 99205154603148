import { Button, ButtonProps, SxProps } from '@mui/material';

interface ButttonCustomProps {
    title: string;
    sx?: SxProps;
}

const ButttonCustom = ({ title, sx, ...props }: ButttonCustomProps & ButtonProps) => {

    return (
        <Button
            variant='contained'
            sx={{
                background: '#79FFE4',
                borderRadius: '24px',
                p: '10px 24px',
                textTransform: 'none',
                boxShadow: 'none',
                height: '40px',
                ...sx,
                color: '#000',
                
                fontfamily: 'Dela Gothic One'
            }}
            {...props}
        >
            {title}
        </Button>
    );
};

export default ButttonCustom;
