import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';

export interface FormTextFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  labelKey?: string;
  errorMessage?: string | FieldError;
  defaultValue?: PathValue<T, Path<T>>;
  fullWidth?: boolean;
  multiline?: boolean;
  type?: string;
  placeholder?: string;
  errorColor?: string;
  disabled?: boolean;
}

const FormTextField = <T extends FieldValues>({
  control,
  name,
  labelKey,
  errorMessage,
  defaultValue,
  fullWidth = true,
  multiline = false,
  type = 'text',
  placeholder,
  errorColor,
  disabled = false,
}: FormTextFieldProps<T>) => {
  const { t } = useTranslation();
  const initialFormattedValue =
    type === 'number' && defaultValue
      ? new Intl.NumberFormat('ru-RU').format(Number(defaultValue))
      : defaultValue;

  const [displayValue, setDisplayValue] = useState<string>(
    String(initialFormattedValue || '')
  );
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    newValue = newValue.replace(/[^0-9,]/g, '');

    const parts = newValue.split(',');
    if (parts.length > 2) {
      newValue = `${parts[0]},${parts.slice(1).join('')}`;
    }

    if (newValue.includes(',')) {
      const [integerPart, fractionalPart] = newValue.split(',');
      const formattedIntegerPart = new Intl.NumberFormat('ru-RU').format(
        Number(integerPart)
      );
      setDisplayValue(
        fractionalPart
          ? `${formattedIntegerPart},${fractionalPart}`
          : `${formattedIntegerPart},`
      );
    } else {
      const formattedValue = new Intl.NumberFormat('ru-RU').format(
        Number(newValue)
      );
      setDisplayValue(formattedValue);
    }
  };

  useEffect(() => {
    if (type === 'number' && defaultValue !== undefined) {
      const formattedValue = new Intl.NumberFormat('ru-RU').format(
        Number(defaultValue)
      );
      setDisplayValue(formattedValue);
    }
  }, [defaultValue, type]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as PathValue<T, Path<T>>}
      render={({ field, fieldState }) => (
        <FormControl fullWidth={fullWidth} error={!!fieldState.error}>
          <TextField
            {...field}
            disabled={disabled}
            value={type === 'number' ? displayValue : field.value}
            onChange={type === 'number' ? handleNumberChange : field.onChange}
            label={labelKey ? t(labelKey) : ''}
            type={
              type === 'password' && showPassword
                ? 'text'
                : type === 'number'
                  ? 'text'
                  : type
            } // Логика для number и password
            variant='outlined'
            autoComplete='off'
            error={!!fieldState.error}
            helperText={
              fieldState.error?.message ||
              (typeof errorMessage === 'string'
                ? errorMessage
                : errorMessage?.message)
            }
            fullWidth={fullWidth}
            multiline={multiline}
            placeholder={placeholder && t(placeholder)}
            inputMode={type === 'number' ? 'numeric' : undefined}
            InputProps={{
              endAdornment:
                type === 'password' ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? (
                        <VisibilityOff
                          sx={{
                            color: '#fff',
                            opacity: '0.25',
                          }}
                        />
                      ) : (
                        <Visibility
                          sx={{
                            color: '#fff',
                            opacity: '0.25',
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
            sx={{
              '& .MuiFormHelperText-root.Mui-error ': {
                color:
                  errorColor ||
                  (fieldState.error ? 'custom.primary.extraError' : 'inherit'),
              },
            }}
          />
        </FormControl>
      )}
    />
  );
};

export default FormTextField;
