import { Avatar, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFollowerFollowing } from 'typings/index';
import { replaceUrl } from 'utils/utils';

interface ISubscriptionItemProps {
  item: IFollowerFollowing;
  isActive: boolean;
  onClick: (id: string) => void;
}

const SubscriptionItem: FC<ISubscriptionItemProps> = ({
  item,
  isActive,
  onClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const targetPath = `/${item.toUserId._id}/profile`;

  const handleItemClick = () => {
    onClick(item.toUserId._id);
    navigate(targetPath);
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      py={1}
      onClick={handleItemClick}
      sx={{
        borderRadius: '24px',
        px: 2,
        background: isActive
          ? `${theme.palette.custom.primary.graphite}10`
          : 'transparent',

        '&:hover': {
          cursor: 'pointer',
          background: `${theme.palette.custom.primary.graphite}10`,
        },
      }}
    >
      <Avatar
        src={replaceUrl(item.toUserId.image)}
        alt='User Image'
        sx={{ width: 24, height: 24, mr: 1.5 }}
      />
      <Typography
        variant='h6'
        sx={{ color: '#fff', fontFamily: 'DM Sans' }}
      >
        {item.toUserId.userName}
      </Typography>
    </Stack>
  );
};

export default SubscriptionItem;
