import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid2';
import CustomButton from 'components/ui/Auth/CustomButton';
import ModalTitle from 'components/ui/Auth/ModalTitle';
import StyledFormTextField from 'components/ui/StyledFormTextField';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useChangePasswordValidationSchema } from 'validation/validationSchema';

interface PasswordRecoveryStep3Data {
  password: string;
  confirmPassword: string;
}

interface IPasswordRecoveryStep3Props {
  onNext: (data: PasswordRecoveryStep3Data) => void;
}

const PasswordRecoveryStep3: FC<IPasswordRecoveryStep3Props> = ({ onNext }) => {
  const { t } = useTranslation();

  const changePasswordValidationSchema = useChangePasswordValidationSchema();

  const defaultValues = {
    password: '',
    confirmPassword: '',
  };

  const { control, handleSubmit } = useForm<PasswordRecoveryStep3Data>({
    defaultValues,
    resolver: yupResolver(changePasswordValidationSchema),
  });

  const onSubmit = (data: PasswordRecoveryStep3Data) => {
    onNext(data);
  };

  return (
    <>
      <ModalTitle title={t('auth.title.passwordRecovery')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ mb: '24px' }}>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='password'
              labelKey='auth.form.password'
              type='password'
            />
          </Grid>
          <Grid size={12}>
            <StyledFormTextField
              control={control}
              name='confirmPassword'
              labelKey='auth.form.confirmPassword'
              type='password'
            />
          </Grid>
        </Grid>

        <CustomButton i18nKey='form.button.save' />
      </form>
    </>
  );
};

export default PasswordRecoveryStep3;
