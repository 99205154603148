import { Box, InputAdornment, MenuItem, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTextField from 'components/ui/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { getFromAPI, postToAPI } from 'api/index';
import cfg from 'config/index';
import { getUserSession } from 'utils/utils';

interface HeaderProps {
    isShowSidebar?: boolean;
    handleOpenMenu?: () => void;
}

const SearchInput: FC<HeaderProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>('');
    const [results, setResults] = useState<any>([]);
    const user = getUserSession();
    const [open, setOpen] = useState<boolean>(false);

    const getUsers = (query: string) => {

        (user && query.trim().length > 2) && postToAPI(null, {}, `${cfg.API_URL}/client/searchHistory/searchUsers?userSearchString=${query}&userId=${user._id}`)
            .then(({ data }: any) => {
                data.searchData && setResults(data.searchData)
            })
    }

    useEffect(() => {
        if (query.trim().length > 2) {
            getUsers(query);
        } else {
            setResults([]);
        }
    }, [query]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
        setOpen(true);
    };

    const handleSelect = (id: string) => {
        setQuery('');
        setOpen(false);
        navigate(`/${id}/profile`)
    };

    return (
        <Box sx={{ width: '100%', position: 'relative' }}>
            <Box sx={{ width: '100%' }}><CustomTextField
                placeholder={t("header.search")}
                fullWidth
                value={query}
                onChange={handleChange}
                disabled={!user}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    color: "#fff !important",
                    maxWidth: '654px',
                    '& .MuiOutlinedInput-root': {
                        '& input': {
                            p: '16px 10px 16px 24px'
                        },
                    }
                }}
                slotProps={{
                    input:
                    {
                        startAdornment: (<InputAdornment position="start"><SearchIcon sx={{ color: "rgba(110, 113, 132, 0.45)" }} /></InputAdornment>)
                    }
                }} />
            </Box>
            {open && results.length > 0 && (
                <Box
                    sx={{
                        position: 'absolute',
                        overflowY: "auto",
                        bgcolor: "rgba(11, 11, 11, 1)",
                        boxShadow: 3,
                        borderRadius: 1,
                        mt: 1,
                        width: '100%'
                    }}
                >
                    {results.map((result: any) => (
                        <MenuItem
                            key={result.id}
                            onClick={() => handleSelect(result._id)}
                            sx={{
                                '&:hover': {
                                    background: 'rgba(121, 255, 228, 1)',
                                    color: '#000',
                                    borderRadius: '8px'
                                }, color: '#fff',
                                p: 1
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <SearchIcon />
                                <Typography sx={{ fontFamily: 'Dm sans', fontWeight: 'bold' }}>{result.userName}</Typography>
                            </Box>

                        </MenuItem>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default SearchInput;
