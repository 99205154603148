import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface FormSelectProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  labelKey?: string;
  errorMessage?: string | FieldError;
  defaultValue?: string | number;
  options: Array<{ value: string; labelKey: string }>;
  // options: Array<{ value: string | number; labelKey: string }>;
  fullWidth?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  isError?: boolean;
  checkboxStyles?: CheckboxProps['sx'];
}

const StyledFormHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError?: boolean }>(({ theme, isError }) => ({
  color: isError
    ? theme.palette.custom.primary.extraError
    : '#fff',
  marginTop: '8px',
}));

const FormSelect = <T extends FieldValues>({
  control,
  name,
  labelKey,
  errorMessage,
  defaultValue,
  options,
  fullWidth = true,
  disabled = false,
  multiple = false,
  isError = false,
  checkboxStyles,
}: FormSelectProps<T>) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as PathValue<T, Path<T>>}
      render={({ field, fieldState }) => (
        <FormControl
          fullWidth={fullWidth}
          error={!!fieldState.error}
          disabled={disabled}
        >
          {labelKey && <InputLabel>{t(labelKey)}</InputLabel>}
          <Select
            {...field}
            value={field.value || defaultValue}
            onChange={(event) => field.onChange(event.target.value)}
            multiple={multiple}
            label={labelKey ? t(labelKey) : ''}
            displayEmpty
            input={<OutlinedInput label={labelKey ? t(labelKey) : ''} />}
            renderValue={(selected) =>
              multiple && Array.isArray(selected)
                ? selected.join(', ')
                : selected
            }
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: '16px',
                },
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={t(option.value)}>
                {multiple && (
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    color='primary'
                    sx={checkboxStyles}
                  />
                )}
                {t(option.labelKey)}
              </MenuItem>
            ))}
          </Select>
          <StyledFormHelperText isError={isError}>
            {fieldState.error?.message ||
              (typeof errorMessage === 'string'
                ? errorMessage
                : errorMessage?.message)}
          </StyledFormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormSelect;
