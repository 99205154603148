import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface FormRadioGroupProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  labelKey?: string;
  errorMessage?: string | FieldError;
  defaultValue?: PathValue<T, Path<T>>;
  options: Array<{ value: string | number; labelKey: string }>;
  fullWidth?: boolean;
}

const FormRadioGroupTest = <T extends FieldValues>({
  control,
  name,
  labelKey,
  errorMessage,
  defaultValue,
  options,
  fullWidth = true,
}: FormRadioGroupProps<T>) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as PathValue<T, Path<T>>}
      render={({ field, fieldState }) => (
        <FormControl fullWidth={fullWidth} error={!!fieldState.error}>
          {labelKey && <FormLabel>{t(labelKey)}</FormLabel>}
          <RadioGroup
            {...field}
            row
            value={field.value || defaultValue}
            onChange={(event) => field.onChange(event.target.value)}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={t(option.labelKey)}
              />
            ))}
          </RadioGroup>
          <FormHelperText>
            {fieldState.error?.message ||
              (typeof errorMessage === 'string'
                ? errorMessage
                : errorMessage?.message)}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormRadioGroupTest;
