import { Box, IconButton, Link } from '@mui/material';
import AppStoreIcon from 'assets/IconsComponents/AppStore';
import DownloadIcon from 'assets/IconsComponents/Download';
import GooglePlayIcon from 'assets/IconsComponents/GooglePlay';
import { FC, PropsWithChildren } from 'react';

const DownloadAppIcons: FC = () => {
  return (
    <Box p={1} mt={1}>
      <IconButton
        disabled
        edge='end'
        color='inherit'
        sx={{
          borderRadius: '24px',
          p: 1,
          mr: 1,
          '&:disabled': {
            opacity: 0.5,
          },
          color: '#79FFE4'
        }}>
        <AppStoreIcon />
      </IconButton>
      <IconButton
        disabled
        edge='end'
        sx={{
          borderRadius: '24px',
          p: 1,
          mr: 1,
          '&:disabled': {
            opacity: 0.5,
          },
        }}>
        <GooglePlayIcon />
      </IconButton>
      <IconButton
        edge='end'
        component={Link}
        href="https://artifacts.staging.iearn.me/iearn_latestSigned.apk"
        color='inherit'
        target="_blank"
        sx={{
          borderRadius: '24px',
          p: 1,
          mr: 1,
          '&:disabled': {
            opacity: 0.5,
          },
        }}>
        <DownloadIcon />
      </IconButton>
    </Box>
  );
};

export default DownloadAppIcons;