import { Box, Typography, useTheme, CircularProgress } from '@mui/material';
import { IconCopy } from 'assets';
import CustomButton from 'components/ui/Auth/CustomButton';
import { FC, useEffect } from 'react';
import { Video } from 'typings/index';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getSharingLinkVideoStart } from 'store/video';
import { useAppSelector } from 'hooks/useRedux';
import {
  EmailIcon,
  OKIcon,
  VKIcon,
  VKShareButton,
  TelegramIcon,
  OKShareButton,
  TelegramShareButton,
} from 'react-share';

interface ISharingPanelProps {
  video: Video;
}

const SharingPanel: FC<ISharingPanelProps> = ({ video }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isLoading, url } = useAppSelector(
    (state) => state.video.getSharingLinkVideo
  );

  useEffect(() => {
    dispatch(getSharingLinkVideoStart({ videoId: video._id }));
  }, [video, location.pathname]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      console.log('Link copied to clipboard!');
    } catch (error) {
      console.error('Failed to copy the link: ', error);
    }
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {isLoading ? (
        <CircularProgress
          sx={{ display: 'block', margin: '0 auto', mt: 2, mb: 2 }}
        />
      ) : (
        <Box>
          <Typography
            variant='h6'
            sx={{
              mb: 2,
              mt: 2,
              color: 'rgba(121, 255, 228, 1)',
              cursor: 'pointer',
              fontFamily: 'Dm sans',
            }}
            onClick={handleCopy}
          >
            {url}
          </Typography>
          <CustomButton
            type='button'
            onClick={handleCopy}
            i18nKey='button.copy'
            sx={{ width: 'auto', marginBottom: 0, padding: '8px 28px' }}
            startIcon={<ContentCopyIcon sx={{ color: '#000' }} />}
            disabled={isLoading}
          />
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              mt: 2,
              width: '100%',
            }}
          >
            <TelegramShareButton url={url}>
              <EmailIcon size={32} round />
            </TelegramShareButton>

            <OKShareButton url={url}>
              <OKIcon size={32} round />
            </OKShareButton>

            <VKShareButton url={url}>
              <VKIcon size={32} round />
            </VKShareButton>

            <TelegramShareButton url={url}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SharingPanel;
