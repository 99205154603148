import { getFromAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getAllVideoFailure,
  getAllVideoStart,
  getAllVideoSuccess,
} from 'store/video/slices/getAllVideoSlice';
import { Primitive } from 'typings/index';

function* getAllVideoSaga(action: ReturnType<typeof getAllVideoStart>) {
  const { limit, start } = yield select((state) => state.video.getAllVideo);
  const endpoint = '/client/video/getAllVideos';
  const { userId, videoId } = action.payload;
  const options: Record<string, Primitive | Primitive[]> = userId
    ? { limit, start, userId, videoId: videoId || '' }
    : { limit, start, videoId: videoId || '' };

  try {
    const response: AxiosResponse = yield call(getFromAPI, options, endpoint);

    const result: any = response.data.data ?? null;

    if (result) {
      yield put(getAllVideoSuccess({ result }));
    } else {
      yield put(getAllVideoFailure('Ошибка: некорректные данные'));
    }
  } catch (e) {
    yield put(getAllVideoFailure('Ошибка при выполнении запроса'));
  }
}

export function* watchGetAllVideoSaga(): SagaIterator {
  yield takeEvery(getAllVideoStart.type, getAllVideoSaga);
}
