import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { watchAuthSaga } from './auth/sagas/authSaga';
import { watchGetReportReasonSaga } from './report/sagas/getReportReasonSaga';
import { watchReportSaga } from './report/sagas/reportSaga';
import { watchFollowSaga } from './user/sagas/followSaga';
import { watchGetFollowingListSaga } from './user/sagas/getFollowingListSaga';
import { watchGetProfileSaga } from './user/sagas/getProfileSaga';
import { watchAddCommentSaga } from './video/sagas/addCommentSaga';
import { watchGetAllVideoSaga } from './video/sagas/getAllVideoSaga';
import { watchGetCommentsSaga } from './video/sagas/getCommentsSaga';
import { watchGetSharingLinkVideoSaga } from './video/sagas/getSharingLinkVideoSaga';
import { watchGetUserVideoSaga } from './video/sagas/getUserVideoSaga';
import { watchLikeCommentSaga } from './video/sagas/likeCommentSaga';
import { watchLikeVideoSaga } from './video/sagas/likeVideoSaga';
import { watchUploadVideoSaga } from './video/sagas/uploadVideoSaga';

export default function* Root(): SagaIterator<void> {
  yield all([fork(watchAuthSaga)]);
  yield all([fork(watchGetAllVideoSaga)]);
  yield all([fork(watchFollowSaga)]);
  yield all([fork(watchLikeVideoSaga)]);
  yield all([fork(watchReportSaga)]);
  yield all([fork(watchGetReportReasonSaga)]);
  yield all([fork(watchUploadVideoSaga)]);
  yield all([fork(watchGetUserVideoSaga)]);
  yield all([fork(watchGetFollowingListSaga)]);
  yield all([fork(watchGetCommentsSaga)]);
  yield all([fork(watchAddCommentSaga)]);
  yield all([fork(watchLikeCommentSaga)]);
  yield all([fork(watchGetSharingLinkVideoSaga)]);
  yield all([fork(watchGetProfileSaga)]);
}
