import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCommentSuccess } from 'store/video/slices/addCommentSlice';
import { likeCommentSuccess } from 'store/video/slices/likeCommentSlice';
import { IComment } from 'typings/index';

interface ICommentsProps {
  userId: string;
  type: string;
  postId?: string;
  videoId?: string;
}

interface GetCommentsState {
  videoComments: IComment[];
  isLoading: boolean;
  error: string | null;
}

const initialState: GetCommentsState = {
  videoComments: [],
  isLoading: false,
  error: null,
};

export const getCommentsSlice = createSlice({
  name: 'get/comments',
  initialState,
  reducers: {
    getCommentsStart: (state, action: PayloadAction<ICommentsProps>) => {
      state.isLoading = true;
      state.error = null;
    },
    getCommentsSuccess: (state, { payload }) => {
      state.videoComments = payload.result;
      state.error = null;
      state.isLoading = false;
    },
    getCommentsFailure: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(likeCommentSuccess, (state, { payload }) => {
      const { postOrVideoCommentId, isLike } = payload;

      state.videoComments = state.videoComments.map((comment) => {
        if (comment._id === postOrVideoCommentId) {
          const totalLikes = comment?.totalLikes || 0;

          return {
            ...comment,
            isLike,
            totalLikes: isLike ? totalLikes + 1 : totalLikes - 1,
          };
        }

        return comment;
      });
    });
    builder.addCase(addCommentSuccess, (state, { payload }) => {
      state.videoComments = [payload.result, ...state.videoComments];
    });
  },
});

export const { getCommentsStart, getCommentsSuccess, getCommentsFailure } =
  getCommentsSlice.actions;

export default getCommentsSlice.reducer;
