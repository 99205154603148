import { Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { IconCut, IconEdit, IconFlag } from 'assets/index';
import { OwnerType } from 'components/ui/CommentsPanel/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ICommentActionPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleActionClose: () => void;
  popoverType: OwnerType;
  currentCommentId: string | null;
}

const CommentActionPopover: FC<ICommentActionPopoverProps> = ({
  anchorEl,
  handleActionClose,
  popoverType,
  currentCommentId,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isCommentOwner = popoverType === OwnerType.COMMENT_OWNER;

  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleActionClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      slotProps={{
        paper: {
          sx: {
            width: '168px',
            py: 1,
            borderRadius: '12px',
            boxShadow: '0px 2px 6px 2px #00000026',
          },
        },
      }}
    >
      {isCommentOwner ? (
        <>
          <MenuItem onClick={handleActionClose}>
            <IconEdit />
            <Typography
              ml={1.5}
              variant='h6'
              sx={{ color: '#fff' }}
            >
              {t('video.popover.edit')}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleActionClose}>
            <IconCut />
            <Typography
              ml={1.5}
              variant='h6'
              sx={{ color: '#fff' }}
            >
              {t('video.popover.remove')}
            </Typography>
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem onClick={handleActionClose}>
            <IconFlag />
            <Typography
              ml={1.5}
              variant='h6'
              sx={{ color: '#fff' }}
            >
              {t('video.popover.complain')}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleActionClose}>
            <IconCut />
            <Typography
              ml={1.5}
              variant='h6'
              sx={{ color: '#fff' }}
            >
              {t('video.popover.remove')}
            </Typography>
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

export default CommentActionPopover;
