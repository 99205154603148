import { Box, IconButton } from '@mui/material';
import VideoPlayer from 'components/ui/VideoPlayer';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Video } from 'typings/index';
import { getUserSession } from 'utils/utils';
import NorthIcon from '@mui/icons-material/North';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { throttle } from 'lodash';
import DialogDownloadApp from '../VideoPlayer/DialogDownloadApp';
import { useAppSelector } from 'hooks/useRedux';
import VideoControlButtons from '../VideoControlButtons';
import { setCurrentPlayingVideoId, setMute, setVolume } from 'store/video';
import { useDispatch } from 'react-redux';

interface IVideoFeedProps {
  videos: Video[];
  userId?: string | null;
  videoId?: string | null;
  scrollRef: React.RefObject<HTMLDivElement>;
  pageUrl?: string;
}

const VideoFeed: FC<IVideoFeedProps> = ({
  videos,
  userId,
  scrollRef,
  videoId,
  pageUrl = 'recommended-videos'
}) => {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = useState<any>(null)
  const [userInfo, setUserInfo] = useState(getUserSession());
  const [firstLoading, setFirstLoading] = useState(true);
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [maxCountVideo, setMaxCountVideo] = useState(20);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [endAnimation, setEndAnimation] = useState(true);
  const [isShowDownloadBtn, setIsShowDownloadBtn] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const lastVideoId = useMemo(
    () =>
      videos.length >= maxCountVideo
        ? videos[maxCountVideo - 1]?._id
        : videos[videos.length - 1]?._id,
    [videos, maxCountVideo]
  );
  const { currentPlayingVideoId, volume, isMuted, previousVolume } =
    useAppSelector((state) => state.video.actionVideo);
  // const displayedVideo = useMemo(
  //   () => (!!userInfo ? videos : videos.slice(0, maxCountVideo)),
  //   [userInfo, videos, maxCountVideo]
  // );

  const displayedVideo = videos
  useEffect(() => {
    if (videoId && firstLoading) {
      const scrollToVideo = () => {
        const targetVideoElement = document.getElementById(videoId);
        if (targetVideoElement) {
          targetVideoElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          setFirstLoading(false);
        }
      };

      // Прокрутка запускается, когда все видео загружены
      scrollToVideo();
    }
  }, [videoId]);

  useEffect(() => {
    const checkLoginStatus = () => {
      setUserInfo(getUserSession());
    };

    window.addEventListener('loginStatusChange', checkLoginStatus);

    return () => {
      window.removeEventListener('loginStatusChange', checkLoginStatus);
    };
  }, []);

  const scrollStep = useCallback(() => {
    return window.innerHeight - 145;
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        // Показываем кнопку "вверх", если прокрутка больше calc(100vh - 145px)
        setShowScrollUp(scrollRef.current.scrollTop >= scrollStep());
      }
    };

    const refCurrent = scrollRef.current;
    refCurrent?.addEventListener('scroll', handleScroll);

    return () => {
      refCurrent?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollRef, scrollStep]);

  let accumulatedDeltaY = 0;
  let isScrolling = false;

  const handleWheel = useCallback((e: WheelEvent) => {
    if (!swiper || isScrolling) return;

    e.preventDefault();

    accumulatedDeltaY += e.deltaY;

    const threshold = 500;

    if (accumulatedDeltaY > threshold) {
      swiper.slideNext();
      accumulatedDeltaY = 0;
      isScrolling = true;
    } else if (accumulatedDeltaY < -threshold) {
      swiper.slidePrev();
      accumulatedDeltaY = 0;
      isScrolling = true;
    }

    setTimeout(() => {
      isScrolling = false;
    }, 300);
  }, [swiper]);

  useEffect(() => {
    const refCurrent = scrollRef.current;
    if (refCurrent) {
      refCurrent.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('wheel', handleWheel);
      }
    };
  }, [swiper, handleWheel]);

  const handleSlideChange = ({ activeIndex }: { activeIndex: number }) => {
    setActiveSlideIndex(activeIndex);
    if (activeIndex % 5 === 4) {
      setTimeout(() => {
        setIsShowDownloadBtn(true);
      }, 1000);
    } else {
      setIsShowDownloadBtn(false);
    }
  };

  const closeDownloadBtn = () => setIsShowDownloadBtn(false)

  const getPreloadStatus = (index: number) => {
    return (
      index === activeSlideIndex ||
      index === activeSlideIndex - 1 ||
      index === activeSlideIndex + 1
    );
  };
  const handlePlayToggle = () => {
    setIsPlaying(!isPlaying);
    dispatch(setCurrentPlayingVideoId(isPlaying ? null : displayedVideo[activeSlideIndex]._id));
  };

  useEffect(() => {
    setEndAnimation(true);
  }, [activeSlideIndex]);

  return (
    <Box
      sx={{
        pt: { xs: 0, sm: '24px' },
        overflowY: 'auto',
        height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 82px)' }
      }}
      ref={scrollRef}
    >
      <Box sx={{
        margin: '0 auto',
        display: { xs: 'none', sm: 'flex' },
        height: '100%',
        position: 'relative',
        aspectRatio: '9 / 17',
        width: {xs:'100%', sm: 'auto'},
        gap: 3,
        justifyContent: 'center'
      }}
      >
        {(videos.length > 0 && endAnimation) && <VideoControlButtons
          isMuted={isMuted}
          isPlaying={isPlaying}
          toggleMute={() => dispatch(setMute(!isMuted))}
          togglePlay={handlePlayToggle}
          volume={volume}
          previousVolume={previousVolume}
          onVolumeChange={(event, newValue) =>
            dispatch(setVolume(newValue as number))
          }
        />}
        <Swiper
          style={{ height: '100%', width: '100%' }}
          direction="vertical"
          slidesPerView={1}
          spaceBetween={0}
          onSwiper={setSwiper}
          cssMode={true}
          onSlideChange={handleSlideChange}
        >
          {displayedVideo.map((video, index) => (
            <SwiperSlide key={index} style={{ height: '100%' }}>
              <VideoPlayer
                isLoggedIn={!!userInfo}
                video={video}
                lastVideoId={lastVideoId}
                videos={videos}
                index={index}
                userId={userId}
                videoId={videoId}
                setMaxCountVideo={setMaxCountVideo}
                maxCountVideo={maxCountVideo}
                pageUrl={pageUrl}
                preload={getPreloadStatus(index)}
                handlePlayToggle={handlePlayToggle}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', gap: 2, height: '100%', justifyContent: 'flex-end', pb: 3 }}>
          {swiper && activeSlideIndex > 0 &&
            <IconButton onClick={() => { swiper.slidePrev(); setIsPlaying(true); setEndAnimation(false) }} sx={{ background: 'rgba(110, 113, 132, 0.2)', p: '12px' }}>
              <NorthIcon sx={{color: 'rgba(110, 113, 132, 1)'}} />
            </IconButton>}
          <IconButton onClick={() => { swiper.slideNext(); setIsPlaying(true); setEndAnimation(false) }} sx={{ background: 'rgba(110, 113, 132, 0.2)', p: '12px', transform: 'rotate(180deg)' }}>
            <NorthIcon sx={{color: 'rgba(110, 113, 132, 1)'}} />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ display: { xs: 'block', sm: 'none' }, height: '100%', width: '100%', position: 'relative' }}>
        {(videos.length > 0 && endAnimation) && <VideoControlButtons
          isMuted={isMuted}
          isPlaying={isPlaying}
          toggleMute={() => dispatch(setMute(!isMuted))}
          togglePlay={handlePlayToggle}
          volume={volume}
          previousVolume={previousVolume}
          onVolumeChange={(event, newValue) =>
            dispatch(setVolume(newValue as number))
          }
        />}
        <DialogDownloadApp open={isShowDownloadBtn} onClose={closeDownloadBtn} />
        <Swiper
          style={{ height: '100%', width: '100%' }}
          direction="vertical"
          slidesPerView={1}
          spaceBetween={0}
          cssMode={true}
          // onSwiper={setSwiper}
          onSlideChange={handleSlideChange}
        >
          {displayedVideo.map((video, index) => (
            <SwiperSlide key={index} style={{ height: '100%' }}>
              <VideoPlayer
                isLoggedIn={!!userInfo}
                video={video}
                lastVideoId={lastVideoId}
                videos={videos}
                index={index}
                userId={userId}
                videoId={videoId}
                setMaxCountVideo={setMaxCountVideo}
                maxCountVideo={maxCountVideo}
                pageUrl={pageUrl}
                preload={getPreloadStatus(index)}
                handlePlayToggle={handlePlayToggle}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default VideoFeed;
