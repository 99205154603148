import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

interface IModalTitleProps {
  title: string;
}

const ModalTitle: FC<IModalTitleProps> = ({ title }) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant='h4'
        sx={{
          color: '#79FFE4',
          textAlign: { xs: 'center', sm: 'start' },
          
        }}
        mb={4}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default ModalTitle;
