import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FollowState {
  isFollowed: boolean | undefined;
  error: string | null;
}

const initialState: FollowState = {
  isFollowed: undefined,
  error: null,
};

const followSlice = createSlice({
  name: 'user/follow',
  initialState,
  reducers: {
    followStart: (
      state,
      action: PayloadAction<{
        fromUserId: string;
        toUserId: string;
      }>
    ) => {
      state.error = null;
    },
    followSuccess: (
      state,
      action: PayloadAction<{ toUserId: string; isFollow: boolean }>
    ) => {
      state.isFollowed = action.payload.isFollow;
      state.error = null;
    },
    followFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { followStart, followSuccess, followFailure } =
  followSlice.actions;

export default followSlice.reducer;
