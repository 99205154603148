import { Box, IconButton, Link, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import BroadcastIcon from 'assets/IconsComponents/Broadcast';
import LiveIcon from 'assets/IconsComponents/Live';
import ButttonCustom from 'components/ui/Button/Index';
import { Path } from 'constants/index';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getUserSession, randomID } from 'utils/utils';
import CardStream from './components/CardStream';
import { getFromAPI, postToAPI } from 'api/index';
import cfg from 'config/index';
import { BroadcastRes } from 'typings/broadcast';

const Broadcasts: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const newRoomId = randomID(5);
  const user = getUserSession();
  const [broadcasts, setBroadcasts] = useState<BroadcastRes[]>([])

  const handleGoToRoomHost = (stream?: BroadcastRes) => {
    if (user) {
      postToAPI(null, { userId: user._id }, `${cfg.API_URL}/client/liveUser/live?userId=${user._id}`)
        .then(({ data }: any) => {
          window.location.href = `${process.env.REACT_APP_BASE_URL}/room/host/${data.data.liveHistoryId || newRoomId}_${data.data._id}_call`
        })

    } else {
      navigate(Path.LOGIN);
    }
  };

  const handleGoToRoom = (stream: BroadcastRes) => {
    if (user) {
      localStorage.setItem("stream", JSON.stringify({
        name: stream.name,
        userName: stream.userName,
        image: stream.image
      }))
      window.location.href = `${process.env.REACT_APP_BASE_URL}/room/${stream.liveHistoryId}_${stream._id}_call`
    } else {
      navigate(Path.LOGIN);
    }
  };

  useEffect(() => {
    user && getFromAPI(null, `${cfg.API_URL}/client/liveUser/getliveUserList?userId=${user._id}`)
      .then((data: any) => {
        setBroadcasts(data.data.liveUserList)
      })
  }, [])

  return (
    <>
      <Box sx={{ width: { xs: '100%', sm: '95%', xl: '80%' }, m: '0 auto', p: { xs: '24px 16px', sm: 4 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between', sm: 'flex-start' }, mb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <LiveIcon sx={{ fontSize: '44px' }} />
            <Typography variant='h4'>{t('broadcasts.title')}</Typography>
          </Box>
          {/* <Link sx={{ textDecoration: 'none', display: { xs: 'none', sm: 'flex' } }} href={`${process.env.REACT_APP_BASE_URL}/room/host/${newRoomId}`}> */}
          <ButttonCustom
            onClick={() => handleGoToRoomHost()}
            startIcon={<BroadcastIcon />}
            title={t('broadcasts.btnStartStream')}
            sx={{ ml: 5, display: { xs: 'none', sm: 'flex' } }}
          />
          {/* </Link> */}
          {/* <Link sx={{ textDecoration: 'none', display: { xs: 'block', sm: 'none' } }} href={`${process.env.REACT_APP_BASE_URL}/room/host/${newRoomId}`}> */}
          <IconButton
            onClick={() => handleGoToRoomHost()}
            sx={{
              background: theme.palette.custom.primary.blue,
              color: '#fff',
              height: '40px',
              p: '0 16px',
              borderRadius: '30px',
              display: { xs: 'block', sm: 'none' }
            }}>
            <BroadcastIcon sx={{ fontSize: '18px' }} />
          </IconButton>
          {/* </Link> */}
        </Box>
        {broadcasts.length > 0 ? <Grid container spacing={3}>
          {broadcasts.map((stream) => (
            // <Grid onClick={() => handleGoToRoom(stream)} key={stream._id} size={{ xs: 12, md: 6 }}>
            //   <CardStream stream={stream} />
            // </Grid>

            <Grid onClick={() => handleGoToRoom(stream)} key={stream._id} size={{ xs: 12, md: 6 }}>
              <Link sx={{ textDecoration: 'none' }} href={`${process.env.REACT_APP_BASE_URL}/room/${stream.liveHistoryId}_${stream._id}_call`}>
                <CardStream stream={stream} />
              </Link>
            </Grid>
          ))}
        </Grid>
          : <Typography variant='h3'>No active broadcasts</Typography>
        }
      </Box >
      {/* } */}
    </>
  );
};

export default Broadcasts;
