import { getFromAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getReportReasonFailure,
  getReportReasonStart,
  getReportReasonSuccess,
} from '../slices/getReportReasonSlice';

function* getReportReasonSaga() {
  const endpoint = '/client/report/getReportReason';

  try {
    const response: AxiosResponse = yield call(getFromAPI, null, endpoint);

    console.log('response', response);

    const result: any = response.data.data ?? null;

    if (result) {
      yield put(getReportReasonSuccess({ result }));
    } else {
      yield put(getReportReasonFailure('Ошибка: некорректные данные'));
    }
  } catch (e) {
    yield put(getReportReasonFailure('Ошибка при выполнении запроса'));
  }
}

export function* watchGetReportReasonSaga(): SagaIterator {
  yield takeEvery(getReportReasonStart.type, getReportReasonSaga);
}
