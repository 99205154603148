import * as yup from 'yup';
import { useValidationMessages } from './errorMessages';
import { emailRegExp, phoneRegExp } from './regExp';

//login
export const useLoginValidationSchema = () => {
  const validationMessages = useValidationMessages();

  return yup.object().shape({
    value: yup
      .string()
      .required(validationMessages.required)
      .test(
        'is-email-or-phone',
        validationMessages.phoneOrEmailInvalid,
        (value) => {
          if (!value) {
            return false;
          }
          const isEmail = emailRegExp.test(value);
          const isPhone = phoneRegExp.test(value);
          return isEmail || isPhone;
        }
      ),
    password: yup.string().required(validationMessages.required),
    isRememberDevice: yup.boolean().oneOf([true, false]),
  });
};

//registration
export const useRegistrationValidationSchema = () => {
  const validationMessages = useValidationMessages();

  return yup.object().shape({
    email: yup
      .string()
      .matches(emailRegExp, validationMessages.emailInvalid)
      .required(validationMessages.required),
    password: yup
      .string()
      .min(8, validationMessages.passwordTooShort)
      .matches(/[A-Z]/, validationMessages.passwordStrength)
      .matches(/[0-9]/, validationMessages.passwordStrength)
      .matches(/[\W_]/, validationMessages.passwordStrength)
      .required(validationMessages.required),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], validationMessages.passwordsMismatch)
      .required(validationMessages.required),
    isPrivacy: yup
      .boolean()
      .oneOf([true], validationMessages.required)
      .required(validationMessages.required),
  });
};

//profile information
export const useProfileInfoValidationSchema = () => {
  const validationMessages = useValidationMessages();

  return yup.object().shape({
    fullName: yup.string().required(validationMessages.required),
    nickname: yup.string().required(validationMessages.required),
    clientId: yup.number().required(validationMessages.required),
    country: yup.string().required(validationMessages.required),
    aboutMe: yup.string(),
    gender: yup.string().required(validationMessages.required),
  });
};

//code
export const useCodeValidationSchema = () => {
  const validationMessages = useValidationMessages();

  return yup.object().shape({
    code: yup.string().required(validationMessages.required),
  });
};

// password recovery
export const usePasswordRecoveryValidationSchema = () => {
  const validationMessages = useValidationMessages();

  return yup.object().shape({
    email: yup
      .string()
      .matches(emailRegExp, validationMessages.emailInvalid)
      .required(validationMessages.required),
  });
};

//registration
export const useChangePasswordValidationSchema = () => {
  const validationMessages = useValidationMessages();

  return yup.object().shape({
    password: yup
      .string()
      .min(8, validationMessages.passwordTooShort)
      .matches(/[A-Z]/, validationMessages.passwordStrength)
      .matches(/[0-9]/, validationMessages.passwordStrength)
      .matches(/[\W_]/, validationMessages.passwordStrength)
      .required(validationMessages.required),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], validationMessages.passwordsMismatch)
      .required(validationMessages.required),
  });
};

export const useUploadVideoSchema = () => {
  const validationMessages = useValidationMessages();

  return yup.object().shape({
    headline: yup.string().max(150, validationMessages.maxlength).required(validationMessages.required),
    hashtags: yup.array().of(yup.string()).required(validationMessages.required),
    cover: yup.array().of(yup.mixed()).required(validationMessages.required),
    file: yup.mixed().required(validationMessages.required),
  });
};

export const useUpdateUserProfileSchema = () => {
  return yup.object().shape({
    name: yup.string(),
    userName: yup.string(),
    file: yup.mixed(),
  });
};
