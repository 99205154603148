import { createSvgIcon } from "@mui/material";

// FIXME: Need extract only document icon, without background
const LiveIcon = createSvgIcon(
    <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={44} height={44} rx={22} fill="#F04444" />
    <path
      d="M11.5556 23.5294V31.9118H13.7778M16.8889 23.5294V31.9118M20 23.5294L23.1111 31.9118L26.2222 23.5294M32.4444 31.9118H29.7778V27.7206M29.7778 27.7206V23.5294H32.4444M29.7778 27.7206H32.4444M36 24V31C36 33.2091 34.2091 35 32 35H12C9.79086 35 8 33.2091 8 31V24C8 21.7909 9.79086 20 12 20H32C34.2091 20 36 21.7909 36 24Z"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29 16C27.49 14.191 24.9184 13 22 13C19.0816 13 16.51 14.191 15 16"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M32 14C29.8428 10.985 26.1692 9 22 9C17.8308 9 14.1572 10.985 12 14"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>,
    "Live",
);

export default LiveIcon;
