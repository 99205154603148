import { useTranslation } from 'react-i18next';

export const useValidationMessages = () => {
  const { t } = useTranslation();

  return {
    required: t('validation.required'),
    emailInvalid: t('validation.emailInvalid'),
    passwordTooShort: t('validation.passwordTooShort'),
    passwordStrength: t('validation.passwordStrength'),
    passwordsMismatch: t('validation.passwordsMismatch'),
    usernameTaken: t('validation.usernameTaken'),
    phoneOrEmailInvalid: t('validation.phoneOrEmailInvalid'),
    maxlength: t('validation.maxlength'),
  };
};
