import { Button, ButtonProps, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CustomButtonProps extends ButtonProps {
  i18nKey: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#79FFE4',
  color: '#000',
  fontSize: '14px',
  lineHeight: '20px',
  textTransform: 'none',
  padding: '10px 24px',
  borderRadius: '24px',
  '&:hover': {
    opacity: 0.95,
  },

  '&:disabled': {
    opacity: 0.7,
    color: theme.palette.custom.primary.white,
  },
}));

const CustomButton: React.FC<CustomButtonProps> = ({
  i18nKey,
  type = 'submit',
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledButton type={type} {...props}>
      {t(i18nKey)}
    </StyledButton>
  );
};

export default CustomButton;
