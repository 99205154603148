import { FormControl } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import FormTextField, {
  FormTextFieldProps,
} from 'components/common/FormTextField/FormTextField';
import { FieldValues, useController } from 'react-hook-form';

interface StyledFormControlProps {
  hasError?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

const StyledFormControl = styled(
  ({ hasError, disabled, children, ...other }: StyledFormControlProps) => (
    <FormControl {...other}>{children}</FormControl>
  )
)<{ theme?: Theme; hasError?: boolean; disabled?: boolean }>(
  ({ theme, hasError = false, disabled = false }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '24px',
      fontFamily: 'Albert Sans',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',

      // Стандартное состояние (без фокуса)
      '& fieldset': {
        borderColor: `${theme.palette.custom.primary.graphite}45`,
      },
      color: disabled
        ? `${theme.palette.custom.primary.graphite}45` // Цвет текста при дизейбле
        : theme.palette.custom.primary.graphite,

      // Плейсхолдер
      '& input::placeholder': {
        color: `${theme.palette.custom.primary.graphite}45`,
        opacity: 1,
      },

      // При ховере
      '&:hover fieldset': {
        borderColor: !disabled
          ? `${theme.palette.custom.primary.graphite}45`
          : undefined,
        backgroundColor: !disabled
          ? `${theme.palette.custom.primary.graphite}10`
          : 'transparent',
      },
      '&:hover': {
        color: !disabled ? theme.palette.custom.primary.graphite : undefined,
      },

      // При фокусе
      '&.Mui-focused fieldset': {
        borderColor: `${theme.palette.custom.primary.graphite}`,
      },
      '&.Mui-focused': {
        color: theme.palette.custom.primary.graphite,
      },

      // Поле, в которое введён текст, но оно не в фокусе
      '&.MuiInputBase-root.MuiInputBase-adornedStart.MuiInputBase-formControl fieldset':
        {
          borderColor: `${theme.palette.custom.primary.graphite}`,
        },

      // При ошибке
      '&.Mui-error fieldset': {
        borderColor: `${theme.palette.custom.primary.extraError}`, // Полный цвет для ошибки
        backgroundColor: disabled
          ? `${theme.palette.custom.primary.extraError}05` // Менее яркий цвет фона для дизейбленного поля с ошибкой
          : `${theme.palette.custom.primary.extraError}10`, // Цвет фона при ошибке для обычного поля
      },
      '&:hover.Mui-error fieldset': {
        backgroundColor: disabled
          ? `${theme.palette.custom.primary.extraError}05`
          : `${theme.palette.custom.primary.extraError}15`,
      },
      '&.Mui-error': {
        color: disabled
          ? `${theme.palette.custom.primary.graphite}30` // Более светлый цвет текста для дизейбленного поля с ошибкой
          : theme.palette.custom.primary.extraError, // Полный цвет текста при ошибке
      },
      '&.Mui-focused.Mui-error': {
        color: theme.palette.custom.primary.extraError, // Полный цвет текста при ошибке в фокусе
      },
    },

    // Лейбл при обычном состоянии
    '& .MuiInputLabel-root': {
      color: hasError
        ? theme.palette.custom.primary.extraError // Полный цвет ошибки для лейбла
        : `${theme.palette.custom.primary.graphite}45`,
    },

    '& .MuiInputLabel-root.Mui-focused': {
      color: hasError
        ? theme.palette.custom.primary.extraError // Полный цвет ошибки для фокуса
        : theme.palette.custom.primary.graphite, // Цвет при фокусе
    },

    // Лейбл при фокусе или когда что-то введено
    '& .MuiInputLabel-shrink, &.Mui-focused .MuiInputLabel-root': {
      color: hasError
        ? theme.palette.custom.primary.extraError // Полный цвет ошибки для лейбла
        : theme.palette.custom.primary.graphite,
    },

    // Лейбл при ошибке
    '& .Mui-error .MuiInputLabel-root': {
      color: theme.palette.custom.primary.extraError, // Полный цвет ошибки для лейбла
    },

    // Для дизейбла уменьшаем видимость
    '&.Mui-disabled .MuiOutlinedInput-root': {
      borderColor: `${theme.palette.custom.primary.graphite}45`,
      color: `${theme.palette.custom.primary.graphite}45`,
      backgroundColor: 'transparent',
      filter: 'brightness(0.5)', // Уменьшаем яркость для дизейбленных полей
    },
    '&.Mui-disabled .MuiInputLabel-root': {
      color: `${theme.palette.custom.primary.graphite}45`,
      filter: 'brightness(0.5)', // Уменьшаем видимость лейбла для дизейбленных полей
    },
    '&.Mui-disabled .MuiInputLabel-shrink': {
      color: `${theme.palette.custom.primary.graphite}45`,
      filter: 'brightness(0.5)', // Уменьшаем видимость сжатого лейбла для дизейбленных полей
    },

    // Запрещаем ховер для дизейбленных полей
    '&.Mui-disabled:hover .MuiOutlinedInput-root': {
      borderColor: 'inherit',
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled:hover': {
      color: 'inherit',
    },
  })
);

const StyledFormTextField = <T extends FieldValues>({
  control,
  name,
  disabled,
  ...props
}: FormTextFieldProps<T>) => {
  const theme = useTheme();

  const {
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const hasError = !!error;

  return (
    <StyledFormControl hasError={hasError} disabled={disabled}>
      <FormTextField
        {...props}
        name={name}
        disabled={disabled}
        control={control}
        errorColor={
          (hasError && theme.palette.custom.primary.extraError) || undefined
        }
      />
    </StyledFormControl>
  );
};

export default StyledFormTextField;
