import { postToAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  addCommentFailure,
  addCommentStart,
  addCommentSuccess,
} from '../slices/addCommentSlice';

function* addCommentSaga(
  action: ReturnType<typeof addCommentStart>
): SagaIterator {
  const endpoint = '/client/postOrvideoComment/commentOfPostOrVideo';

  const options = { ...action.payload };

  try {
    const response: AxiosResponse = yield call(
      postToAPI,
      options,
      {},
      endpoint
    );

    const result = response.data.postOrVideoComment;

    if (result) {
      yield put(addCommentSuccess({ result }));
    } else {
      yield put(addCommentFailure('Ошибка: Некорректный ответ от сервера'));
    }
  } catch (e) {
    yield put(addCommentFailure('Ошибка при выполнении запроса на сервер'));
  }
}

export function* watchAddCommentSaga(): SagaIterator {
  yield takeEvery(addCommentStart.type, addCommentSaga);
}
