export const privacyMenuItems = [
  { id: 'section1', label: 'Summary of key points' },
  {
    id: 'section2',
    label:
      'What information do we collect?',
  },
  {
    id: 'section3',
    label: 'How do we process your information?',
  },
  {
    id: 'section4',
    label: 'What legal bases do we rely on to process your personal information?',
  },
  { id: 'section5', label: 'When and with whom do we share your personal information?' },
  { id: 'section6', label: 'Do we use cookies and other tracking technologies?' },
  { id: 'section7', label: 'How do we handle your social logins?' },
  { id: 'section8', label: 'How long do we keep your information?' },
  { id: 'section9', label: 'How do we keep your information safe?' },
  { id: 'section10', label: 'Do we collect information from minors?' },
  {
    id: 'section11',
    label: 'What are your privacy rights?',
  },
  { id: 'section12', label: 'Controls for do-not-track features' },
  { id: 'section13', label: 'Do United States residents have specific privacy rights?' },
  { id: 'section14', label: 'Do other regions have specific privacy rights?' },
  {
    id: 'section15',
    label:
      'How can you contact us about this notice?',
  },
  {
    id: 'section16',
    label:
      'How can you review, update, or delete the data we collect from you?',
  },
];
