import { postToAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { showNotification } from 'store/notification';
import { reportFailure, reportStart } from '../slices/reportSlice';

function* reportSaga(action: ReturnType<typeof reportStart>): SagaIterator {
  const endpoint = '/client/report/reportByUser';
  const options = { ...action.payload };

  console.log('action.payload', action.payload);
  console.log('options', options);

  try {
    const response: AxiosResponse = yield call(
      postToAPI,
      options,
      {},
      endpoint
    );
    const result = response.data;

    if (result.status) {
      yield put(
        showNotification({
          message: result.message,
          severity: 'success',
        })
      );
    } else {
      yield put(reportFailure('Ошибка: Некорректный ответ от сервера'));
    }
  } catch (e) {
    yield put(reportFailure('Ошибка при выполнении запроса на сервер'));
  }
}

export function* watchReportSaga(): SagaIterator {
  yield takeEvery(reportStart.type, reportSaga);
}
