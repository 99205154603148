import { IconStreamLive } from 'assets';
import { Path } from 'constants/index';
import { ISidebarList } from 'typings/index';
import HomeIcon from 'assets/IconsComponents/Home';
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

export const commonMenu: ISidebarList[] = [
  { id: 1, title: 'We recommend it', icon: <HomeIcon sx={{ color: "#fff", fontSize: '16px' }} />, path: Path.RECOMMENDEDVIDEOS },
  { id: 2, title: 'Broadcasts', icon: <PodcastsOutlinedIcon sx={{ color: "#fff", fontSize: '18px' }} />, path: Path.BROADCASTS },
  { id: 3, title: 'Shorts', icon: <PlayArrowOutlinedIcon sx={{ color: "#fff", fontSize: '20px' }} />, path: Path.SHORTS },
  // { id: 4, title: 'Popular Channels', icon: <StarBorderPurple500OutlinedIcon />, path: Path.ROOT },
  { id: 4, title: 'My Shorts', icon: <StarBorderPurple500OutlinedIcon />, path: Path.MYVIDEOS },
  { id: 5, title: 'Popular Channel', icon: <StarBorderOutlinedIcon sx={{ color: "#fff", fontSize: '20px' }} />, path: Path.POPULARCHANNELS },
];

export const protectedMenu: ISidebarList[] = [
  { id: 1, title: 'My profile', icon: <PersonOutlineOutlinedIcon sx={{ color: "#fff", fontSize: '18px' }} />, path: Path.PROFILE },
  // { id: 2, title: 'Stream', icon: <IconStreamLive />, path: Path.ROOM },
];
