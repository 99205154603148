import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface actionState {
  volume: number;
  isMuted: boolean;
  previousVolume: number;
  currentPlayingVideoId: string | null;
}

const initialState: actionState = {
  volume: 0,
  isMuted: true,
  previousVolume: 0.5,
  currentPlayingVideoId: null,
};

const actionSlice = createSlice({
  name: 'video/action',
  initialState,
  reducers: {
    setVolume(state, action: PayloadAction<number>) {
      state.previousVolume = state.volume;
      state.volume = action.payload;
    },
    setMute(state, action) {
      state.isMuted = action.payload;
    },
    toggleMute(state) {
      if (state.isMuted) {
        state.volume = state.previousVolume;
      } else {
        state.previousVolume = state.volume;
        state.volume = 0;
      }
      state.isMuted = !state.isMuted;
    },
    setCurrentPlayingVideoId(state, action) {
      state.currentPlayingVideoId = action.payload;
    },
  },
});

export const { setCurrentPlayingVideoId, setVolume, toggleMute, setMute } =
  actionSlice.actions;
export default actionSlice.reducer;
