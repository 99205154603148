import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GetSharingLinkVideoState {
  url: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: GetSharingLinkVideoState = {
  url: '',
  isLoading: false,
  error: null,
};

export const getSharingLinkVideoSlice = createSlice({
  name: 'get/sharing_link/video',
  initialState,
  reducers: {
    getSharingLinkVideoStart: (
      state,
      action: PayloadAction<{ videoId: string }>
    ) => {
      state.isLoading = true;
      state.error = null;
      state.url = '';
    },
    getSharingLinkVideoSuccess: (state, { payload }) => {
      state.url = payload.result;
      state.error = null;
      state.isLoading = false;
    },
    getSharingLinkVideoFailure: (state, { payload }) => {
      state.error =
        payload || 'Error. Unable to get the link. Please try later';
      state.isLoading = false;
      state.url = '';
    },
  },
});

export const {
  getSharingLinkVideoStart,
  getSharingLinkVideoSuccess,
  getSharingLinkVideoFailure,
} = getSharingLinkVideoSlice.actions;

export default getSharingLinkVideoSlice.reducer;
