import { Avatar, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showNotification } from 'store/notification';
import { IFollowerFollowing } from 'typings/index';

interface ISubscribeCardItemProps {
  subscribe: IFollowerFollowing;
  isFollowerType: boolean;
}

const SubscribeCardItem: FC<ISubscribeCardItemProps> = ({
  subscribe,
  isFollowerType,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subscribeInformation = isFollowerType
    ? subscribe.fromUserId
    : subscribe.toUserId;

  const handleGoToSubscribe = () => {
    if (subscribeInformation?._id) {
      navigate(`/${subscribeInformation._id}/profile`);
    } else {
      dispatch(
        showNotification({
          message: 'Ошибка в данных пользователя',
          severity: 'error',
        })
      );
    }
  };

  return (
    <Stack
      sx={{
        width: '100%',
        background: `${theme.palette.custom.primary.graphite}10`,
        cursor: 'pointer',
        borderRadius: '24px',
      }}
      alignItems='center'
      onClick={handleGoToSubscribe}
      p={2}
    >
      <Avatar
        src={subscribeInformation?.image}
        alt={'User'}
        sx={{ width: 200, height: 200 }}
      />
      <Typography
        variant='h5'
        mt={2}
        mb={1}
        sx={{ color: `${theme.palette.custom.primary.graphite}` }}
      >
        {subscribeInformation?.name}
      </Typography>
      <Typography
        variant='h6'
        sx={{ color: `${theme.palette.custom.primary.graphite}95` }}
      >
        {subscribeInformation?.userName}
      </Typography>
    </Stack>
  );
};

export default SubscribeCardItem;
