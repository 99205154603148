import { AppBar, Avatar, Box, Menu, Typography, useTheme, InputAdornment, Button, useMediaQuery } from '@mui/material';
import { IconAvatar, IconStreamLive } from 'assets';
import UserCustomMenu from 'components/common/Header/UserCustomMenu';
import CustomButton from 'components/ui/Auth/CustomButton';
import VideoUploadButton from 'components/ui/VideoUploadButton';
import { AuthModalType, Path } from 'constants/index';
import useModal from 'hooks/useModal';
import { useAppSelector } from 'hooks/useRedux';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { uploadVideoStart } from 'store/video';
import { getUserSession, randomID, replaceUrl } from 'utils/utils';
import AuthCustomMenu from './AuthCustomMenu';
import AddIcon from '@mui/icons-material/Add';
import SiteLogo from '../Sidebar/SiteLogo';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import ButttonCustom from 'components/ui/Button/Index';
import Sidebar from '../Sidebar';
import SidebarContentProvider from '../SidebarContentProvider';
import SearchInput from './SearchInput';

interface HeaderProps {
  isShowSidebar?: boolean;
  handleOpenMenu?: () => void;
}

const Header: FC<HeaderProps> = ({ isShowSidebar, handleOpenMenu }) => {
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorSidebar, setAnchorSidebar] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState<string>('');
  const menuRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const { openModal } = useModal();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const user = getUserSession();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const newRoomId = randomID(5);
  const { roomId } = useAppSelector((state) => state.stream);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleMenu = (
    (event: React.MouseEvent<HTMLElement>) => {
      if (pathname.includes('host')) {
        setAnchorSidebar(event.currentTarget);
      }
      else if (!isMobile) {
        handleOpenMenu && handleOpenMenu()
      } else if (isMobile) {
        setAnchorSidebar(event.currentTarget);
      }
    }
  );


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSidebarClose = () => {
    setAnchorSidebar(null);
  };

  const handleOpenModal = (type: AuthModalType) => {
    openModal(type);
    navigate(`/${type}`);
    handleMenuClose();
  };

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      dispatch(uploadVideoStart({ videoData: { file }, userId: user._id }));
    }
  };

  const handleGoToRoom = () => {
    if (user) {
      navigate(`/room/${roomId ? roomId : newRoomId}`);
    } else {
      navigate(Path.LOGIN);
    }
  };

  // Add/remove 'inert' attribute based on whether the menu is open
  useEffect(() => {
    if (menuRef.current) {
      if (!anchorEl) {
        menuRef.current.setAttribute('inert', 'true');
      } else {
        menuRef.current.removeAttribute('inert');
      }
    }
  }, [anchorEl]);

  return (
    <>
      <AppBar
        position='sticky'
        sx={{
          top: 0,
          left: 0,
          background: 'rgba(33, 33, 33, 0.13)',
          boxShadow: 'none',
          // borderBottom: `1px solid ${theme.palette.custom.primary.graphite}20`
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: { xs: '60px', sm: '80px' },
            gap: 2
          }}
          py={3}
          pr={2}
          pl={4}
          aria-label={t('header.account')}
        >
          <Box sx={{ display: 'flex', gap: 2, alignItems: "center" }}>
            <Box onClick={handleMenu}>
              <MenuIcon sx={{ color: '#fff', cursor: 'pointer' }} />
            </Box>
            <SiteLogo />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '654px',
              width: '100%'
            }}
          ><SearchInput /></Box>

        
          <Box sx={{ display: { xs: "none", sm: "flex" }, gap: 3 }}>
            {!user && <CustomButton
              onClick={() => navigate(`/login`)}
              sx={{
                width: 'auto',
                marginBottom: 0,
                background: "#79FFE4",
                color: '#000',
                
              }}
              type='button'
              i18nKey='header.login'
            />}

            {/* <VideoUploadButton
              user={user}
              handleVideoUpload={handleVideoUpload}
              startIcon={<AddIcon />}
            /> */}
            <Link to={user ? '/upload' : "/login"}>
              <ButttonCustom startIcon={<AddIcon />} title={t('header.upload')} />
            </Link>

            <Box
              sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={handleMenuClick}
            >
              <Typography
                variant='h6'
                sx={{ color: '#fff', mr: 2, fontFamily: 'DM Sans' }}
              >
                {!!user ? user.name : t('header.account')}
              </Typography>
              {!!user ? (
                <Avatar
                  src={replaceUrl(user.image)}
                  alt='User Image'
                  sx={{ width: 40, height: 40 }}
                />
              ) : (
                <IconAvatar />
              )}
              <MoreVertIcon sx={{ color: '#fff' }} />
            </Box>
          </Box>
        </Box>
      </AppBar>

      <Menu
        ref={menuRef}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              width: '260px',
              borderRadius: '12px',
              boxShadow: '0px 2px 6px 2px #00000026',
              background: '#0B0B0B'
            },
          },
        }}
      >
        {!!user ? (
          <UserCustomMenu user={user} handleMenuClose={handleMenuClose} />
        ) : (
          <AuthCustomMenu handleOpenModal={handleOpenModal} />
        )}
      </Menu>
      <Menu
        ref={sidebarRef}
        anchorEl={anchorSidebar}
        open={Boolean(anchorSidebar)}
        onClose={handleSidebarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 'none',
              background: '#111111'
            },
          },
        }}
        sx={{ '.MuiPaper-root': { left: '0 !important' } }}
      >
        <Box onClick={() => setAnchorSidebar(null)}><Sidebar isShowSidebar sidebarContent={<SidebarContentProvider isShowSidebar />} /></Box>
      </Menu>
    </>
  );
};

export default Header;
