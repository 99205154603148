import { postToAPI } from 'api';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ContentType } from 'constants/index';
import { getFollowingListStart } from 'store/user/slices/getFollowerListSlice';
import {
  followFailure,
  followStart,
  followSuccess,
} from '../slices/followSlice';

function* followSaga(action: ReturnType<typeof followStart>): SagaIterator {
  const endpoint = '/client/followerFollowing/followUnfollowUser';
  const { fromUserId, toUserId } = action.payload;
  const options = { fromUserId, toUserId };

  try {
    const response: AxiosResponse = yield call(
      postToAPI,
      options,
      {},
      endpoint
    );
    const result = response.data;

    if (result) {
      yield put(followSuccess({ toUserId, isFollow: result.isFollow }));

      yield put(
        getFollowingListStart({
          userId: fromUserId,
          type: ContentType.FOLLOWING_LIST,
          isOtherUser: false,
        })
      );
    } else {
      yield put(followFailure('Ошибка: Некорректный ответ от сервера'));
    }
  } catch (e) {
    yield put(followFailure('Ошибка при выполнении запроса на сервер'));
  }
}

export function* watchFollowSaga(): SagaIterator {
  yield takeEvery(followStart.type, followSaga);
}
