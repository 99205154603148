import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
  SxProps,
  useTheme,
} from '@mui/material';
import { IconSmile } from 'assets/index';
import AddCancelButtonGroup from 'components/ui/CommentsPanel/AddCancelButtonGroup';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable'

interface IAddCommentFieldProps {
  commentText: string;
  handleAddComment: () => void;
  handleCancelComment: () => void;
  handleKeyPress: (event: { key: string; preventDefault: () => void }) => void;
  handleSetIsEmojiPickerOpen: () => void;
  image: string;
  setCommentText: (e: any) => void;
  sx?: SxProps;
  closeComments?: () => void;
}

const AddCommentFieldStreamHost: FC<IAddCommentFieldProps> = ({
  commentText,
  handleAddComment,
  handleCancelComment,
  handleKeyPress,
  handleSetIsEmojiPickerOpen,
  image,
  setCommentText,
  closeComments,
  sx
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => closeComments && closeComments(),
    trackMouse: true,
  });

  return (
    <Box {...swipeHandlers} sx={{ background: {xs:'rgba(110, 113, 132, 0.1)', sm: 'inherit'}, p: { xs: '8px 16px 16px', sm: 0 } }}>
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', mt: 1, mb: 3 }}>
        <Box sx={{ background: 'rgba(110, 113, 132, 0.45)', width: '32px', height: '4px', borderRadius: '100px' }} />
      </Box>
      <Stack
        direction='row'
        alignItems='start'
        spacing={1}
        sx={{ position: 'sticky', bottom: 0 }}
      >
        <Avatar src={image} alt='User avatar' />
        <Box width='100%'>
          <InputBase
            placeholder={t('information.add_comment')}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            onKeyPress={handleKeyPress}
            multiline
            fullWidth
            sx={{
              borderRadius: '24px',
              padding: '5px 16px',
              color: `${theme.palette.custom.primary.graphite}`,
              fontFamily: 'DM Sans',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '0.1px',
              border: `1px solid ${theme.palette.custom.primary.graphite}45`,
              mb: 1.5,
              ...sx
            }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={handleSetIsEmojiPickerOpen}>
                  <IconSmile />
                </IconButton>
              </InputAdornment>
            }
          />
          {commentText && (
            <AddCancelButtonGroup
              handleAddComment={handleAddComment}
              handleCancelComment={handleCancelComment}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default AddCommentFieldStreamHost;
