import { createSvgIcon } from "@mui/material";

// FIXME: Need extract only document icon, without background
const GoogleIcon = createSvgIcon(
    <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.990234 5.12935C1.53516 3.9938 2.30508 3.03403 3.27539 2.23599C4.59023 1.14966 6.09844 0.495753 7.78945 0.295363C9.77578 0.0598159 11.6426 0.435988 13.3688 1.46958C13.7977 1.72622 14.1984 2.01802 14.5816 2.33443C14.6766 2.41177 14.666 2.45747 14.5852 2.53482C13.8012 3.31528 13.0172 4.09575 12.2402 4.88325C12.1488 4.97466 12.0996 4.96763 12.0012 4.8938C9.72305 3.1395 6.40078 3.61411 4.70273 5.93443C4.40391 6.34224 4.16133 6.78169 3.99258 7.25982C3.97852 7.302 3.95039 7.34068 3.9293 7.38286C3.47578 7.03833 3.01875 6.6938 2.56875 6.34575C2.04141 5.94146 1.51406 5.53716 0.990234 5.12935Z"
      fill="#E94335"
    />
    <path
      d="M3.92988 10.6805C4.08105 11.0145 4.20762 11.3626 4.39746 11.6755C5.19551 12.9833 6.33457 13.8235 7.83574 14.1223C9.18926 14.393 10.49 14.2102 11.7029 13.5317C11.7451 13.5106 11.7873 13.4895 11.826 13.4684C11.8471 13.4895 11.8646 13.5141 11.8857 13.5317C12.7928 14.2348 13.7033 14.938 14.6104 15.6411C14.1744 16.0735 13.6787 16.4216 13.1514 16.7239C11.6221 17.5958 9.96621 17.9473 8.22246 17.8137C6.0498 17.645 4.17598 16.7872 2.6291 15.2403C1.95059 14.5618 1.3916 13.7954 0.987305 12.92C1.36348 12.6317 1.73965 12.3469 2.11582 12.0587C2.72051 11.5981 3.3252 11.1411 3.92988 10.6805Z"
      fill="#34A853"
    />
    <path
      d="M14.6135 15.641C13.7065 14.9379 12.7959 14.2348 11.8889 13.5316C11.8678 13.5141 11.8467 13.4895 11.8291 13.4684C12.142 13.2258 12.462 12.9902 12.7186 12.6809C13.1475 12.1676 13.4323 11.5875 13.5799 10.9371C13.5975 10.8563 13.5834 10.8281 13.5026 10.8316C13.4604 10.8352 13.4217 10.8316 13.3795 10.8316C11.9452 10.8316 10.5073 10.8281 9.07289 10.8352C8.91469 10.8352 8.87953 10.793 8.88304 10.6418C8.89008 9.62227 8.89008 8.60273 8.88304 7.5832C8.88304 7.45312 8.9182 7.41797 9.04828 7.41797C11.678 7.42148 14.3077 7.42148 16.9409 7.41797C17.0534 7.41797 17.0991 7.44609 17.1272 7.56562C17.3416 8.53242 17.3276 9.50273 17.1975 10.4801C17.092 11.2605 16.9022 12.0164 16.6104 12.7477C16.178 13.827 15.5416 14.7691 14.7049 15.5777C14.6733 15.6023 14.6416 15.6199 14.6135 15.641Z"
      fill="#395AFC"
    />
    <path
      d="M3.92951 10.6805C3.32482 11.141 2.72013 11.598 2.11545 12.0586C1.73927 12.3434 1.3631 12.6316 0.986929 12.9199C0.695132 12.3785 0.505289 11.8055 0.350601 11.2148C0.0552889 10.0723 0.00958573 8.91211 0.171304 7.74844C0.297867 6.83437 0.561539 5.95547 0.986929 5.13281C1.51427 5.53711 2.0381 5.94492 2.56544 6.34922C3.01896 6.69727 3.47248 7.0418 3.92599 7.38633C3.84865 7.7625 3.73966 8.13164 3.70451 8.51836C3.64123 9.22148 3.70802 9.90703 3.90841 10.582C3.92248 10.6102 3.92599 10.6453 3.92951 10.6805Z"
      fill="#FABB06"
    />
  </svg>,
    "Google",
);

export default GoogleIcon;
